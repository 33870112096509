<div class="content background-white">
  <div class="header__wrapper">
    <!-- Burger + logo Generali -->
    <div class="header__logo-wrapper">
      <button class="burgher-wrapper" aria-label="Apri il menù" (click)="onToggleMenu()">
        <img alt="" src="../../../../assets/icons/burger-menu.svg">
      </button>
      <button class="header__logo-img" (click)="onLogoClicked()" aria-label="Vai alla home">
        <img alt="Logo Generali" src="../../../../assets/icons/logo-generali.svg">
      </button>
    </div>

    <!-- Nome applicazione -->
    <button class="header__name d-none d-sm-flex" (click)="onLogoClicked()" aria-label="Vai alla home">
      <img alt="Logo PerformanceUP" src="../../../../assets/icons/logo-performanceUp.svg">
    </button>
    <div></div>
  </div>

  <div class="header-container">

  </div>
</div>


<!-- Linea divisoria -->
<div class="header__line"></div>

<drawer [(show)]="showMenu" [items]="items" (onClick)="onRedirection.emit($event)">
</drawer>