import { Component, Input } from "@angular/core";

@Component({
  selector: "loader",
  templateUrl: "./loader.component.html",
  styleUrl: "./loader.component.scss"
})
export class LoaderComponent {
  @Input() width?: string;
  @Input() marginBlock?: string;
}
