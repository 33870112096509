import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";

export interface DrawerItems {
  id?: string;
  name: string;
  redirectTo?: string;
  canDisplay?: boolean;
}

@Component({
    selector: "drawer",
    templateUrl: "./drawer.component.html",
    styleUrls: ["./drawer.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DrawerComponent extends BaseComponent {
  @Input() show?: boolean;
  @Input() items?: DrawerItems[] = [];
  @Input() title?: string;
  @Output() showChange = new EventEmitter<boolean>();
  @Output() onClick = new EventEmitter<DrawerItems>();
}
