import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HomepageSectionStatusTypeEnum } from "salary-review-cl";

@Component({
    selector: "panel-card",
    templateUrl: "./panel-card.component.html",
    styleUrl: "./panel-card.component.scss",
    standalone: false
})
export class PanelCardComponent {
  // Immagine dell'elemento
  @Input() src?: string;
  @Input() id?: string;
  // Titolo 
  @Input() title?: string;
  // Descrizione
  @Input() description?: string;
  // Sottotitolo
  @Input() subTitle?: string;
  // Label del pulsante
  @Input() buttonLabel?: string;
  // Stato card
  @Input() statusType?: HomepageSectionStatusTypeEnum;
  // Card accessibile
  @Input() isAccessible?: boolean = false;
  // click sul pulsante
  @Output() onClick = new EventEmitter<void>();

  emitClick() {
    this.onClick.emit();
  }
}
