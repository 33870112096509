import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "src/app/app-routing.module";
import { DrawerItems } from "../drawer/drawer.component";


@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    standalone: false
})
export class HeaderComponent extends BaseComponent {
  @Input() items?: DrawerItems[] = [];
  @Input() showMenu?: boolean;
  @Output() onRedirection = new EventEmitter<DrawerItems>();
  @Output() onOpen = new EventEmitter<void>();

  protected async onLogoClicked() {
    await this.router.navigate([UrlCollection.homepage]);
  }

  protected onToggleMenu() {
    this.showMenu = !this.showMenu;
    this.onOpen.emit();
  }
}
