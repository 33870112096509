import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "proposal-header-table",
  templateUrl: "./proposal-header-table.component.html",
  styleUrl: "./proposal-header-table.component.scss",
  standalone: false
})
export class ProposalHeaderTableComponent implements OnInit {
  @Input() titleId?: string;
  @Input() text?: string | null = null;
  @Input() sortDirectionTitle: SortDirectionType | undefined = "ASC";
  @Input() activeSortDirection: SortDirectionType | undefined = "ASC";
  @Input() isLast: boolean = false;
  @Input() withoutBorderRight: boolean = false;
  @Input() activeId?: string;
  @Output() changeOrder = new EventEmitter<{ id: string | undefined, sortDirection: SortDirectionType }>();
  protected COLUMNS_WITH_TOOLTIP: string[] = ["ANNUAL_RETRIBUTION", "EQUITY", "STI"];
  protected COLUMNS_WITHOUT_SORTING: string[] = ["STI", "GRADING", "LAST_MEASURE"];

  ngOnInit() {
  }

  changeOrderTitle() {
    // se sto attivando l'ordinamento per la prima volta, lo inizializzo a ASC
    if (this.activeId != this.titleId) {
      this.activeSortDirection = "ASC";
    } else {
      this.activeSortDirection = this.activeSortDirection == "ASC" ? "DESC" : "ASC";
    }
    this.changeOrder.emit({ id: this.titleId, sortDirection: this.activeSortDirection });
  }
}

export type SortDirectionType = "ASC" | "DESC";