import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProposalPhaseInfo, ProposalSummary } from "salary-review-cl";

@Component({
    selector: "proposal-table-action-cell",
    templateUrl: "./proposal-table-action-cell.component.html",
    styleUrl: "./proposal-table-action-cell.component.scss"
})
export class ProposaTableActionCellComponent {
    @Input()
    id: string = "";
    @Input()
    data: ProposalSummary | null = null;
    @Input()
    teamType?: string;
    @Input()
    phaseInfo: ProposalPhaseInfo | null = null;
    @Input()
    canEdit: boolean = false;

    @Output()
    onPropose: EventEmitter<void> = new EventEmitter<void>();


    onProposeClicked() {
        this.onPropose.emit();
    }
}