import { ErrorHandler, Inject, inject, Injectable } from "@angular/core";
import { AppLoginInfo, AuthProfilesKeys, BudgetMovingPhaseInfo, StructureBudgetUpdateRequest, User, ProposalPhaseInfo, ProposalDashboardSummary, ProposalBudgetExtendedDashboard, ProposalGenderExtendedDashboard, ProposalsFilters, ProposalUserDetails, ProposalFilterTypesEnum, SortTypesEnum, ProposalTypesEnum, ProposalAssignationDetails, ProposalFormInfo, ProposalEconomicMeasureTypeDetails, ProposalEconomicMeasureTypesEnum, ProposalEconomicsDetails, ProposalFieldValidationResult, ProposalFieldValidationRequest, ProposalUpdateRequest, ProposalFilterAgeResponse, ProposalFilterGroupSeniorityResponse, PerformanceOverallEvaluationsEnum, UserProposalToBeConfirmed, ProposalFilterContractLevelResponse, ProposalFilterStructureResponse, ProposalSummary, StructureTreeNode, MeasuresListPhaseInfo, MeasuresListProposalDetails } from "salary-review-cl";
import { DownloadedFile, HttpService } from "src/ancestors/base-api.service";
import { ApplicationError } from "src/ancestors/exception.service";
import { StructureEconomicsDetailsExtended } from "../pages/budget-moving/budget-moving.component";
import { FilterEnumEntry, ProposalUserAnagDetailsExtended } from "../pages/proposal/proposal.component";

@Injectable()
export class ProfileBasedAPIService extends HttpService {

  private errorHandler = inject(ErrorHandler);
  private profileKey: AuthProfilesKeys | undefined;
  protected restPathKey?: string;

  constructor(
    // Questo parametro verrà specificato dal ProfileBasedAPIServiceFactory, che a sua volta viene richiamato dal ProfileBasedAPIServiceProvider.
    // È definito come opzionale perchè lo si potrà conoscere unicamente a runtime e sulla base delle azioni svolte dall'utente (es: impersonifica di un utente piuttosto che un altro),
    // però Angular richiede che esso sia definito o iniettato per poter istanziare il service all'avvio dell'applicazione.
    // Per aggirare questo funzionamento si utilizza questo inject fake che non avrà mai nessun provider definito che possa garantirgli un valore, quindi fintanto che non si passa per
    // il provider citato prima, questo parametro non sarà definito.
    @Inject("FAKE") profileKey?: AuthProfilesKeys
  ) {
    super();
    this.profileKey = profileKey;
    this.restPathKey = profileKey?.toLowerCase();
    this.logger.setCaller("ProfileBasedAPIService");
    this.createInstance({
      url: this.env.apiBaseUrl,
      preventFastFlickering: true,
      minimum: 1500
    });
  }

  public async countUsersToImpersonify(searchedText: string): Promise<number> {

    try {

      const result = await this.httpClient<number>(`/impersonify/${this.restPathKey}/impersonifiableUsers/count`, {
        query: { searchedText }
      });

      return result ?? 0;

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return 0;

  }

  public async getUsersToImpersonify(searchedText: string, fromRecord?: number, numRecords?: number): Promise<User[]> {

    try {

      const result = await this.httpClient<User[]>(`/impersonify/${this.restPathKey}/impersonifiableUsers/list`, {
        query: { searchedText, fromRecord, numRecords }
      });

      if (result?.length) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return [];

  }

  public async impersonifyUser(userId: string): Promise<AppLoginInfo> {
    try {
      const loginInfo = await this.httpClient<AppLoginInfo>(`/impersonify/${this.restPathKey}/impersonifyUser`, {
        method: "post",
        path: [userId]
      });


      if (loginInfo) {
        return loginInfo;
      }


    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return {} as AppLoginInfo;
  }

  public async getBudgetMovingInfo(processId: string, rootStructureId: string, structureId: string) {
    try {

      const result = await this.httpClient<BudgetMovingPhaseInfo>(`/budgetMoving/${this.restPathKey}/phaseInfo/` + processId + "/" + rootStructureId + "/" + structureId, {
      });

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return null;
  }

  public async countBudgetMovingStructures(processId: string, rootStructureId: string, structureId: string) {
    try {

      const result = await this.httpClient<number>(`/budgetMoving/${this.restPathKey}/structures/count/` + processId + "/" + rootStructureId + "/" + structureId, {
      });

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  public async listBudgetMovingStructures(processId: string, rootStructureId: string, structureId: string, sortBy: string, sortType: string) {
    try {

      const result = await this.httpClient<StructureEconomicsDetailsExtended[]>(`/budgetMoving/${this.restPathKey}/structures/list/` + processId + "/" + rootStructureId + "/" + structureId, {
        query: { sortBy, sortType }
      });

      if (result?.length) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async saveBudget(processId: string, structureId: string, budgetUpdates: StructureBudgetUpdateRequest[]) {
    try {
      const saveSuccessful = await this.httpClient<boolean>(`/budgetMoving/${this.restPathKey}/budget/save/` + processId + "/" + structureId, {
        method: "post",
        body: { budgetUpdates: budgetUpdates }
      });

      if (saveSuccessful) {
        return saveSuccessful;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async confirmBudget(processId: string, structureId: string) {
    try {
      const confirmSuccessful = await this.httpClient<boolean>(`/budgetMoving/${this.restPathKey}/budget/confirm/` + processId + "/" + structureId, {
        method: "post"
      });

      if (confirmSuccessful) {
        return confirmSuccessful;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async getProposalPhaseInfo(processId: string, structureId: string): Promise<ProposalPhaseInfo | null> {
    try {
      const result = await this.httpClient<ProposalPhaseInfo>(`/proposal/${this.restPathKey}/phaseInfo/` + processId + "/" + structureId, {});

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  public async getProposalDashboardSummary(processId: string, structureId: string, teamType: string, filters?: ProposalsFilters): Promise<ProposalDashboardSummary | null> {
    try {
      const result = await this.httpClient<ProposalDashboardSummary>(`/proposal/${this.restPathKey}/dashboard/summary/` + processId + "/" + structureId + "/" + teamType, {
        method: "post",
        body: { filters: filters }
      });

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  public async getProposalDashboardExtendedBudget(processId: string, structureId: string, teamType: string, filters?: ProposalsFilters) {
    try {
      const result = await this.httpClient<ProposalBudgetExtendedDashboard>(`/proposal/${this.restPathKey}/dashboard/extended/budget/` + processId + "/" + structureId + "/" + teamType, {
        method: "post",
        body: { filters: filters }
      });

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  public async getProposalDashboardExtendedGender(processId: string, structureId: string, teamType: string, filters?: ProposalsFilters) {
    try {
      const result = await this.httpClient<ProposalGenderExtendedDashboard>(`/proposal/${this.restPathKey}/dashboard/extended/gender/` + processId + "/" + structureId + "/" + teamType, {
        method: "post",
        body: { filters: filters }
      });

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  public async countProposalTableData(processId: string, structureId: string, teamType: string, filters: ProposalsFilters): Promise<number> {
    try {
      const result = await this.httpClient<number>(`/proposal/${this.restPathKey}/user/count/` + processId + "/" + structureId + "/" + teamType, {
        method: "post",
        body: { filters: filters }
      });

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return 0;
    }

    return 0;
  }

  public async listProposalTableData(processId: string, structureId: string, teamType: string, fromRecord: number, numRecords: number, filters: ProposalsFilters, sortBy: string, sortType: SortTypesEnum): Promise<ProposalUserDetails[] | null> {
    try {
      const result = await this.httpClient<ProposalUserDetails[]>(`/proposal/${this.restPathKey}/user/list/` + processId + "/"
        + structureId + "/" + fromRecord + "/" + numRecords + "/" + teamType, {
        method: "post",
        body: {
          filters: filters,
          sortBy: sortBy,
          sortType: sortType
        }
      });

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  public async getProposalFilters(processId: string, structureId: string, teamType: string): Promise<ProposalFilterTypesEnum | null> {
    try {
      const result = await this.httpClient<ProposalFilterTypesEnum>(`/proposal/${this.restPathKey}/filters/` + processId + "/" + structureId + "/" + teamType, {});

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  // Recupera i valori possibili che può assumere un filtro
  public async getProposalFiltersValue(processId: string, structureId: string, teamType: string, filterType: string): Promise<string[] | FilterEnumEntry[] | ProposalFilterAgeResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterContractLevelResponse[] | ProposalFilterStructureResponse[] | PerformanceOverallEvaluationsEnum[] | null> {
    try {
      const result = await this.httpClient<string[] | FilterEnumEntry[] | ProposalFilterAgeResponse | ProposalFilterGroupSeniorityResponse | PerformanceOverallEvaluationsEnum[]>(`/proposal/${this.restPathKey}/filters/values/` + processId + "/" + structureId + "/" + teamType + "/" + filterType, {});

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  public async getProposalAvailableOptions(processId: string, structureId: string, userId: string) {
    try {
      const options = await this.httpClient<ProposalTypesEnum[]>(`/proposal/${this.restPathKey}/userProposal/type/available/` + processId + "/" + structureId + "/" + userId, {
      });

      if (options?.length) {
        return options;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async getUserProposalDetails(processId: string, structureId: string, userId: string, selectedStructureId: string) {
    try {
      const userProposal = await this.httpClient<ProposalAssignationDetails>(`/proposal/${this.restPathKey}/userProposal/details/` + processId + "/" + selectedStructureId + "/" + structureId + "/" + userId, {
      });

      if (userProposal) {
        return userProposal;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as ProposalAssignationDetails;
  }


  public async getAvailableFields(processId: string, structureId: string, userId: string, proposalType: ProposalTypesEnum) {
    try {
      const proposalFormInfo = await this.httpClient<ProposalFormInfo[]>(`/proposal/${this.restPathKey}/userProposal/fields/available/` + processId + "/" + structureId + "/" + userId + "/" + proposalType, {
      });

      if (proposalFormInfo?.length) {
        return proposalFormInfo;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as ProposalFormInfo[];
  }

  public async getTypeDetailsInfo(processId: string, structureId: string, userId: string, proposaType: string | undefined, proposalEconomicMeasureType: ProposalEconomicMeasureTypesEnum, proposalEconomicDetails: ProposalEconomicsDetails[]) {
    try {
      const typeDetails = await this.httpClient<ProposalEconomicMeasureTypeDetails>(`/proposal/${this.restPathKey}/userProposal/type/details/` + processId + "/" + structureId + "/" + userId + "/" + proposaType + "/" + proposalEconomicMeasureType, {
        method: "post",
        body: { proposalEconomicsDetails: proposalEconomicDetails }
      });

      if (typeDetails) {
        return typeDetails;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as ProposalEconomicMeasureTypeDetails;
  }

  public async getPreviewInfo(processId: string, structureId: string, userId: string, proposaType: string | undefined, proposalEconomicMeasureType: ProposalEconomicMeasureTypesEnum, proposalEconomicsDetails: ProposalEconomicsDetails[]) {
    try {
      const typeDetails = await this.httpClient<ProposalEconomicMeasureTypeDetails>(`/proposal/${this.restPathKey}/userProposal/preview/` + processId + "/" + structureId + "/" + userId + "/" + proposaType + "/" + proposalEconomicMeasureType, {
        method: "post",
        body: { proposalEconomicsDetails: proposalEconomicsDetails }
      });

      if (typeDetails) {
        return typeDetails;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as ProposalEconomicMeasureTypeDetails;
  }

  public async getValidateInfo(processId: string, structureId: string, userId: string, proposalType: string | undefined, proposalValidationRequest: ProposalFieldValidationRequest, selectedStructureId: string): Promise<ProposalFieldValidationResult> {
    try {
      const validateInfo = await this.httpClient<ProposalFieldValidationResult>(`/proposal/${this.restPathKey}/userProposal/field/validate/` + processId + "/" + selectedStructureId + "/" + structureId + "/" + userId + "/" + proposalType, {
        method: "post",
        body: proposalValidationRequest
      });

      if (validateInfo) {
        return validateInfo;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as ProposalFieldValidationResult;
  }

  public async savePropose(processId: string, structureId: string, userId: string, formToSave: ProposalUpdateRequest, selectedStructureId: string) {
    try {
      const savedProposeVersion = await this.httpClient<number>(`/proposal/${this.restPathKey}/userProposal/save/` + processId + "/" + selectedStructureId + "/" + structureId + "/" + userId, {
        method: "post",
        body: formToSave
      });

      return savedProposeVersion;

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return null;
  }

  public async deletePropose(processId: string, structureId: string, userId: string, version: number, selectedStructureId: string) {
    try {
      const deletedProposeVersion = await this.httpClient<number>(`/proposal/${this.restPathKey}/userProposal/delete/` + processId + "/" + selectedStructureId + "/" + structureId + "/" + userId, {
        method: "delete",
        body: { version: version }
      });

      return deletedProposeVersion;

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return null;
  }

  // ritorna le proposte che saranno inoltrate al conferma
  public async proposalsToBeConfirmed(processId: string, structureId: string): Promise<UserProposalToBeConfirmed[]> {
    try {
      const proposalsToBeConfirmed = await this.httpClient<UserProposalToBeConfirmed[]>(`/proposal/${this.restPathKey}/proposalsToBeConfirmed/` + processId + "/" + structureId, {});

      if (proposalsToBeConfirmed?.length) {
        return proposalsToBeConfirmed;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as UserProposalToBeConfirmed[];
  }

  // ritorna le proposte che saranno inoltrate al conferma
  public async confirmProposals(processId: string, structureId: string): Promise<boolean> {
    try {
      const confirmProposals = await this.httpClient<boolean>(`/proposal/${this.restPathKey}/confirmProposals/` + processId + "/" + structureId, {
        method: "post"
      });

      return confirmProposals ?? false;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }


  // ritorna le proposte che saranno inoltrate al conferma
  public async downloadProposalReport(processId: string, structureId: string): Promise<DownloadedFile | undefined> {
    try {
      const proposalReport = await this.httpClient<DownloadedFile>(`/proposal/${this.restPathKey}/report/download`, {
        path: [processId, structureId],
        isFileDownload: true
      });

      if (proposalReport) {
        return proposalReport;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async getProposalAnagUserDetails(processId: string, structureId: string, userId: string): Promise<ProposalUserAnagDetailsExtended | undefined> {
    try {
      const anagDetails = await this.httpClient<ProposalUserAnagDetailsExtended>(`/proposal/${this.restPathKey}/user/details/` + processId + "/" + structureId + "/" + userId, {});
      if (anagDetails) {
        return anagDetails;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async getProposalTimelineDetails(processId: string, structureId: string, userId: string, selectedStructureId: string): Promise<ProposalSummary[] | undefined> {
    try {
      const anagDetails = await this.httpClient<ProposalSummary[]>(`/proposal/${this.restPathKey}/userProposal/timeline/` + processId + "/" + selectedStructureId + "/" + structureId + "/" + userId, {});
      if (anagDetails) {
        return anagDetails;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  // Ritorna i dati del filtro per gerarchia
  public async getHieararchyStructure(processId: string, structureId: string): Promise<StructureTreeNode[]> {
    try {
      const treeStructure = await this.httpClient<StructureTreeNode[]>(`/proposal/${this.restPathKey}/filters/structures/hierarchy/` + processId + "/" + structureId, {});
      if (treeStructure) {
        return treeStructure;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }


  public async getMeasuresPhaseInfo(processId: string, structureId: string): Promise<MeasuresListPhaseInfo | null> {
    try {
      let result;
      if (this.restPathKey === "manager") {
        result = await this.httpClient<MeasuresListPhaseInfo>(`/measuresList/${this.restPathKey}/phaseInfo/` + processId + "/" + structureId, {});
      } else {
        result = await this.httpClient<MeasuresListPhaseInfo>(`/measuresList/${this.restPathKey}/phaseInfo/` + processId, {});
      }

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  public async countMeasuresTableData(processId: string, structureId: string, teamType: string, filters: ProposalsFilters): Promise<number> {
    try {
      let result;
      if (this.restPathKey === "manager") {
        result = await this.httpClient<number>(`/measuresList/${this.restPathKey}/user/count/` + processId + "/" + structureId + "/" + teamType, {
          method: "post",
          body: { filters: filters }
        });
      } else {
        result = await this.httpClient<number>(`/measuresList/${this.restPathKey}/user/count/` + processId, {
          method: "post",
          body: { filters: filters }
        });
      }

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return 0;
    }

    return 0;
  }

  public async listMeasuresTableData(processId: string, structureId: string, teamType: string, fromRecord: number, numRecords: number, filters: ProposalsFilters): Promise<MeasuresListProposalDetails[] | null> {
    try {
      let result;
      if (this.restPathKey === "manager") {
        result = await this.httpClient<MeasuresListProposalDetails[]>(`/measuresList/${this.restPathKey}/user/list/` + processId + "/"
          + structureId + "/" + teamType + "/" + fromRecord + "/" + numRecords, {
          method: "post",
          body: {
            filters: filters
          }
        });
      } else {
        result = await this.httpClient<MeasuresListProposalDetails[]>(`/measuresList/${this.restPathKey}/user/list/` + processId + "/" + fromRecord + "/" + numRecords, {
          method: "post",
          body: {
            filters: filters
          }
        });
      }

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }


  public async getMeasuresFilters(processId: string, structureId: string, teamType: string): Promise<ProposalFilterTypesEnum | null> {
    try {
      let result;
      if (this.restPathKey === "manager") {
        result = await this.httpClient<ProposalFilterTypesEnum>(`/measuresList/${this.restPathKey}/filters/` + processId + "/" + structureId + "/" + teamType, {});
      } else {
        result = await this.httpClient<ProposalFilterTypesEnum>(`/measuresList/${this.restPathKey}/filters/` + processId, {});
      }

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  // Recupera i valori possibili che può assumere un filtro
  public async getMeasuresFiltersValue(processId: string, structureId: string, teamType: string, filterType: string): Promise<string[] | FilterEnumEntry[] | ProposalFilterAgeResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterContractLevelResponse[] | ProposalFilterStructureResponse[] | PerformanceOverallEvaluationsEnum[] | null> {
    try {
      let result;
      if (this.restPathKey === "manager") {
        result = await this.httpClient<string[] | FilterEnumEntry[] | ProposalFilterAgeResponse | ProposalFilterGroupSeniorityResponse | PerformanceOverallEvaluationsEnum[]>(`/measuresList/${this.restPathKey}/filters/values/` + processId + "/" + structureId + "/" + teamType + "/" + filterType, {});
      } else {
        result = await this.httpClient<string[] | FilterEnumEntry[] | ProposalFilterAgeResponse | ProposalFilterGroupSeniorityResponse | PerformanceOverallEvaluationsEnum[]>(`/measuresList/${this.restPathKey}/filters/values/` + processId + "/" + filterType, {});
      }

      if (result) {
        return result;
      }

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message || error.errorKey) as string, error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
      return null;
    }

    return null;
  }

  public async downloadMeasuresReport(processId: string, structureId: string, teamType: string): Promise<DownloadedFile | undefined> {
    try {
      let measuresReport;
      if (this.restPathKey === "manager") {
        measuresReport = await this.httpClient<DownloadedFile>(`/measuresList/${this.restPathKey}/report/download`, {
          path: [processId, structureId, teamType],
          isFileDownload: true
        });
      } else {
        measuresReport = await this.httpClient<DownloadedFile>(`/measuresList/${this.restPathKey}/report/download`, {
          path: [processId],
          isFileDownload: true
        });
      }
      if (measuresReport) {
        return measuresReport;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

}
