import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: "[removeAfter]",
    standalone: false
})
export class RemoveAfterDirective {
  constructor(readonly element: ElementRef<HTMLElement>) { }

  /**
   * Removes the attributed element after the specified number of milliseconds.
   */
  @Input() removeAfter?: number;
  private delay = 3000;

  ngOnInit() {
    if (this.removeAfter) {
      setTimeout(() => {
        /** Nascondi il componente con transizione */
        this.element.nativeElement.style.opacity = "0";
        this.element.nativeElement.style.transition = "all 700ms ease-in-out";
        this.element.nativeElement.style.transitionDelay = this.delay.toString();
      }, this.removeAfter);

      setTimeout(() => {
        /** Rimuove il componente dal DOM */
        this.element.nativeElement.remove();
      }, this.removeAfter + 700);
    }
  }
}