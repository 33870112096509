import { Component, Input, OnInit } from "@angular/core";
import { GenderTypesEnum, ProcessTypeEnum, ProcessTypeEnumTypes, ProposalBudgetGenderDetails } from "salary-review-cl";
import { MapById } from "sharedclasses";

type DataMapObject = { genderType: GenderTypesEnum | "TOTAL", value: number, percentage: number, warning: boolean };

@Component({
  selector: "proposal-table-gender",
  templateUrl: "./proposal-dashboard-table-gender.component.html",
  styleUrl: "./proposal-dashboard-table-gender.component.scss"
})
export class ProposalDashboardTableGender implements OnInit {
  protected proposalAmountsByEconomicMeasureType: MapById<MapById<DataMapObject>> = new MapById();
  protected proposalCountersByEconomicMeasureType: MapById<MapById<DataMapObject>> = new MapById();

  @Input()
  set tableData(value: ProposalBudgetGenderDetails[] | null) {
    this.formatData(value);
  }
  @Input()
  processType: ProcessTypeEnum = ProcessTypeEnumTypes.STANDARD;

  columnOrder: string[] = [];
  rowOrder: string[] = [];

  ngOnInit(): void {

  }

  formatData(data: ProposalBudgetGenderDetails[] | null) {

    if (!data) {
      return;
    }

    this.rowOrder = ["TOTAL"];
    this.columnOrder = ["TOTAL", "FEMALE", "MALE"];

    this.proposalCountersByEconomicMeasureType["TOTAL"] = new MapById();

    const amountTotalObject: DataMapObject = {
      genderType: "TOTAL",
      value: 0,
      percentage: 0,
      warning: false
    };
    const countersTotalObject: DataMapObject = {
      genderType: "TOTAL",
      value: 0,
      percentage: 0,
      warning: false
    };

    for (const item of data) {
      // this.columnOrder.push(item.genderType as string);

      // Totale dei totali
      amountTotalObject.value += item.totUsedBudget;
      amountTotalObject.percentage = item.totUsedBudgetPercentage;
      countersTotalObject.value += item.totProposals;
      countersTotalObject.percentage = item.totProposalsPercentage;

      // Totale per genere
      const genderAmountsMapObject: DataMapObject = {
        genderType: item.genderType,
        value: item.totUsedBudget,
        percentage: item.totUsedBudgetPercentage,
        warning: false
      };

      const genderCountersMapObject: DataMapObject = {
        genderType: item.genderType,
        value: item.totProposals,
        percentage: item.totProposalsPercentage,
        warning: false
      };

      if (!this.proposalAmountsByEconomicMeasureType["TOTAL"]) {
        this.proposalAmountsByEconomicMeasureType["TOTAL"] = new MapById<DataMapObject>();
      }

      this.proposalAmountsByEconomicMeasureType["TOTAL"]["TOTAL"] = genderAmountsMapObject;
      this.proposalAmountsByEconomicMeasureType["TOTAL"][genderAmountsMapObject.genderType] = genderAmountsMapObject;

      if (!this.proposalCountersByEconomicMeasureType["TOTAL"]) {
        this.proposalCountersByEconomicMeasureType["TOTAL"] = new MapById<DataMapObject>();
      }

      this.proposalCountersByEconomicMeasureType["TOTAL"][genderCountersMapObject.genderType] = genderCountersMapObject;


      for (const proposal of item.proposalTypesUsedBudgets) {
        const totalBudgetForProposalTypes: DataMapObject = {
          genderType: "TOTAL",
          value: 0,
          percentage: 0,
          warning: false
        };

        const totalCounterForProposalTypes: DataMapObject = {
          genderType: "TOTAL",
          value: 0,
          percentage: 0,
          warning: false
        };

        if (!this.rowOrder.includes(proposal.proposalEconomicMeasureType as string)) {
          this.rowOrder.push(proposal.proposalEconomicMeasureType as string);
        }

        if (!this.proposalAmountsByEconomicMeasureType[proposal.proposalEconomicMeasureType]) {
          this.proposalAmountsByEconomicMeasureType[proposal.proposalEconomicMeasureType] = new MapById<DataMapObject>();
        }

        if (!this.proposalCountersByEconomicMeasureType[proposal.proposalEconomicMeasureType]) {
          this.proposalCountersByEconomicMeasureType[proposal.proposalEconomicMeasureType] = new MapById<DataMapObject>();
        }

        const amountsMapObject: DataMapObject = {
          genderType: item.genderType,
          value: proposal.amount ?? 0,
          percentage: proposal?.percentage ?? 0,
          warning: proposal?.showWarning ?? false
        };

        const countersMapObject: DataMapObject = {
          genderType: item.genderType,
          value: proposal.totProposals ?? 0,
          percentage: proposal?.totProposalsPercentage ?? 0,
          warning: proposal?.showWarning ?? false
        };

        totalBudgetForProposalTypes.value += amountsMapObject.value;
        totalBudgetForProposalTypes.percentage += amountsMapObject.percentage;
        totalBudgetForProposalTypes.warning = totalBudgetForProposalTypes.warning || amountsMapObject.warning;

        totalCounterForProposalTypes.value += countersMapObject.value;
        totalCounterForProposalTypes.percentage += countersMapObject.percentage;
        totalCounterForProposalTypes.warning = totalCounterForProposalTypes.warning || countersMapObject.warning;

        this.proposalAmountsByEconomicMeasureType[proposal.proposalEconomicMeasureType][amountsMapObject.genderType] = amountsMapObject;
        this.proposalCountersByEconomicMeasureType[proposal.proposalEconomicMeasureType][countersMapObject.genderType] = countersMapObject;

        if (!this.proposalAmountsByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"]) {
          this.proposalAmountsByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"] = {
            genderType: "TOTAL",
            value: amountsMapObject.value,
            percentage: amountsMapObject.percentage,
            warning: false
          };
        } else {
          this.proposalAmountsByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"].value += amountsMapObject.value;
          this.proposalAmountsByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"].percentage += amountsMapObject.percentage;
          // this.proposalAmountsByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"].warning = totalBudgetForProposalTypes.warning || amountsMapObject.warning;
        }

        if (!this.proposalCountersByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"]) {
          this.proposalCountersByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"] = {
            genderType: "TOTAL",
            value: countersMapObject.value,
            percentage: countersMapObject.percentage,
            warning: false
          };
        } else {
          this.proposalCountersByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"].value += countersMapObject.value;
          this.proposalCountersByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"].percentage += countersMapObject.percentage;
          // this.proposalCountersByEconomicMeasureType[proposal.proposalEconomicMeasureType]["TOTAL"].warning = totalCounterForProposalTypes.warning || countersMapObject.warning;
        }
      }
    }
    this.proposalAmountsByEconomicMeasureType["TOTAL"]["TOTAL"] = Object.assign({}, amountTotalObject);
    this.proposalCountersByEconomicMeasureType["TOTAL"]["TOTAL"] = Object.assign({}, countersTotalObject);
  }
}


