import { Component } from "@angular/core";

@Component({
  selector: "app-manager",
  templateUrl: "./manager.component.html",
  styleUrl: "./manager.component.scss"
})
export class ManagerComponent {

}
