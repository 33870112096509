import { Injectable, inject } from "@angular/core";
import { EnvironmentLoaderService } from "src/ancestors/env-config.service";
import { BaseLoginInfo } from "./user.service";

@Injectable()
export class TokenStorageService {

  private env = inject(EnvironmentLoaderService);

  constructor() { }

  /**
   * 
   * @returns token salvato + data scadenza
   */
  public retriveToken(tokenKey: string): BaseLoginInfo {
    let token: string | null | undefined;
    const { storeAccessToken } = this.env.getEnvConfig();

    switch (storeAccessToken) {
      case "sessionstorage": {
        token = sessionStorage.getItem(tokenKey);
        break;
      }
      default: {
        token = document.cookie.split(";")
          ?.find((item) => item?.trim()?.startsWith(`${tokenKey}=`))
          ?.split(`${tokenKey}=`)[1];
        break;
      }
    }

    if (token) {
      return JSON.parse(token) as BaseLoginInfo;
    }

    return {} as BaseLoginInfo;

  }
  /**
   * @param token
   * Salva il token nello storage
   */
  public saveToken(tokenKey: string, token: BaseLoginInfo): void {
    const { storeAccessToken } = this.env.getEnvConfig();

    switch (storeAccessToken) {
      case "sessionstorage": {
        sessionStorage.setItem(tokenKey, JSON.stringify(token));
        break;
      }
      default: {
        document.cookie = `${tokenKey}=${JSON.stringify(token)}; samesite=strict; path=/`;
        break;
      }
    }
  }

  /**
   * 
   * Elimina il token dallo storage
   */
  public deleteToken(tokenKey: string): void {
    const { storeAccessToken } = this.env.getEnvConfig();

    switch (storeAccessToken) {
      case "sessionstorage": {
        sessionStorage.removeItem(tokenKey);
        break;
      }
      default: {
        document.cookie = `${tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        break;
      }
    }
  }

}