<table>
    <thead>
        <tr>
            <th>&nbsp;</th>
            <th class="title border-bottom" placement="top" container="body"
                [ngbTooltip]="'proposal.dashboard.expanded.gender.PROPOSAL_IMPORT_TOOLTIP' | translate">
                {{ "proposal.dashboard.expanded.gender.PROPOSAL_IMPORT" | translate }}
            </th>
            <th class="title border-bottom" placement="top" container="body"
                [ngbTooltip]="'proposal.dashboard.expanded.gender.PROPOSAL_NUMBER_TOOLTIP' | translate">
                {{ "proposal.dashboard.expanded.gender.PROPOSAL_NUMBER" | translate }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="table-cell">
                <table>
                    <thead>
                        <tr>
                            <td class="subtitle border-bottom">
                                &nbsp;
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="category black right border-bottom">{{ "proposal.gender.TOTAL" | translate}}
                            </td>
                        </tr>
                        <tr>
                            <td class="category border-ral">{{"proposal.types.ANNUAL_RETRIBUTION_INCREASE" |
                                translate}}</td>
                        </tr>
                        <tr class="grey-bg" *ngIf="processType != 'EXECUTIVE'">
                            <td class="category border-level">{{"proposal.types.LEVEL_ADVANCEMENT" |
                                translate}}</td>
                        </tr>
                        <tr *ngIf="!isDisabledUnaTantumView" [ngClass]="{'grey-bg': processType == 'EXECUTIVE'}">
                            <td class="category border-tantum">{{"proposal.types.UNA_TANTUM" | translate}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
            <td class="table-cell border-right">
                <table>
                    <thead>
                        <tr>
                            <th class="subtitle border-bottom">{{ "proposal.gender.TOTAL" | translate}}</th>
                            <th class="subtitle border-bottom">{{ "proposal.gender.FEMALE" | translate}}</th>
                            <th class="subtitle border-bottom">{{ "proposal.gender.MALE" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Per ogni riga-->
                        <ng-container *ngFor="let row of rowOrder; let firstRow = first; let i = index as number">
                            <tr *ngIf="processType == 'STANDARD' || (processType =='EXECUTIVE' && row != 'LEVEL_ADVANCEMENT')"
                                [ngClass]="{ 'grey-bg': !firstRow && i % 2 == 0}">
                                <ng-container *ngFor="let column of columnOrder; let firstColumn = first">
                                    <ng-container
                                        *ngIf="proposalAmountsByEconomicMeasureType[row] && proposalAmountsByEconomicMeasureType[row][column]">
                                        <ng-container *ngIf="row == 'TOTAL' && column == 'TOTAL'">
                                            <td class="black right border-bottom">
                                                {{
                                                proposalAmountsByEconomicMeasureType[row][column].value.toString()|currency:
                                                'EUR':true:'1.0-0' }}
                                            </td>
                                        </ng-container>
                                        <ng-container *ngIf="row != 'TOTAL' || column != 'TOTAL'">
                                            <td [ngClass]="{'border-bottom': firstRow}">
                                                <div class="cell-percentage-warning"
                                                    [ngClass]="{'warning': proposalAmountsByEconomicMeasureType[row][column].warning}">
                                                    <div class="cell-with-percentage">
                                                        <p class="text black">{{
                                                            (proposalAmountsByEconomicMeasureType[row][column].value).toString()
                                                            |
                                                            currency: 'EUR':true:'1.0-0' }}</p>
                                                        <p *ngIf="column != 'TOTAL'" class="percentage">{{
                                                            (proposalAmountsByEconomicMeasureType[row][column].percentage
                                                            || 0) | number: '1.0-1'
                                                            }}%
                                                        </p>
                                                    </div>
                                                    <img *ngIf="proposalAmountsByEconomicMeasureType[row][column].warning"
                                                        aria-hidden="true" src="../../../../assets/icons/warning.svg"
                                                        alt="">
                                                </div>
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </td>

            <!-- Tabella numero proposte -->
            <td class="table-cell">
                <table>
                    <thead>
                        <tr>
                            <th class="subtitle border-bottom">{{ "proposal.gender.TOTAL" | translate}}</th>
                            <th class="subtitle border-bottom">{{ "proposal.gender.FEMALE" | translate}}</th>
                            <th class="subtitle border-bottom">{{ "proposal.gender.MALE" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let row of rowOrder; let firstRow = first;  let i = index as number">
                            <tr *ngIf="processType == 'STANDARD' || (processType =='EXECUTIVE' && row != 'LEVEL_ADVANCEMENT')"
                                [ngClass]="{'grey-bg': !firstRow && i % 2 == 0}">
                                <ng-container *ngFor="let column of columnOrder; let firstColumn = first;">
                                    <ng-container
                                        *ngIf="proposalCountersByEconomicMeasureType[row] && proposalCountersByEconomicMeasureType[row][column]">
                                        <ng-container *ngIf="row == 'TOTAL' && column == 'TOTAL'">
                                            <td class="black right border-bottom">{{
                                                proposalCountersByEconomicMeasureType[row][column].value}}</td>
                                        </ng-container>
                                        <ng-container *ngIf="row != 'TOTAL' || column != 'TOTAL'">
                                            <td [ngClass]="{'border-bottom': firstRow}">
                                                <div class="cell-percentage-warning"
                                                    [ngClass]="{'warning': proposalCountersByEconomicMeasureType[row][column].warning}">
                                                    <div class="cell-with-percentage">
                                                        <p class="text black">
                                                            {{(proposalCountersByEconomicMeasureType[row][column].value).toString()}}
                                                        </p>

                                                        <p *ngIf="column != 'TOTAL'" class="percentage">{{
                                                            (proposalCountersByEconomicMeasureType[row][column].percentage
                                                            || 0) | number: '1.0-1'
                                                            }}%
                                                        </p>

                                                    </div>
                                                    <img *ngIf="proposalCountersByEconomicMeasureType[row][column].warning"
                                                        aria-hidden="true" src="../../../../assets/icons/warning.svg"
                                                        alt="">
                                                </div>
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>