import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"]
})
export class PaginationComponent implements OnInit {
  @Input() collectionSize: number = 0;
  @Input() pageSize = 10;
  @Input() maxSize = 10;
  @Input() currentPage = 1;
  @Input() ariaLabel: string = "Paginazione";

  @Output() pageChange = new EventEmitter<number>();
  @ViewChild("pagination") pagination: unknown;

  ngOnInit() {

  }

  onPageChange(page: number) {
    this.pageChange.emit(page);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const paginationElement = document.getElementById("pagination");
      if (paginationElement) {
        paginationElement.children[0]?.children[0]?.children[0]?.setAttribute("role", "button");
        const pagesNumber = paginationElement.children[0]?.childElementCount;
        paginationElement.children[0]?.children[pagesNumber - 1]?.children[0]?.setAttribute("role", "button");
      }
    }, 1000);
  }
}
