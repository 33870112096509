<base-modal [id]="id" [mandatory]="mandatory" [largeModal]="largeModal">
    <div class="modal">

        <div class="modal__close" (click)="onClose.emit()">
            <!-- <img src="./assets/icons/close.svg"> -->
            <button type="button" class="btn btn-link close">
                <p class="fw-bold">{{'generic.CLOSE' | translate}}</p>
            </button>
        </div>

        <div class="modal__header">
            <div class="modal__header-title">
                <p>{{ title }}</p>
            </div>
        </div>

        <ng-content></ng-content>

        <div class="modal__actions pt-4" *ngIf="cancelButton || confirmButton">
            <div class="d-flex flex-row gap-2 w-100">
                <ng-container *ngIf="cancelButton">
                    <button type="button" class="btn btn btn-outline-dark btn-lg text-uppercase fw-bold w-100"
                        (click)="this.onCancel.emit()">
                        <p>{{
                            cancelButton }}
                        <p>
                    </button>
                </ng-container>
                <ng-container *ngIf="confirmButton">
                    <button id="modal-confirm-button" type="button"
                        class="btn btn-primary btn-lg  text-uppercase fw-bold w-100"
                        (click)="onConfirm.emit()">{{confirmButton}}</button>
                </ng-container>

            </div>
        </div>
    </div>
</base-modal>