import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProposalEconomicsDetails, ProposalPhaseInfo, ProposalSummary } from "salary-review-cl";

@Component({
    selector: "proposal-table-action-cell",
    templateUrl: "./proposal-table-action-cell.component.html",
    styleUrl: "./proposal-table-action-cell.component.scss",
    standalone: false
})
export class ProposaTableActionCellComponent {
    @Input()
    id: string = "";
    @Input()
    data: ProposalSummary | null = null;
    @Input()
    teamType?: string;
    @Input()
    phaseInfo: ProposalPhaseInfo | null = null;
    @Input()
    canEdit: boolean = false;

    @Output()
    onPropose: EventEmitter<void> = new EventEmitter<void>();

    showExternal: boolean = false;

    getTooltipType() {
        const types = this.data?.proposalEconomicsDetails?.map(item => item.proposalEconomicMeasureType).sort().join("_");
        return types;
    }

    onProposeClicked() {
        this.onPropose.emit();
    }

    getTooltipWarning(proposal: ProposalEconomicsDetails) {
        if (proposal.showWarning && proposal.warningTypes?.length) {
            return "proposal.warnings." + proposal.warningTypes[0];
        }
        return "";
    }
}