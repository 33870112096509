// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    /** Nome standard del file usato per la configurazione dei vari ambienti **/
    configurationFileName: "config.json",
    /** Path per l'inizializzazione dell'ambiente di sviluppo */
    configurationDevPath: "./assets/config/dev/config.json",
    /** Path per l'inizializzazione dell'ambiente di certificazione */
    configurationStagingPath: "./assets/config/staging/config.json",
    /** Path per l'inizializzazione del secondo ambiente di certificazione */
    configurationStaging2Path: "./assets/config/staging-bis/config.json",
    /** Path per l'inizializzazione dell'ambiente di produzione */
    configurationProdPath: "./assets/config/prod/config.json",
    /** Path per l'inizializzazione della configurazione comune a tutti gli ambienti */
    commonConfiguration: "./assets/config/common-config.json"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
