import { Component, inject } from "@angular/core";
import { BreadcrumbService } from "./breadcrumb.service";
import { BaseComponent } from "src/ancestors/base/base.component";

export interface Breadcrumb {
  name: string;
  url: string;
}

@Component({
  selector: "breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrl: "./breadcrumb.component.scss",
  providers: [BreadcrumbComponent]
})
export class BreadcrumbComponent extends BaseComponent {
  protected breadcrumbService: BreadcrumbService = inject(BreadcrumbService);

  constructor() {
    super();
  }

  public async checkDataFields(item: Breadcrumb) {
    this.breadcrumbService.modalUrl = item.url;

    if (JSON.stringify(this.breadcrumbService.processDateParams) !== JSON.stringify(this.breadcrumbService.processDateParamsCopy)) {
      this.modalService.open("modal-changed-fields-for-breadcrumb");
    } else {
      await this.router.navigate([item.url]);
    }
  }
}
