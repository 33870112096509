import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "./app-routing.module";
import { TranslateService } from "./shared/util/translate.service";
import { BaseLoginInfo } from "./global-service/user.service";
import { DrawerItems } from "./shared/components/drawer/drawer.component";
import { PayloadParams, User } from "salary-review-cl";
import { JwtPayload } from "sharedclasses";
import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: false
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  protected fb: FormBuilder = inject(FormBuilder);
  protected translate = inject(TranslateService);
  protected tooltipService = inject(NgbTooltipConfig);

  constructor() {
    super();
    this.logger.setCaller("AppComponent");
  }

  protected showUserOption?: boolean;
  protected drawerItems: DrawerItems[] = [];

  impersonifiedData: JwtPayload<User, PayloadParams> | undefined = undefined;

  override async ngOnInit(): Promise<void> {
    const loginResposnse: BaseLoginInfo = this.tStorage.retriveToken(this.tokenKey);
    this.tooltipService.tooltipClass = "custom-tooltip";
    /**
     * 
     * Se esiste un token ed è ancora valido schedula il rinnovo prima della scadenza
    */
    if (!this.appService.isEmpty(loginResposnse) && this.tkManager.checkTokenValidity(loginResposnse)) {
      this.tkManager.refreshTokenScheduler(loginResposnse);
    }

    if (!sessionStorage.getItem("originalRequestedUrl") && this.env.loginType === "saml-sso") {
      await this.router.navigate([UrlCollection.redirectTo]);
    }

    sessionStorage.removeItem("originalRequestedUrl");

    this.impersonifiedData = this.usrService.getJwtPayload();
  }

  /** Questa funzione cambia la lingua a runtime, e fa il reload della pagina */
  private changeLang(locale: string) {

    /** Salva la lingua nel localStorage */
    localStorage.setItem("locale", locale);

    /** Fa il reload della pagina, per caricare il file json corretto */
    location.reload();
  }

  protected async doLogout() {
    this.showUserOption = false;
    try {
      const redirectToLogin: string = sessionStorage.getItem("SSO_LOGIN") ? "Saml-sso/logoutSaml" : this.env.loginUrl;
      // cancello la variabile che mi dice se ho fatto il login con l'sso 
      sessionStorage.removeItem("SSO_LOGIN");
      sessionStorage.removeItem("selectedProfile");
      sessionStorage.removeItem("selectedSidebarManagerRole");
      this.usrService.clearSelectedProfile();
      await this.backendService.logout(redirectToLogin);
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  protected loadMenuOptions() {
    this.drawerItems = [];
    this.drawerItems.push(
      {
        name: this.translate.instant("generic.LOGOUT"),
        redirectTo: UrlCollection.localAuthentication,
        id: "LOGOUT"
      }
    );
  }
  protected async onNavTabClick(path: DrawerItems) {
    if (path?.id === "LOGOUT") {
      await this.doLogout();
    }
    return this.router.navigate([path.redirectTo]);
  }

  protected async returnToMyProfile() {
    const loginResponse = await this.backendService.loginOriginalUser();
    this.tStorage.saveToken(this.tokenKey, loginResponse);

    this.tkManager.refreshTokenScheduler(loginResponse);
    this.usrService.clearSelectedProfile();
    sessionStorage.removeItem("selectedSidebarManagerRole");
    // recupero l'oggetto impersonifyType per capire in che pagina di impersonifica andare, se non c'è vado in STANDARD
    const impersonifyType: string = sessionStorage.getItem("impersonifyType") ?? "STANDARD";
    await this.router.navigate(["/admin/" + UrlCollection.impersonify + "/" + impersonifyType]);
  }

  getImpersonifiedData() {
    const payload = this.usrService.getJwtPayload();
    return {
      forename: payload?.user?.forename,
      surname: payload?.user?.surname,
      impersonifiedByUserRole: payload?.params?.impersonifiedByUserRole
    };
  }

  protected skipToContent() {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.focus();
    }
  }
}
