import { AuthProfilesKeys, AuthProfilesKeysEnumTypes, PayloadParams, User } from "salary-review-cl";
import { JwtPayload } from "sharedclasses";
import { LoggerService } from "src/ancestors/logger.service";
import { ProfileBasedAPIService } from "./profile-based-api.service";
import { UserService } from "./user.service";

const ERROR_PREFIX = "ProfileBasedAPIServiceFactory - Cannot get ProfileAPIService instance for logged user";
const INSTANCE_CREATION_ERROR = "PROFILE_API_SERVICE_INSTANCE_ERROR";
const serviceInstancesByProfileKey: Map<AuthProfilesKeys, ProfileBasedAPIService> = new Map();

// Funzione che inizializza la mappa con tutte le possibili istanze del service, una per ogni profilo possibile.
// Deve essere chiamata dall'interno del factory perchè mantenendola nel livello top del modulo provecherebbe degli errori in fase di avvio dell'applicazione.
function init() {
  if (!serviceInstancesByProfileKey.has(AuthProfilesKeysEnumTypes.ADMIN)) {
    serviceInstancesByProfileKey.set(AuthProfilesKeysEnumTypes.ADMIN, new ProfileBasedAPIService(AuthProfilesKeysEnumTypes.ADMIN));
  }
  if (!serviceInstancesByProfileKey.has(AuthProfilesKeysEnumTypes.HRBP)) {
    serviceInstancesByProfileKey.set(AuthProfilesKeysEnumTypes.HRBP, new ProfileBasedAPIService(AuthProfilesKeysEnumTypes.HRBP));
  }
  if (!serviceInstancesByProfileKey.has(AuthProfilesKeysEnumTypes.MANAGER)) {
    serviceInstancesByProfileKey.set(AuthProfilesKeysEnumTypes.MANAGER, new ProfileBasedAPIService(AuthProfilesKeysEnumTypes.MANAGER));
  }
}

// Funzione che svolge la funzione di factory per restituire l'istanza corretta del service da utilizzare da parte dell'utente loggato.
// Passando per questo factory è possibile definire un solo service per tutti i profili così da mantenere una stessa logica senza dover duplicare le funzioni o complicare la struttura
// con l'aggiunta di parametri/condizioni da specificare a ogni chiamata
export function ProfileBasedAPIServiceFactory(logger: LoggerService, userService: UserService): ProfileBasedAPIService {

  // Inizializzo la mappa dei service perchè non è possibile farlo al di fuori di questa funzione
  init();

  const jwtPayload: JwtPayload<User, PayloadParams> = userService.getJwtPayload();

  if (!jwtPayload) {
    logger.error(`${ERROR_PREFIX} - Missing JwtPayload`);
    throw new Error(INSTANCE_CREATION_ERROR);
  }

  // Se un utente sta impersonificando qualche altro utente, recupero il profilo dell'utente "impersonificante" dal jwtPayload.
  // Questo sarà il profilo che determina le chiamate da fare perchè a ogni profilo sono abbinate delle regole potenzialmente diverse all'interno dei servizi, che sono quindi suddivisi,
  // e quindi esso vince rispetto al profilo dell'utente impersonificato.
  // Se invece non c'è nessuna impersonificazione in corso, determino il profilo dell'utente loggato e recupero il service che deve utilizzare sulla base di quello.
  const profileKey: AuthProfilesKeys = jwtPayload.params.impersonifiedByUserRole == AuthProfilesKeysEnumTypes.ADMIN && AuthProfilesKeysEnumTypes.ADMIN
    || jwtPayload.params.impersonifiedByUserRole == AuthProfilesKeysEnumTypes.HRBP && AuthProfilesKeysEnumTypes.HRBP
    || jwtPayload.params.impersonifiedByUserRole == AuthProfilesKeysEnumTypes.MANAGER && AuthProfilesKeysEnumTypes.MANAGER
    // Determino il profilo di default dell'utente loggato dando una priorità nel caso in cui esso abbia più profili abbinati a sé
    || userService.getSelectedProfile();

  if (!profileKey) {
    logger.error(`${ERROR_PREFIX} - Cannot determine profileKey`);
    throw new Error(INSTANCE_CREATION_ERROR);
  } else if (serviceInstancesByProfileKey.has(profileKey)) {
    return serviceInstancesByProfileKey.get(profileKey) as ProfileBasedAPIService;
  } else {
    logger.error(`${ERROR_PREFIX} - Cannot find instance for profileKey ${profileKey}`);
    throw new Error(INSTANCE_CREATION_ERROR);
  }

}

// Provider che instanzia il service ProfileBasedAPIService utilizzando il factory dedicato che a runtime si occuperà di determinare l'istanza da utilizzare
export const ProfileBasedAPIServiceProvider = {
  provide: ProfileBasedAPIService,
  useFactory: ProfileBasedAPIServiceFactory,
  deps: [LoggerService, UserService]
};
