import { Component, Input } from "@angular/core";
import { BudgetTypesEnumTypes, ProcessTypeEnum, ProcessTypeEnumTypes, ProposalBudgetTypeDetails } from "salary-review-cl";

@Component({
    selector: "proposal-table-dots-budget",
    templateUrl: "./proposal-dashboard-table-dots-budget.component.html",
    styleUrl: "./proposal-dashboard-table-dots-budget.component.scss",
    standalone: false
})
export class ProposalDashboardTableDotsBudgetComponent {
    // Segue i tipi di BudgetTypesEnumTypes, quindi PERMANENT e UNA_TANTUM
    @Input()
    type: string = BudgetTypesEnumTypes.PERMANENT;
    @Input()
    processType: ProcessTypeEnum = ProcessTypeEnumTypes.STANDARD;

    @Input()
    tableData: ProposalBudgetTypeDetails | null = null;

    
}


