import { Component, EventEmitter, Input, Output } from "@angular/core";
@Component({
    selector: "modal",
    templateUrl: "./modal.component.html",
    styleUrl: "./modal.component.scss",
    standalone: false
})
export class ModalComponent {
  @Input() id!: string;
  @Input() title?: string;
  @Input() mandatory?: boolean;
  @Input() confirmButton?: string;
  @Input() cancelButton?: string;
  @Input() confirmDisabled?: boolean;
  @Input() largeModal?: boolean;

  @Output() onClose = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<void>();
}
