import { ErrorHandler, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { API_REQUEST_METHODS, DownloadedFile, HttpService } from "src/ancestors/base-api.service";
import { TokenStorageService } from "src/app/global-service/token-storage.service";
import { BaseLoginInfo } from "./user.service";
import { AppLoginInfo, HomepageSection, ImportResult, LetterCompanyFilterValues, LetterCustomizationTypesEnum, LettersEmailCounters, LettersEmailSendingStatus, LettersFilters, LettersSessionCreationRequest, LettersSessionDetails, LettersSessionInfo, LettersSessionProcessInfo, LettersSessionUpdateRequest, LetterStructureFilterValues, LetterTypeFilterValues, Process, ProcessTypeEnum, ProcessTypeEnumTypes, SignatureInfo, StructureSummary, UserLetter, UserLetterDetails } from "salary-review-cl";
import { ApplicationError } from "sharedclasses";
import { StructureTreeNodeExtended } from "../pages/setup/detail-process/detail-process.component";

export interface UserParams {
  email: string;
  password: string;
  langCode?: string;
  deviceType?: string;
  userAgent?: string;
  createPersistentUserAuth?: string;
}

export interface CreateProcessParams {
  title: string,
  processType: ProcessTypeEnum,
  freezeDate: Date,
  budgetMoveStartDate: Date,
  budgetMoveEndDate: Date,
  directManagerProposalStartDate: Date,
  nonDirectManagerProposalStartDate: Date,
  nonDirectManagerProposalEndDate: Date,
  rewardReviewStartDate: Date,
  rewardReviewEndDate: Date,
  proposalVisibilityStartDate: Date,
  proposalVisibilityEndDate: Date,
  settlementDate: Date
}

@Injectable()
export class BackendService extends HttpService {
  constructor() {
    super();
    this.logger.setCaller("BackendService");
    this.createInstance({
      url: this.env.apiBaseUrl,
      preventFastFlickering: true,
      minimum: 1500
    });
  }

  private tkStorage: TokenStorageService = inject(TokenStorageService);
  private router: Router = inject(Router);
  private errorHandler = inject(ErrorHandler);

  /** AUTH */

  /**
   * @param loginParam parametri per eseguire il login.
   */
  async localLogin(loginParam: UserParams): Promise<BaseLoginInfo | undefined> {
    try {
      const loginRes = await this.httpClient<BaseLoginInfo>("authn/login", {
        method: API_REQUEST_METHODS.POST,
        body: loginParam
      });


      if (loginRes) {
        return loginRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * Rinnova il token di autenticazione ed invalida il precedente.
   */
  async refreshToken(): Promise<BaseLoginInfo | undefined> {
    try {
      const refreshToken = await this.httpClient<BaseLoginInfo>("authn/refreshToken", {
        method: API_REQUEST_METHODS.POST
      });

      if (refreshToken) {
        return refreshToken;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async decodeSingleUseToken(singleUseToken: string): Promise<BaseLoginInfo | undefined> {
    try {
      const decodeSingleUseToken = await this.httpClient<BaseLoginInfo>("authn/decodeSingleUseToken", {
        query: { singleUseToken }
      });

      if (decodeSingleUseToken) {
        return decodeSingleUseToken;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async createSingleUseToken(): Promise<string | undefined> {
    try {
      const createSingleUseToken = await this.httpClient<BaseLoginInfo>("/authn/createSingleUseToken", {
        method: "post"
      });

      if (createSingleUseToken) {
        return createSingleUseToken.token;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * @returns Invalida il token dell'utente e ne impedisce l'uso e il rinnovo.
   */
  public async logout(redirectToLogin?: string): Promise<boolean> {
    try {
      const logoutRes = await this.httpClient<BaseLoginInfo>("authn/logout", {
        method: API_REQUEST_METHODS.POST
      });

      if (logoutRes) {
        this.tkStorage.deleteToken(this.env.localTokenKey);
        await this.router.navigate([redirectToLogin ? redirectToLogin : this.env.loginUrl]);
        return true;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  // public async adminProcessesCount() {
  //   try {
  //     const countProcessesRes = await this.httpClient<number>("setup/process/count");

  //     if (countProcessesRes >= 0) {
  //       return countProcessesRes;
  //     }
  //   } catch (error: unknown) {
  //
  // this.errorHandler.handleError(error);
  //   }

  //   return 0;
  // }

  public async getProcessSelectableTypes(): Promise<ProcessTypeEnum[]> {
    try {
      const listSelectableTypesProcessesRes = await this.httpClient<ProcessTypeEnum[]>("/process/selectableTypes", {
      });

      if (listSelectableTypesProcessesRes) {
        return listSelectableTypesProcessesRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async getManagerPhases(processType: ProcessTypeEnum): Promise<HomepageSection[]> {

    try {
      const managerPhasesRes = await this.httpClient<HomepageSection[]>("/process/manager/phases/" + processType, {
      });

      if (managerPhasesRes) {
        return managerPhasesRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async getHRBPPhases(processType: ProcessTypeEnum): Promise<HomepageSection[]> {
    try {
      const hrbpPhaseRes = await this.httpClient<HomepageSection[]>("/process/hrbp/phases/" + processType, {
      });

      if (hrbpPhaseRes) {
        return hrbpPhaseRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async getAdminPhases(processType: ProcessTypeEnum): Promise<HomepageSection[]> {
    try {
      const adminPhaseRes = await this.httpClient<HomepageSection[]>("/process/admin/phases/" + processType, {
      });

      if (adminPhaseRes) {
        return adminPhaseRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async adminProcessesCount(processType: ProcessTypeEnum): Promise<number> {
    try {
      const countProcessesRes = await this.httpClient<number>("setup/process/count/" + processType, {
      });

      if (countProcessesRes) {
        return countProcessesRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  public async adminProcessesList(processType: ProcessTypeEnum): Promise<Process[]> {
    try {
      const listProcessesRes = await this.httpClient<Process[]>("setup/process/list/" + processType, {
      });

      if (listProcessesRes) {
        return listProcessesRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async createProcess(processParams: CreateProcessParams): Promise<Process | undefined> {
    try {
      const createProcessRes = await this.httpClient<Process>("setup/createProcess", {
        method: API_REQUEST_METHODS.POST,
        body: processParams
      });


      if (createProcessRes) {
        return createProcessRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async updateProcess(processParams: CreateProcessParams & { processId: string, version: number | undefined }): Promise<Process | undefined> {
    try {
      const createProcessRes = await this.httpClient<Process>("setup/updateProcess", {
        method: API_REQUEST_METHODS.POST,
        body: processParams
      });


      if (createProcessRes) {
        return createProcessRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async deleteProcess(processId: string): Promise<boolean | undefined> {
    try {
      const deleteProcessRes = await this.httpClient<boolean>("setup/deleteProcess", {
        method: API_REQUEST_METHODS.DELETE,
        body: { processId }
      });


      if (deleteProcessRes) {
        return deleteProcessRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }
  public async getProcessById(processId: string): Promise<Process | undefined> {
    try {
      const processByIdRes = await this.httpClient<Process>("/setup/process/get/" + processId, {});

      if (processByIdRes) {
        return processByIdRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async getImportedFileNameForStructures(processId: string): Promise<string | undefined> {
    try {
      const fileNameRes = await this.httpClient<string>("/setup/getImportedFileName/structures/" + processId, {
        withPossibleResponseNull: true
      });

      if (fileNameRes) {
        return fileNameRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }
  public async getImportedFileNameForPayAnalytics(processId: string): Promise<string | undefined> {
    try {
      const fileNameRes = await this.httpClient<string>("/setup/getImportedFileName/payAnalytics/" + processId, {
        withPossibleResponseNull: true
      });

      if (fileNameRes) {
        return fileNameRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async getImportedFileNameForBudgets(processId: string): Promise<string | undefined> {
    try {
      const fileNameRes = await this.httpClient<string>("/setup/getImportedFileName/budgets/" + processId, {
        withPossibleResponseNull: true
      });

      if (fileNameRes) {
        return fileNameRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async getImportedFileNameForAllowances(processId: string): Promise<string | undefined> {
    try {
      const fileNameRes = await this.httpClient<string>("/setup/getImportedFileName/allowances/" + processId, {
        withPossibleResponseNull: true
      });

      if (fileNameRes) {
        return fileNameRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async getImportedFileNameForHrbp(processId: string): Promise<string | undefined> {
    try {
      const fileNameRes = await this.httpClient<string>("/setup/getImportedFileName/hrbp/" + processId, {
        withPossibleResponseNull: true
      });

      if (fileNameRes) {
        return fileNameRes;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async exportStructureFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedFile/structure/" + processId, {
        isFileDownload: true
      });

      if (file) {
        return file;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async exportBudgetsFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedFile/budgets/" + processId, {
        isFileDownload: true
      });

      if (file) {
        return file;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async exportPayAnalyticsFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedFile/payAnalytics/" + processId, {
        isFileDownload: true
      });

      if (file) {
        return file;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async exportAllowancesFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedFile/allowances/" + processId, {
        isFileDownload: true
      });

      if (file) {
        return file;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async exportHrbpFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedFile/hrbp/" + processId, {
        isFileDownload: true
      });

      if (file) {
        return file;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async importStructureFile(processId: string, file: File, hasHeader?: boolean) {
    hasHeader = true;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("processId", processId);
    formData.append("hasHeader", hasHeader ? "true" : "false");

    try {
      const fileName = await this.httpClient<ImportResult>("/setup/import/structures", {
        method: API_REQUEST_METHODS.POST,
        body: formData,
        formData: true
      });

      if (fileName) {
        return fileName;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }


  public async importBudgetsFile(processId: string, file: File, hasHeader: boolean): Promise<ImportResult | undefined> {
    hasHeader = true;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("processId", processId);
    formData.append("hasHeader", hasHeader ? "true" : "false");


    try {
      const createProcessRes = await this.httpClient<ImportResult>("/setup/import/budgets", {
        method: API_REQUEST_METHODS.POST,
        body: formData,
        formData: true
      });


      if (createProcessRes) {
        return createProcessRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }


  public async importPayAnalyticsFile(processId: string, file: File, hasHeader?: boolean) {
    hasHeader = true;
    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("processId", processId);
      formData.append("hasHeader", hasHeader ? "true" : "false");

      const fileName = await this.httpClient<ImportResult>("/setup/import/payAnalytics", {
        method: API_REQUEST_METHODS.POST,
        body: formData,
        formData: true
      });

      if (fileName) {
        return fileName;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async importAllowancesFile(processId: string, file: File, hasHeader?: boolean) {
    hasHeader = true;
    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("processId", processId);
      formData.append("hasHeader", hasHeader ? "true" : "false");

      const fileName = await this.httpClient<ImportResult>("/setup/import/allowances", {
        method: API_REQUEST_METHODS.POST,
        body: formData,
        formData: true
      });

      if (fileName) {
        return fileName;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async importHrbpFile(processId: string, file: File, hasHeader?: boolean) {
    hasHeader = true;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("processId", processId);
    formData.append("hasHeader", hasHeader ? "true" : "false");

    try {
      const fileName = await this.httpClient<ImportResult>("/setup/import/hrbp", {
        method: API_REQUEST_METHODS.POST,
        body: formData,
        formData: true
      });

      if (fileName) {
        return fileName;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async getStructureTree(processId: string): Promise<StructureTreeNodeExtended[]> {
    try {
      const structureTree = await this.httpClient<StructureTreeNodeExtended[]>("/process/structure/tree/" + processId, {
      });

      if (structureTree?.length) {
        return structureTree;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async downloadStructureFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedData/structure", {
        path: [processId],
        isFileDownload: true
      });

      if (file) {
        return file;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async downloadBudgetsFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedData/budgets", {
        path: [processId],
        isFileDownload: true
      });

      if (file) {
        return file;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async downloadAllowancesFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedData/allowances", {
        path: [processId],
        isFileDownload: true
      });

      if (file) {
        return file;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async downloadHrbpFile(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedData/hrbp", {
        path: [processId],
        isFileDownload: true
      });

      if (file) {
        return file;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async downloadStructureFileAtFreezeDate(processId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/export/importedFile/structureFileAtFreezeDate", {
        path: [processId],
        isFileDownload: true
      });

      if (file) {
        return file;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async publishProcess(processId: string): Promise<boolean> {
    try {
      const createProcessRes = await this.httpClient<boolean>("setup/process/publish", {
        method: "post",
        path: [processId]
      });


      if (createProcessRes) {
        return createProcessRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async getManagerAvailableStructures(processType: ProcessTypeEnum) {
    try {
      const availableStructures = await this.httpClient<StructureSummary[]>("/process/manager/availableStructures/" + processType, {
      });

      if (availableStructures?.length) {
        return availableStructures;
      }
    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message), error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return [];
  }

  public async getHomepageSections(profileType: string, processType: ProcessTypeEnum, selectedSidebarManagerRoleStructureId: string | undefined): Promise<HomepageSection[]> {
    try {

      const result = selectedSidebarManagerRoleStructureId
        ? await this.httpClient<HomepageSection[]>(`/process/${profileType}/phases/${selectedSidebarManagerRoleStructureId}`, {})
        : await this.httpClient<HomepageSection[]>(`/process/${profileType}/phases/${processType}`, {});

      return result ?? [];

    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        this.logger.error((error.message), error.messageFromError as string, error.stack);
      }
      this.errorHandler.handleError(error);
    }

    return [];

  }

  public async loginOriginalUser() {
    try {
      const loginInfo = await this.httpClient<AppLoginInfo>("/impersonify/loginOriginalUser", {
        method: "post"
      });


      if (loginInfo) {
        return loginInfo;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as AppLoginInfo;
  }

  // Ritorna il numero delle sessioni lettere salary
  public async sessionsCount(processType: ProcessTypeEnum): Promise<number> {
    try {
      const countSessions = await this.httpClient<number>("/letters/admin/session/count/" + processType, {});

      return countSessions ?? 0;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return 0;
  }

  // Ritorna il numero delle sessioni lettere salary
  public async sessionsList(processType: ProcessTypeEnum, fromRecord: number, numRecords: number): Promise<LettersSessionInfo[]> {
    try {
      const sessionsList = await this.httpClient<LettersSessionInfo[]>("/letters/admin/session/list/" + processType + "/" + fromRecord + "/" + numRecords, {});

      return sessionsList ?? [] as LettersSessionInfo[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as LettersSessionInfo[];
  }

  // Ritorna la lista di processi per cui creare una sessione
  public async getSessionValuesProcess(processType: ProcessTypeEnum) {
    try {
      const list = await this.httpClient<LettersSessionProcessInfo[]>("/letters/admin/letter/generation/values/process/" + processType, {});

      return list ?? [] as LettersSessionProcessInfo[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as LettersSessionProcessInfo[];
  }

  public async getSessionValuesLetterMonth(processId: string) {
    try {
      const list = await this.httpClient<number[]>("/letters/admin/letter/generation/values/letterMonth/" + processId, {});

      return list ?? [] as number[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as number[];
  }


  public async getSessionValuesLetterYear(processId: string) {
    try {
      const list = await this.httpClient<number[]>("/letters/admin/letter/generation/values/letterYear/" + processId, {});

      return list ?? [] as number[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as number[];
  }

  public async getSessionValuesEffectiveMonth(processId: string) {
    try {
      const list = await this.httpClient<number[]>("/letters/admin/letter/generation/values/effectiveMonth/" + processId, {});

      return list ?? [] as number[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as number[];
  }

  public async getSessionValuesEffectiveYear(processId: string) {
    try {
      const list = await this.httpClient<number[]>("/letters/admin/letter/generation/values/effectiveYear/" + processId, {});

      return list ?? [] as number[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as number[];
  }

  public async getSessionValuesStiYear(processId: string) {
    try {
      const list = await this.httpClient<number[]>("/letters/admin/letter/generation/values/stiYear/" + processId, {});

      return list ?? [] as number[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as number[];
  }

  // Crea una nuova sessione, ritorna il letterSessionId
  public async createSession(data: LettersSessionCreationRequest) {
    try {
      const sessionId = await this.httpClient<string>("letters/admin/session/create", {
        method: API_REQUEST_METHODS.POST,
        body: data
      });

      return sessionId;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return "";
  }

  // Aggiorna una sessione triggerando la rigenerazione delle lettere in toto o solo quelle senza customizzazioni
  public async updateSession(letterSessionId: string, data: LettersSessionUpdateRequest) {
    try {
      const result = await this.httpClient<boolean>(`letters/admin/session/update/${letterSessionId}`, {
        method: API_REQUEST_METHODS.POST,
        body: data
      });

      return result;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return false;
  }


  // Ritorna i dettagli della sessione
  public async getSessionDetails(letterSessionId: string) {
    try {
      const session = await this.httpClient<LettersSessionDetails>("/letters/admin/session/get/" + letterSessionId, {});

      return session ?? null;
    } catch (error: unknown) {
      if (error instanceof ApplicationError) {
        return null;
      }
      let url = "/admin/letter-setup/sessions/";
      if (window.location.href.includes(ProcessTypeEnumTypes.STANDARD)) {
        url += ProcessTypeEnumTypes.STANDARD;
      } else {
        url += ProcessTypeEnumTypes.EXECUTIVE;
      } 
      await this.router.navigate([url]);
      this.errorHandler.handleError(error);
    }
    return null;
  }

  async countLetters(letterSessionId: string, data: LettersFilters) {
    try {
      const lettersCount = await this.httpClient<number>("/letters/admin/letter/count/" + letterSessionId, {
        method: API_REQUEST_METHODS.POST,
        body: data
      });

      return lettersCount ?? 0;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return 0;
  }

  async getLetters(letterSessionId: string, fromRecord: number, numRecords: number, data: LettersFilters) {
    try {
      const lettersList = await this.httpClient<UserLetter[]>("/letters/admin/letter/list/" + letterSessionId + "/" + fromRecord + "/" + numRecords, {
        method: API_REQUEST_METHODS.POST,
        body: data
      });

      return lettersList ?? [];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as UserLetter[];
  }

  async getLetterDetails(letterId: string) {
    try {
      const letter = await this.httpClient<UserLetterDetails>("/letters/admin/letter/details/" + letterId, {});

      return letter;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return null;
  }

  public async getLetterSignatures(processId: string) {
    try {
      const list = await this.httpClient<SignatureInfo[]>("/letters/admin/letter/generation/values/signature/" + processId, {});

      return list ?? [] as SignatureInfo[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as SignatureInfo[];
  }

  public async getLetterManagers(processId: string, fromRecord: number, numRecords: number, searchedText: string) {
    try {
      const list = await this.httpClient<SidebarUser[]>("/letters/admin/letter/generation/values/manager/" + processId
        + "/" + fromRecord + "/" + numRecords, { query: { searchedText: searchedText } });

      return list ?? [] as SidebarUser[];
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [] as SidebarUser[];

  }

  public async downloadAllLetters(letterSessionId: string): Promise<DownloadedFile | undefined> {
    try {
      const file = await this.httpClient<DownloadedFile>(`/letters/admin/letter/download/all/${letterSessionId}`, {
        isFileDownload: true
      });

      if (file) {
        return file;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async deleteSession(letterSessionId: string): Promise<boolean | undefined> {
    try {
      const res = await this.httpClient<boolean>(`/letters/admin/session/delete/${letterSessionId}`, {
        method: API_REQUEST_METHODS.DELETE
      });

      if (res) {
        return res;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async downloadLetterPreview(letterId: string) {
    try {
      const file = await this.httpClient<DownloadedFile>("/letters/admin/letter/download/" + letterId, {
        isFileDownload: true
      });

      if (file) {
        return file;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async getNotificationPreviewCounters(letterSessionId: string, type: "manager" | "user") {
    try {
      const response = await this.httpClient<LettersEmailCounters>(`/letters/admin/email/${type}/preview/counters/${letterSessionId}`, {
        method: API_REQUEST_METHODS.GET
      });
      return response;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }

  public async sendAllNotificationBySession(letterSessionId: string, type: "manager" | "user") {
    try {
      const response = await this.httpClient<boolean>(`/letters/admin/email/${type}/send/all/${letterSessionId}`, {
        method: API_REQUEST_METHODS.POST
      });
      return response;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async grantAllVisibilityBySession(letterSessionId: string, type: "manager" | "user") {
    try {
      const response = await this.httpClient<number>(`/letters/admin/letter/${type}/visibility/grant/all/${letterSessionId}`, {
        method: API_REQUEST_METHODS.POST
      });
      return response;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  public async updateSendingStatus(letterSessionId: string) {
    try {
      const response = await this.httpClient<LettersEmailSendingStatus>(`/letters/admin/email/all/sendingStatus/${letterSessionId}`, {
        method: API_REQUEST_METHODS.GET
      });
      return response;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return undefined;
  }


  public async updateLetter(letterId: string, data: FormData) {
    // I parametri da passare sono in UserLetterUpdateRequest.
    // Passo direttamente il form data così non devo passare tutti i parametri separati al servizio

    try {
      const response = await this.httpClient<boolean>("/letters/admin/letter/update/" + letterId, {
        method: API_REQUEST_METHODS.POST,
        body: data,
        formData: true
      });

      return response;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return false;
  }

  public async getFilters(letterSessionId: string) {
    try {
      const filters = await this.httpClient<LettersFilters>("/letters/admin/letter/filters/" + letterSessionId, {});

      return filters;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return null;
  }

  public async getFiltersValues(letterSessionId: string, filterType: "CID" | "FULL_NAME" | "COMPANY" | "NOTIFICATIONS_DATE" | "VISIBILITIES_DATE" | "LETTER_TYPE" | "CUSTOMIZATIONS" | "DIRECT_MANAGER" | "AD_1" | "STRUCTURE") {
    try {
      const filterValues = await this.httpClient<string[] | LetterCustomizationTypesEnum[] | LetterCompanyFilterValues[] | LetterTypeFilterValues[] | LetterStructureFilterValues[] | SidebarUser[]>("/letters/admin/letter/filters/values/" + letterSessionId + "/" + filterType, {});
      return filterValues;
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return [];
  }
}

export type SidebarUser = {
  userId: string;
  forename: string;
  surname: string;
};
