import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProposalPhaseInfo, ProposalUserDetails, ProposalsSortEnum, SortTypesEnum } from "salary-review-cl";

@Component({
    selector: "proposal-table",
    templateUrl: "./proposal-table.component.html",
    styleUrl: "./proposal-table.component.scss"
})
export class ProposalTableComponent {
    @Input()
    headerList: HeaderList[] = [];
    @Input()
    isLoadingData: boolean = false;
    @Input()
    count: number = 0;
    @Input()
    list: ProposalUserDetails[] = [];
    @Input()
    page: number = 0;
    @Input()
    pageSize: number = 10;
    @Input()
    teamType?: string;
    @Input()
    sortData!: { sortBy: string; sortType: SortTypesEnum; };
    @Input()
    phaseInfo: ProposalPhaseInfo | null = null;

    @Output()
    changeOrder = new EventEmitter<{ id: string | undefined, sortDirection: SortDirectionType }>();

    @Output()
    onPropose: EventEmitter<ProposalUserDetails> = new EventEmitter<ProposalUserDetails>();

    @Output()
    onPageChanged = new EventEmitter<number>();

    currentYear: number = new Date().getFullYear();

    changeOrderTitle(header: { id: string | undefined, sortDirection: SortDirectionType }) {
        if (header.id != "STI") {
            this.changeOrder.emit({ id: header.id ?? "USER_FULL_NAME", sortDirection: header.sortDirection });
        }
    }

    convertPerformance(id: string): number {
        if (id == "IMPROVABLE") {
            return 2;
        } else if (id == "MEET") {
            return 3;
        } else if (id == "EXCEEDED") {
            return 4;
        } else if (id == "EXCEPTIONAL") {
            return 5;
        } else {
            return 1;
        }
    }

    changePageClicked(page: number) {
        if (this.page != page) {
            this.onPageChanged.emit(page);
        }
    }

    checkStiType(user: ProposalUserDetails): boolean {
        return user?.stiDetails?.stiType != null;
    }

    getStiDetailsAmount(user: ProposalUserDetails): number {
        return user?.stiDetails?.amount ?? 0;
    }

    getStiDetailsPercentage(user: ProposalUserDetails): number {
        return user?.stiDetails?.percentage ?? 0;
    }
}

export type StatusType = "ACTIVE" | "NOT_ACTIVE";
export type SortDirectionType = "ASC" | "DESC";
export type HeaderList = {
    titleId: ProposalsSortEnum | "STI";
    text?: string | null; // Nel caso di un titolo contenente dati posso usare questo campo che ha la precedenza su titleId
    sortDirection: SortDirectionType;
};