import { Component, inject } from "@angular/core";
import { ProcessTypeEnum } from "salary-review-cl";
import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "src/app/app-routing.module";
import { BreadcrumbService } from "src/app/shared/components/breadcrumb/breadcrumb.service";
@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
  providers: [BreadcrumbService]
})
export class HomepageComponent extends BaseComponent {
  protected breadcrumbService: BreadcrumbService = inject(BreadcrumbService);
  protected panelItems: { title?: string, subtitle?: string, description?: string, url?: string, buttonLabel?: string, srcImg?: string, type: ProcessTypeEnum, actionIsAvailable?: boolean }[] = [];
  protected isLoadingHomeData: boolean = false;
  constructor() {
    super();
    this.logger.setCaller("HttpService");
  }


  override async ngOnInit(): Promise<void> {
    this.isLoadingHomeData = true;
    this.appService.selectableTypes = await this.backendService.getProcessSelectableTypes();

    if (this.appService.selectableTypes?.length == 1) {
      void this.router.navigate([this.usrService.getSelectedProfile().toLowerCase() + "/homepage-actions/" + this.appService.selectableTypes[0]]);
    } else if (this.appService.selectableTypes?.length > 1) {
      // Imposto la breadcrumb
      this.setBreadcrumb();

      for (let i = 0; i < this.appService.selectableTypes?.length; i++) {
        const selectableType = this.appService.selectableTypes[i];
        this.panelItems.push(
          {
            description: this.translateService.instant("homepage.PROFILE_" + selectableType),
            buttonLabel: this.translateService.instant("generic.GO"),
            type: selectableType,
            srcImg: "../../../../assets/img/red-frame.png",
            actionIsAvailable: true
          }
        );
      }
    }

    this.isLoadingHomeData = false;
  }

  protected setBreadcrumb() {
    this.breadcrumbService.breadcrumbs = [{ name: "Home", url: UrlCollection.homepage }];
  }

  protected async goToPage(type: ProcessTypeEnum) {
    this.usrService.clearSelectedProfile();
    sessionStorage.removeItem("selectedSidebarManagerRole");
    await this.router.navigate([this.usrService.getSelectedProfile().toLowerCase() + "/homepage-actions/" + type]);
  }
}
