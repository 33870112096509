import { Component, Input } from "@angular/core";
import { ProposalSummary } from "salary-review-cl";
import { BaseComponent } from "src/ancestors/base/base.component";

@Component({
  selector: "timeline-proposal",
  templateUrl: "./timeline-proposal.component.html",
  styleUrl: "./timeline-proposal.component.scss"
})
export class TimelineProposalComponent extends BaseComponent {
  @Input()
  timelineProposal?: ProposalSummary;

  @Input()
  firstProposal?: boolean = false;

  @Input()
  lastProposal?: boolean = false;
}