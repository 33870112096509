import { Injectable } from "@angular/core";
import { BaseDataService } from "src/ancestors/base-data.service";
import { ProcessParams } from "src/app/pages/setup/detail-process/detail-process.component";
export interface Breadcrumb {
    name: string;
    url: string;
}

@Injectable()
export class BreadcrumbService extends BaseDataService {
    public breadcrumbs: Breadcrumb[] = [];

    public modalUrl: string | null = null;
    public processDateParams: ProcessParams | null = null;
    public processDateParamsCopy: ProcessParams | null = null;
    constructor() {
        super();
    }
}