import { ErrorHandler, NgModule, inject, provideAppInitializer } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GlobalError } from "src/ancestors/exception.service";
import { LoggerService } from "src/ancestors/logger.service";
import { BackendService } from "src/app/global-service/backend-api.service";
import { BaseComponent } from "../ancestors/base/base.component";
import { BaseModule } from "../ancestors/base/base.module";
import { EnvironmentLoaderService } from "../ancestors/env-config.service";
import { i18nModule } from "../ancestors/i18n/i18n.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApplicationDataService } from "./global-service/application-data.service";
import { TokenManagerService } from "./global-service/token-manager.service";
import { TokenStorageService } from "./global-service/token-storage.service";
import { UserService } from "./global-service/user.service";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { HomepageActionsComponent } from "./pages/homepage-actions/homepage-actions.component";
import { SharedModule } from "./shared/shared.module";
import { TranslateService } from "./shared/util/translate.service";
import { ImpersonifyComponent } from "./pages/impersonify/impersonify.component";
import { BudgetMovingComponent } from "./pages/budget-moving/budget-moving.component";
import { ProposalComponent } from "./pages/proposal/proposal.component";
import { NgbModule, NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgAlloyModule } from "alloy-design-system";
import { MultiSelectModule } from "primeng/multiselect";
import { TreeModule } from "primeng/tree";
import { FloatLabelModule } from "primeng/floatlabel";
import { MeasureComponent } from "./pages/measure/measure.component";
import { TabMenuModule } from "primeng/tabmenu";
import { DropdownModule } from "primeng/dropdown";
import { MenuModule } from "primeng/menu";
import { providePrimeNG } from "primeng/config";
import { environment } from "src/environments/environment";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from "ng-recaptcha-2";



/**
 *
 * @param envService Service responsabile del caricamento del file di configurazione
 * @returns File di configurazione.
 * Angular fornisce la possibilità di eseguire delle logiche di configurazione prima che l'applicazione venga inizializzata,
 * in questo modo possiamo caricare il file di configurazione delle variabili globali senza dover ricompilare l'applicazione.
 *
 */
const initAppFn = (envService: EnvironmentLoaderService) => {
  return () => envService.setAppConfig();
};

const translationInitializer = (translation: TranslateService) => {
  return () => {
    translation.loadTranslation();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    ForbiddenComponent,
    HomepageComponent,
    HomepageActionsComponent,
    ImpersonifyComponent,
    BudgetMovingComponent,
    ProposalComponent,
    MeasureComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BaseModule,
    ReactiveFormsModule,
    NgbModule,
    NgAlloyModule,
    MultiSelectModule,
    FloatLabelModule,
    TreeModule,
    TabMenuModule,
    DropdownModule,
    MenuModule,
    RecaptchaV3Module
  ],
  providers: [
    BackendService,
    TokenStorageService,
    ApplicationDataService,
    TokenManagerService,
    UserService,
    LoggerService,
    EnvironmentLoaderService,
    NgbTooltipConfig,
    provideAppInitializer(() => {
      const initializerFn = (initAppFn)(inject(EnvironmentLoaderService));
      return initializerFn();
    }),
    TranslateService,
    provideAppInitializer(() => {
      const initializerFn = (translationInitializer)(inject(TranslateService));
      return initializerFn();
    }),
    {
      provide: ErrorHandler,
      useClass: GlobalError
    },
    providePrimeNG({
      translation: {
        "accept": "Si",
        "addRule": "Aggiungi regola",
        "am": "AM",
        "apply": "Applica",
        "cancel": "Annulla",
        "choose": "Scegli",
        "chooseDate": "Seleziona Data",
        "chooseMonth": "Seleziona Mese",
        "chooseYear": "Seleziona Anno",
        "clear": "Cancella tutto",
        "contains": "Contiene",
        "dateAfter": "La data è successiva",
        "dateBefore": "La data è precedente",
        "dateFormat": "dd/mm/yy",
        "dateIs": "La data è",
        "dateIsNot": "La data non è",
        "dayNames": [
          "Domenica",
          "Lunedi",
          "Martedì",
          "Mercoledì",
          "Giovedì",
          "Venerdì",
          "Sabato"
        ],
        "dayNamesMin": [
          "Do",
          "Lu",
          "Ma",
          "Me",
          "Gi",
          "Ve",
          "Sa"
        ],
        "dayNamesShort": [
          "Dom",
          "Lun",
          "Mar",
          "Mer",
          "Gio",
          "Ven",
          "Sab"
        ],
        "emptyFilterMessage": "Nessuna opzione disponibile",
        "emptyMessage": "Nessun risultato trovato",
        "emptySearchMessage": "Nessun risultato trovato",
        "emptySelectionMessage": "Nessun elemento selezionato",
        "endsWith": "Finisce con",
        "equals": "Equivale",
        "fileSizeTypes": [
          "B",
          "KB",
          "MB",
          "GB",
          "TB",
          "PB",
          "EB",
          "ZB",
          "YB"
        ],
        "firstDayOfWeek": 1,
        "gt": "Maggiore di",
        "gte": "Maggiore o uguale a",
        "lt": "Minore di",
        "lte": "Minore o uguale a",
        "matchAll": "Abbina tutto",
        "matchAny": "Abbina alcuni",
        "medium": "Medio",
        "monthNames": [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre"
        ],
        "monthNamesShort": [
          "Gen",
          "Feb",
          "Mar",
          "Apr",
          "Mag",
          "Giu",
          "Lug",
          "Ago",
          "Set",
          "Ott",
          "Nov",
          "Dic"
        ],
        "nextDecade": "Decade successiva",
        "nextHour": "Ora successiva",
        "nextMinute": "Minuto successivo",
        "nextMonth": "Mese successivo",
        "nextSecond": "Secondo successivo",
        "nextYear": "Anno successivo",
        "noFilter": "Senza Filtro",
        "notContains": "Non contiene",
        "notEquals": "Non uguale",
        "passwordPrompt": "Inserisci la password",
        "pending": "In corso",
        "pm": "PM",
        "prevDecade": "Decade precedente",
        "prevHour": "Ora precedente",
        "prevMinute": "Minuto precedente",
        "prevMonth": "Mese precedente",
        "prevSecond": "Secondo precedente",
        "prevYear": "Anno precedente",
        "reject": "No",
        "removeRule": "Rimuovi regola",
        "searchMessage": "{0} risultati disponibili",
        "selectionMessage": "{0} elementi selezionati",
        "startsWith": "Inizia con",
        "strong": "Forte",
        "today": "Oggi",
        "upload": "Carica",
        "weak": "Debole",
        "weekHeader": "Sett",
        "aria": {
          "cancelEdit": "Annulla modifica",
          "close": "Chiudi",
          "collapseRow": "Riduci riga",
          "editRow": "Modifica riga",
          "expandRow": "Espandi riga",
          "falseLabel": "Falso",
          "filterConstraint": "Costante di filtro",
          "filterOperator": "Operatore di filtro",
          "firstPageLabel": "Prima pagina",
          "gridView": "Griglia",
          "hideFilterMenu": "Nascondi Menu filtri",
          "jumpToPageDropdownLabel": "Vai alla Dropdown delle pagine",
          "jumpToPageInputLabel": "Vai all'Input delle pagine",
          "lastPageLabel": "Ultima pagina",
          "listView": "Lista",
          "moveAllToSource": "Muovi tutto alla sorgente",
          "moveAllToTarget": "Muovi tutto all'elemento",
          "moveBottom": "Vai in fondo",
          "moveDown": "Vai sotto",
          "moveTop": "Vai in cima",
          "moveToSource": "Vai alla sorgente",
          "moveToTarget": "Vai all'elemento",
          "moveUp": "Vai sopra",
          "navigation": "Naviga",
          "next": "Successivo",
          "nextPageLabel": "Pagina successiva",
          "nullLabel": "Non selezionato",
          "pageLabel": "Pagina {page}",
          "previous": "Precedente",
          "previousPageLabel": "Pagina precedente",
          "removeLabel": "Rimuovere",
          "rotateLeft": "Ruota a sinistra",
          "rotateRight": "Ruota a destra",
          "rowsPerPageLabel": "Elementi per pagina",
          "saveEdit": "Salva modifica",
          "scrollTop": "Torna sù",
          "selectAll": "Seleziona tutti gli elementi",
          "selectRow": "Seleziona riga",
          "showFilterMenu": "Mostra Menu filtri",
          "slide": "Scorri",
          "slideNumber": "{slideNumber}",
          "star": "1 stella",
          "stars": "{star} stelle",
          "trueLabel": "Vero",
          "unselectAll": "Deseleziona tutti gli elementi",
          "unselectRow": "Deseleziona riga",
          "zoomImage": "Zoom Immagine",
          "zoomIn": "Ingrandisci",
          "zoomOut": "Riduci"
        }
      }
    }),
    ReCaptchaV3Service,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaKey
    },
    i18nModule.setLocale(),
    i18nModule.setLocaleId()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
