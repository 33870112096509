<div class="card">
    <img [src]="src" class="card-img-top" alt="" aria-hidden="true">
    <div *ngIf="statusType?.length" class="{{statusType}} badge">
        <p>{{'cardStatus.' + statusType | translate}}</p>
    </div>
    <div class="card-body d-flex flex-column justify-content-between">
        <div>
            <h5 *ngIf="title?.length" class="title-text">{{title}}</h5>
            
            <p [ngClass]="{'m-21':!title}" class="description-text">{{description}}</p>
            
            <p *ngIf="subTitle?.length" class="subtitle-text">{{subTitle}}</p>
        </div>
        <div *ngIf="isAccessible" class="d-flex justify-content-end">
            <button *ngIf="buttonLabel" [ngClass]="{'disabled': statusType === 'DEACTIVATED'}" (click)="emitClick()"
                class="btn btn-link d-flex flex-row p-0 ">
                <p class="fw-bold">{{buttonLabel}}</p>
                <img *ngIf="statusType !== 'DEACTIVATED'" aria-hidden="true"
                    src="../../../../assets/icons/chevron-right.svg">
            </button>
        </div>
    </div>
</div>