<div [ngClass]="inputWrapperClass" class="input-wrapper" #alloySelectContainer>
  <label *ngIf="formTitle" class="control-label">{{ formTitle }}</label>
  <div [ngClass]="containerClass ? containerClass : 'small-dimension'">
    <ng-select #alloySelect [virtualScroll]="virtualScroll" [labelForId]="labelForId" [bindValue]="bindValue"
      [bindLabel]="bindLabel || ''" [items]="items" placeholder="{{ placeholderLabel }}" [multiple]="multiple"
      (add)="emitAddItem($event)" [disabled]="disabled" (remove)="emitRemoveItem($event)"
      (change)="emitOnSelectionChanged($event)" [(ngModel)]="bindModel" (ngModelChange)="emiOnModelChanged($event)"
      [dropdownPosition]="dropdownPosition" (scrollToEnd)="emitOnScrollToEnd($event)" (close)="emitOnClose()"
      [addTag]="addTag" [attr.aria-label]="attrAriaLabel" [clearOnBackspace]="clearOnBackspace"
      [searchable]="searchable" [hideSelected]="hideSelected" [typeahead]="typeahead" [loading]="loading"
      [ariaAutoComplete]="searchable ? 'list' : 'none'"
      [ngClass]="{'flex-1-ie': ieFlexProblem, 'search-input-training-passport': selectTrainingPassportClass, 'fix-user-selected': areUsersList && !multiple, 'searchable': searchable}"
      [compareWith]="compareWithFn" [markFirst]="markFirst">

      <ng-template ng-header-tmp>
        <div class="search-container">
          <i class="search-icon" aria-hidden="true"></i>
        </div>
      </ng-template>

      <!-- Elementi selezionati -->
      <ng-template class="alloy-select" ng-label-tmp let-item="item" let-clear="clear">
        <!-- Elementi selezionati -->
        <div *ngIf="multiple && !areUsersList && !areGroupsList && !isIDPCategorySelect" fxLayout="row"
          fxLayoutAlign="start center" style="padding: 5px 10px;" [ngClass]="{'faculty-wrap' : isFacultySelect}"
          fxLayourGap="8px">
          <p>{{resolveItemTitle(item, true)}}</p>
          <!-- ho dovuto aggiungere un div dove aggangiare il click per risolvere un problema con ng-select, si rompeva non trovando il .classname sull'elemento SVG al click della X -->
          <div (click)="clear(item);" style="max-height: 16px; margin-left: 8px;">
            <img aria-hidden="true" src="assets/img/icons/icon-trash.svg" alt="">
          </div>
        </div>

        <!-- Elementi selezionati per usersList-->
        <div *ngIf="multiple && areUsersList && !areGroupsList && !isIDPCategorySelect" fxLayout="row"
          fxLayoutAlign="start center" style="padding: 5px 10px;" [ngClass]="{'faculty-wrap' : isFacultySelect}"
          fxLayourGap="8px">
          <p>{{item.surname}} {{item.forename}}</p>
          <p *ngIf="item.ramo">{{item.ramo}}</p>
          <!-- ho dovuto aggiungere un div dove aggangiare il click per risolvere un problema con ng-select, si rompeva non trovando il .classname sull'elemento SVG al click della X -->
          <div (click)="clear(item);" style="max-height: 16px; margin-left: 8px;">
            <img aria-hidden="true" src="assets/img/icons/icon-trash.svg" alt="">
          </div>
        </div>

        <div *ngIf="!multiple && !areUsersList && !areGroupsList" fxLayout="row" fxLayoutAlign="start center">
          <p class="margin0">{{ resolveItemTitle(item, true) }}</p>
        </div>
        <div *ngIf="isIDPCategorySelect && !multiple && !areUsersList && !areGroupsList" fxLayout="row"
          fxLayoutAlign="start center">
          <p class="margin0">{{ item.title }}</p>
        </div>

        <div *ngIf="!multiple && areUsersList"
          class="d-flex flex-row justify-content-between align-content-center user-container">
          <p class="margin0">{{ item.surname + " " + item.forename }}</p>
          <button *ngIf="!loading" (mousedown)="clear(item);" class="clear-icon-btn">
            <nga-icon src="../../../../assets/icons/close.svg"></nga-icon>
          </button>
        </div>
      </ng-template>

      <!-- Template per l'inizio della ricerca -->
      <ng-template class="alloy-select" ng-typetosearch-tmp>
        <div class="ng-option disabled">{{ startSearchLabel || 'Inizia una ricerca' }}</div>
      </ng-template>
      <!-- Template per i dati non trovati -->
      <ng-template class="alloy-select" ng-notfound-tmp let-searchTerm="filter">
        <div class="ng-option disabled">{{ noDataFoundLabel || 'Nessun dato trovato' }}</div>
      </ng-template>
      <!-- Template per il caricamento dei risultati -->
      <ng-template class="alloy-select" ng-loadingtext-tmp>
        <div class="ng-option disabled">{{ loadingData || 'Caricamento in corso' }}</div>
      </ng-template>

      <!-- Template per i singoli item nelle options -->
      <ng-template class="alloy-select" ng-option-tmp let-item="item" let-search="filter">
        <div
          *ngIf="!areUsersList && !areMailAndNameList && !areMailtemplateList && !isIDPCategorySelect && !isAdminSelect()"
          class="default-option" fxLayout="row">
          <div *ngIf="item.containerClass">
            <span [ngClass]="item.containerClass" class="skills-legend"></span>
          </div>
          <p class="margin0 truncate" [ngClass]="(noWhitespace == 'true' ? 'no-whitespace' : '')">
            {{resolveItemTitle(item)}}</p>
        </div>
        <!-- Template per i singoli item nelle options (se è una lista categorie IDP) -->
        <div *ngIf="isIDPCategorySelect">
          <div class="select-options-card">
            <div class="card-body" fxLayout="row">
              <div class="full-width" fxLayout="column">
                <p class="title">{{ item.title }}</p>
                <p class="subtitle">{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Template per i singoli item nelle options (se è una lista di utenti) -->
        <div *ngIf="areUsersList">
          <div class="select-options-card">
            <div class="card-body" fxLayout="row">
              <div class="full-width userlist-user-container" fxLayout="row">
                <div fxLayout="column">
                  <p class="bold-text margin-bottom0">{{item.surname}} {{item.forename}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Template per i singoli item nelle options (se è lista di nome-email) -->
        <div *ngIf="areMailAndNameList" class="select-options-card">
          <div class="card-body" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
              <img class="svg icon" src="assets/img/icons/gear-email.svg" alt="">
            </div>
            <div fxLayout="column">
              <span class="card-title">{{item.name}}</span>
              <span class="card-subtitle italic text-muted">{{item.mail}}</span>
            </div>
          </div>
        </div>
        <!-- Template per i singoli item nelle options (se è lista dei template mail) -->
        <div *ngIf="areMailtemplateList" class="select-options-card">
          <div class="card-body" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
              <img class="svg icon" src="assets/img/icons/gear-email.svg" alt="">
            </div>
            <div fxLayout="column">
              <span class="card-title">{{item.name}}</span>
              <span class="card-subtitle italic text-muted">{{item.subject}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-select>

    <div fxLayout="row" *ngIf="required && !bindModel && (focused || forceShowError)" class="input-error-message">
      {{ errorRequiredText }}
    </div>
  </div>
</div>