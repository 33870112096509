import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { UrlCollection } from "../app-routing.module";
import { ProcessTypeEnum } from "salary-review-cl";

@Injectable()
export class ApplicationDataService {
  private router: Router = inject(Router);

  public showHeader = false;
  public showFooter = false;

  public selectableTypes: ProcessTypeEnum[] = [];

  public stringify(param?: unknown): string | undefined {
    if (!param) return undefined;

    return typeof param === "string" ? param : JSON.stringify(param);
  }

  /**
   * Utilizzando la pipe keyValue l'ordine con cui i dati vengono esposti non corrisponde all'ordine originale nella quale il parametro si trova all'interno dell'oggezzo.
   *
   * obj = { z: 1, c: 2, l: 3 } ==> 2, 3, 1
   *
   * Se si desidera mantenere l'ordine originale di inserimento passare come parametro alla pipe questo metodo.
   *
   *
   * {{ loop | keyValue: originalOrder }}
   *
   * @param a
   * @param b
   * @returns
   */
  public originalOrder = (): number => {
    return 0;
  };

  /**
   * Scorre la pagina verso il punto specificato.
   * 
   * @param ancorTagId L'ID dell'ancora a cui si desidera scorrere la pagina.
   */
  public scrollTo(ancorTagId: string) {
    const ancor = document.querySelector(ancorTagId);
    const position = ancor?.getBoundingClientRect();
    if (position) {
      window.scrollTo(0, document.body.scrollHeight - position.height);
    }
  }

  public isEmpty(obj: unknown): obj is undefined {
    if (typeof obj === "string") {
      return obj === "" ? true : false;
    }

    if (obj === undefined || obj === null) {
      return true;
    }

    if (JSON.stringify(obj) === "{}") {
      return true;
    }

    if (JSON.stringify(obj) === "[]") {
      return true;
    }

    return false;
  }
  public setHeaderVisibility() {
    if (this.router.url.includes(UrlCollection.localAuthentication) || this.router.url.includes(UrlCollection.samlSso)) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }
}
