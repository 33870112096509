import { Component, Input } from "@angular/core";

@Component({
    selector: "equity-cell",
    templateUrl: "./equity-cell.component.html",
    styleUrl: "./equity-cell.component.scss"
})
export class EquityCellComponent {
    @Input()
    isPositive: boolean = false;

    @Input()
    value: number = 0;

    @Input()
    percentage: number = 0;


    formatRoundValue(
        number: number,
        threeDecimal?: boolean,
        oneDecimal?: boolean
    ) {
        let text;
        if (threeDecimal) {
            number = Math.round(number * 1000) / 1000;
            text = number.toString().replace(".", ",");
        } else if (oneDecimal) {
            number = Math.round(number * 10) / 10;
            text = number.toString().replace(".", ",");
        } else {
            text = Math.round(number);
        }
        return text?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
}