import { Component } from "@angular/core";

@Component({
    selector: "app-hrbp",
    templateUrl: "./hrbp.component.html",
    styleUrl: "./hrbp.component.scss",
    standalone: false
})
export class HrbpComponent {

}
