<div class="d-flex flex-row w-100 timeline-container" [ngClass]="{'first': firstProposal, 'last': lastProposal}"
  *ngIf="timelineProposal">
  <div class="d-flex flex-column timeline-divisor-proposal-container">
    <div class="timeline-divisor-vertical first"></div>
    <div class="timeline-divisor-point"></div>
    <div class="timeline-divisor-vertical second"></div>
  </div>
  <div class="timeline-proposal-container w-100 d-flex flex-column">
    <div *ngIf="timelineProposal.statusType" class="d-flex flex-row w-100 proposal-status-container align-items-center">
      <div class="badge-{{ timelineProposal.statusType.toLowerCase() }}"></div>
      <div class="d-flex flex-column">
        <p>{{ timelineProposal.lastUpdateDate | date : "dd/MM/yyyy" }}</p>
        <p class="bold">
          {{ "proposal.status." + timelineProposal.statusType | translate }} -
          {{ timelineProposal.proposerUserSurename }} {{ timelineProposal.proposerUserRole != 'MANAGER' ?
          ('(' + timelineProposal.proposerUserRole + ')') : '' }}
        </p>
      </div>
    </div>
    <div *ngIf="timelineProposal.proposalEconomicsDetails?.length && timelineProposal.statusType != 'DELETED'">
      <p class="intervention-text" *ngFor="let intervention of timelineProposal.proposalEconomicsDetails">
        {{"proposal.types." + intervention.proposalEconomicMeasureType| translate}}
        {{
        ( intervention.amount ? (intervention.amount | currency: 'EUR' : true:'1.0-0') : "")
        || (intervention.absorbedAmount ? (intervention.absorbedAmount | currency: 'EUR' : true:'1.0-0') : "")
        || (intervention.percentage ? (intervention.percentage + '%') : "")}}
      </p>
    </div>
    <p *ngIf="!timelineProposal.proposalEconomicsDetails?.length && timelineProposal.statusType != 'DELETED'"
      class="intervention-text">
      {{ "proposal.types." + timelineProposal.proposalType | translate }}
    </p>
    <p *ngIf="timelineProposal.statusType == 'DELETED'" class="intervention-text">
      {{ "proposal.types.NO_INTERVENTION" | translate }}
    </p>
    <p class="note-text" *ngIf="timelineProposal.proposalNote?.length">
      {{ timelineProposal.proposalNote }}
    </p>
  </div>
</div>