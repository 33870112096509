import { ErrorHandler, Injectable, inject } from "@angular/core";
import { I18n } from "src/ancestors/i18n/i18n.module";

@Injectable()
export class TranslateService {
  private i18n = inject(I18n);
  private errorHandler = inject(ErrorHandler);
  private _translation?: Record<string, string>;

  constructor() {
    this.loadTranslation();
  }

  public loadTranslation(): void {
    this.i18n.getLocale().then((t) => {
      this._translation = t.default as Record<string, string>; // eslint-disable-line
    }).catch((error: unknown) => {
      this.errorHandler.handleError(error);
    });
  }

  /**
   * Passando una stringa o un array di stringhe ritorna la/le traduzioni
   * 
   * @param translateKey Chiave della traduzione
   * @param textTransform Possibilita di trasformare il testo in upperCase, lowerCase o capitalize
   * @returns Traduzione
   */
  public instant<T>(translateKey: string | string[], options?: { transform?: "upper-case" | "lower-case" | "capitalize", arg?: { [k: string]: string } }): T {
    if (Array.isArray(translateKey)) {
      return translateKey.map((key: string) => this.translation?.[key] ?? key)
      .filter(Boolean) as T;
    }
    
    if (!this.translation?.[translateKey]) {
      return translateKey as T;
    }
    
    let returnMsg;
    
    if (options?.arg) {
      for (const key in options?.arg) {
        returnMsg = this.translation[translateKey].replace(`{{${key}}}`, options?.arg[key]);
      }
    }

    if (options?.transform == "upper-case") {
      returnMsg = this.translation[translateKey].toUpperCase() as T;
    }
    if (options?.transform == "lower-case") {
      returnMsg = this.translation[translateKey].toLowerCase() as T;
    }
    if (options?.transform == "capitalize") {
      returnMsg = `${this.translation[translateKey][0].toUpperCase()}${this.translation[translateKey].slice(1).toLowerCase()}` as T;
    }
    return (returnMsg ?? this.translation[translateKey]) as T;
  }

  public has(translateKey?: string | string[]): boolean {
    if (!translateKey) return false;

    if (Array.isArray(translateKey)) {
      return translateKey.map((key: string) => this.translation?.[key])
        .every(Boolean);
    }

    return this.translation?.[translateKey] ? true : false;
  }

  /**
   * Oggetto traduzioni da usare nelle pipe
   */
  public get translation() {
    return this._translation;
  }
}