import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
// import { UrlCollection } from "../app-routing.module";
import { UserService } from "./user.service";
import { UrlCollection } from "../app-routing.module";
import { ApplicationDataService } from "./application-data.service";


export const RedirectToSelectableTypeUrlFn: CanActivateFn = async (): Promise<boolean> => {

    const appService = inject(ApplicationDataService);
    const router: Router = inject(Router);

    if (appService.selectableTypes.length > 0) {
        return true;
    } else {
        await router.navigate([UrlCollection.homepage]);
    }


    return false;
};

export const AdminGuard: CanActivateFn = (): boolean => {
    const usr = inject(UserService);

    if (usr.isAdmin()) {
        return true;
    }

    return false;
};

export const HrbpGuard: CanActivateFn = (): boolean => {
    const usr = inject(UserService);

    if (usr.isHRBP()) {
        return true;
    }

    return false;
};

export const ManagerGuard: CanActivateFn =  (): boolean => {
    const usr = inject(UserService);
    if (usr.isManager()) {
        return true;
    }

    return false;
};


