import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation, inject } from "@angular/core";
import { LoggerService } from "src/ancestors/logger.service";
import { BaseModalService } from "./base-modal.service";


@Component({
  selector: "base-modal",
  templateUrl: "./base-modal.component.html",
  styleUrl: "./base-modal.component.scss",
  encapsulation: ViewEncapsulation.None
})
export class BaseModalComponent implements OnInit {
  constructor() {
    this.element = this.el.nativeElement as HTMLElement;
  }

  private logger = inject(LoggerService);
  private modal = inject(BaseModalService);
  private el = inject(ElementRef);
  private element: HTMLElement;
  private size?: string;

  @Input() id!: string;
  @Input() mandatory?: boolean;
  @Input() largeModal?: boolean;

  @Output() onClose = new EventEmitter<void>();
  @Output() onOpen = new EventEmitter<void>();

  ngOnInit() {
    // ensure id attribute exists
    if (!this.id) {
      this.logger.warn("modal must have an id");
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", (el) => {
      if (((el.target) as HTMLTextAreaElement).className === "modal" && !this.mandatory) {
        this.close();
        this.onClose.emit();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modal.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy() {
    this.modal.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(opt?: { dimension: "lg" | "md" | "sm" | "full" | "auto" }): void {
    this.element.style.display = "block";
    document.body.classList.add("modal__open");
    if (opt?.dimension) {
      document.body.classList.add(`modal__size-${opt.dimension}`);
      this.size = `modal__size-${opt.dimension}`;
    } else {
      document.body.classList.add("modal__size-default");
    }
    this.onOpen.emit();
  }

  // close modal
  close(): void {
    this.element.style.display = "none";
    document.body.classList.remove("modal__open");
    if (this.size) document.body.classList.remove(this.size);
    else document.body.classList.remove("modal__size-default");
    this.onClose.emit();
  }
}
