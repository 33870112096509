<div [ngClass]="{'isLast': isLast, 'withoutBorderRight': withoutBorderRight }"
    *ngIf="!COLUMNS_WITH_TOOLTIP.includes(titleId ?? '')"
    class="d-flex justify-content-center align-items-center flex-column proposal-header-table-container">
    <ng-template [ngTemplateOutlet]="headerCellContent"></ng-template>
</div>

<div [ngClass]="{'isLast': isLast, 'withoutBorderRight': withoutBorderRight}"
    class="d-flex justify-content-center align-items-center flex-column proposal-header-table-container"
    *ngIf="COLUMNS_WITH_TOOLTIP.includes(titleId ?? '')" placement="top"
    [ngbTooltip]="'proposal.table.header.TOOLTIP_' + titleId | translate">
    <ng-template [ngTemplateOutlet]="headerCellContent"></ng-template>
</div>


<ng-template #headerCellContent>
    <div class="d-flex flex-row align-items-center gap8 justify-content-center">
        <p class="title-text">
            {{ text != null ? text : 'proposal.table.header.' + titleId | translate}} </p>
        <ng-container *ngIf="titleId != 'STI' && titleId != 'GRADING'">
            <button (click)="changeOrderTitle()" class="table-header-button" aria-label="Modifica ordinamento ">
                <!-- Icone inattive -->
                <img *ngIf="titleId != activeId && sortDirectionTitle=='ASC'"
                    src="./assets/icons/order-asc-not-active-budget-moving-table.svg" alt="">
                <img *ngIf="titleId != activeId && sortDirectionTitle=='DESC'"
                    src="./assets/icons/order-desc-not-active-budget-moving-table.svg" alt="">
                <!-- Icone attive -->
                <img *ngIf="titleId == activeId && activeSortDirection=='DESC'"
                    src="./assets/icons/order-desc-active-budget-moving-table.svg" alt="">
                <img *ngIf="titleId == activeId && activeSortDirection=='ASC'"
                    src="./assets/icons/order-asc-active-budget-moving-table.svg" alt="">
            </button>
        </ng-container>
    </div>
</ng-template>