<ng-container *ngIf="tableData != null">
    <table>
        <tr class="header">
            <th>{{ "proposal.dashboard.expanded.table.gender.POP" | translate }}</th>
            <th class="right">{{ tableData.totUsers }} </th>
        </tr>
        <ng-container *ngFor="let detail of tableData.genderTypesWithDetails">
            <tr>
                <td class="cell-container">
                    <div class="dot" [ngClass]="detail.genderType"></div>
                    {{ ("proposal.gender." + detail.genderType) | translate}}
                </td>
                <td class="right">
                    {{ detail.totUsers }} ({{(detail.totUsersPercentage|| 0) | number: '1.0-1'}})%
                </td>
            </tr>
        </ng-container>
    </table>
</ng-container>