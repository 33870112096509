import { Component, Input, inject } from "@angular/core";
import { ToastService } from "./toast.service";

@Component({
  selector: "toast",
  templateUrl: "./toast.component.html",
  styleUrl: "./toast.component.scss"
})
export class ToastComponent {
  protected toastService = inject(ToastService);
  @Input() hideAfter?: number = 4000;

  ngOnDestroy() {
    this.toastService.clear();
  }
}
