import { Injectable, inject } from "@angular/core";
import { AppLoginInfo, AuthProfilesKeys, AuthProfilesKeysEnumTypes } from "salary-review-cl";
import { BaseDataService } from "src/ancestors/base-data.service";
import { TokenStorageService } from "./token-storage.service";

export type BaseLoginInfo = AppLoginInfo;

@Injectable()
export class UserService extends BaseDataService {

    private tkStorage = inject(TokenStorageService);
    private selectedProfile?: AuthProfilesKeys;

    constructor() {
        super();
    }

    public isAdmin(): boolean {
        const USER = this.tkStorage.retriveToken(this.tokenKey);
        return USER.jwtPayload.profiles?.includes("ADMIN") ?? false;
    }

    public isManager(): boolean {
        const USER = this.tkStorage.retriveToken(this.tokenKey);
        return USER.jwtPayload.profiles?.includes("MANAGER") ?? false;
    }

    public isUser(): boolean {
        const USER = this.tkStorage.retriveToken(this.tokenKey);
        return USER.jwtPayload.profiles?.includes("USER") ?? false;
    }

    public isHRBP(): boolean {
        const USER = this.tkStorage.retriveToken(this.tokenKey);
        return USER.jwtPayload.profiles?.includes("HRBP") ?? false;
    }

    public getAuth() {
        const USER = this.tkStorage.retriveToken(this.tokenKey);
        return USER.jwtPayload.profiles ?? [];
    }

    public setSelectedProfile(selectedProfile: AuthProfilesKeys) {
        this.selectedProfile = selectedProfile;
        sessionStorage.setItem("selectedProfile", selectedProfile);
    }

    public getSelectedProfile(): AuthProfilesKeys {
        if (sessionStorage.getItem("selectedProfile")?.length) {
            const selectedProfileFromSessionStorage = sessionStorage.getItem("selectedProfile") as AuthProfilesKeys;
            return selectedProfileFromSessionStorage;
        } else {
            // Determino il profilo di default dell'utente loggato dando una priorità nel caso in cui esso abbia più profili abbinati a sé
            return this.selectedProfile
                ?? (this.isAdmin() && AuthProfilesKeysEnumTypes.ADMIN
                    || this.isHRBP() && AuthProfilesKeysEnumTypes.HRBP
                    || this.isManager() && AuthProfilesKeysEnumTypes.MANAGER
                    || AuthProfilesKeysEnumTypes.USER);
        }
    }

    public getJwtPayload() {
        const USER = this.tkStorage.retriveToken(this.tokenKey);
        return USER.jwtPayload;
    }

    clearSelectedProfile() {
        this.selectedProfile = undefined;
        sessionStorage.removeItem("selectedProfile");
    }

}
