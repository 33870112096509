<!-- Loader -->
<loader *ngIf="isLoadingData; else tableTemplate" [marginBlock]="'150px'"></loader>
<!-- Tabella con o senza dati -->
<ng-template #tableTemplate>

    <div class="table-shadow-container">
        <div class="table-container" *ngIf="!isLoadingData && count">
            <table class="table text-table table-striped proposal-table" *ngIf="!isLoadingData && count > 0">
                <thead>
                    <tr>
                        <th *ngFor="let header of headerList; let last = last"
                            [ngClass]="{'limit-size': ['STRUCTURE_NAME', 'LAST_MEASURE','CURRENT_PROPOSAL'].includes(header.titleId), 'performance': ['LAST_PERFORMANCE_OVERALL'].includes(header.titleId), 'proposal': ['CURRENT_PROPOSAL'].includes(header.titleId)}">
                            <proposal-header-table [titleId]="header.titleId" [text]="header.text"
                                (changeOrder)="changeOrderTitle($event)" [isLast]="last" [activeId]="sortData.sortBy"
                                [activeSortDirection]="sortData.sortType">
                            </proposal-header-table>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of list">
                        <!-- Nome, cognome, sesso, età -->
                        <td>
                            <div class="two-row-cell">
                                <p class="label">{{ user.forename }} {{ user.surname }}</p>
                                <p class="info">
                                    {{ ('proposal.table.genderType.' + user.genderType ) | translate }}
                                    {{ user.age + ' ' + ('proposal.table.YEARS' | translate) }}
                                </p>
                            </div>
                        </td>
                        <!-- Unità organizzativa -->
                        <td>
                            {{ user.structureName }}
                        </td>
                        <!-- Inquadramento -->
                        <td>{{ user.salaryLevel }}</td>
                        <!-- RAL  ANNUAL_RETRIBUTION-->
                        <td>
                            <div class="two-row-cell">
                                <p class="label">{{ user.annualSalary | currency: 'EUR' : true:'1.0-0'}}</p>
                                <p class="info"
                                    *ngIf="user.absorbableAmount != undefined && user.absorbableAmount != null && user.absorbableAmount > 0">
                                    <span position="top" *ngIf="!columnsWithoutTooltip.includes('ADP')"
                                        [ngbTooltip]="'proposal.table.header.TOOLTIP_ADP' | translate">ADP</span> {{
                                    user.absorbableAmount | currency: 'EUR' : true:'1.0-0' }}
                                </p>
                            </div>
                        </td>
                        <!-- Equità -->
                        <td>
                            <equity-cell [isPositive]="user.equityAmount > 0" [value]="user.equityAmount"
                                [percentage]="user.equityPercentage"></equity-cell>
                        </td>
                        <!-- STI -->
                        <td>
                            <div class="two-row-cell">
                                <p class="label">{{ user?.stiCategory || '' }}</p>
                                <ng-container *ngIf="checkStiType(user)">
                                    <p class="info" *ngIf="getStiDetailsAmount(user) > 0">
                                        {{(
                                        ("proposal.table." + user?.stiDetails?.stiType) | translate) + " " +
                                        (getStiDetailsAmount(user) | currency: 'EUR' :
                                        true:'1.0-0') }}</p>
                                    <p class="info" *ngIf="getStiDetailsPercentage(user) > 0">
                                        {{(("proposal.table." + user?.stiDetails?.stiType) | translate) + " " +
                                        getStiDetailsPercentage(user) + "%"}}
                                    </p>
                                </ng-container>
                            </div>
                        </td>
                        <!-- Valutazione performance -->
                        <td>
                            <div class="d-flex flex-column justify-content-center align-items-end">
                                <ng-container *ngIf="user.performanceOverallEvaluation; else noPOE">
                                    <p class="performance-evaluation-text">{{
                                        convertPerformance(user.performanceOverallEvaluation)
                                        }}</p>
                                    <p class="performance-evaluation-text">{{ ('proposal.table.performanceOverall.' +
                                        user.performanceOverallEvaluation) | translate}}
                                    </p>
                                </ng-container>
                                <ng-template #noPOE>
                                    <p class="performance-evaluation-text">
                                        {{ 'proposal.table.performanceOverall.NO_PERFORMANCE' | translate }}
                                    </p>
                                </ng-template>
                            </div>
                        </td>
                        <!-- Interventi -->
                        <td>
                            <div class="d-flex flex-column justify-content-center align-items-end">
                                <ng-container *ngIf="user.measuresHistory?.length; else noOps">
                                    <p *ngFor="let measure of user.measuresHistory"
                                        [ngbTooltip]=" ('proposal.table.measures.' + measure.type | translate) + ': '  + ('proposal.types.' + measure.type | translate)">
                                        {{ "proposal.table.measures." + measure.type | translate}} {{
                                        measure.amount | currency: 'EUR' : true:'1.0-0'}}
                                    </p>
                                </ng-container>
                                <ng-template #noOps>
                                    <p class="right">{{ 'proposal.table.measures.NO_MEASURE' | translate }}</p>
                                </ng-template>
                            </div>
                        </td>
                        <!-- Proposte salary-->
                        <td>
                            <proposal-table-action-cell [id]="user.forename+'-'+user.surname" [data]="user.proposal"
                                (onPropose)="onPropose.emit(user)" [canEdit]="user.canEdit" [teamType]="teamType"
                                [phaseInfo]="phaseInfo">
                            </proposal-table-action-cell>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <pagination *ngIf="!isLoadingData && count" [collectionSize]="count || 0" [maxSize]="3" [currentPage]="page"
        [ariaLabel]="'Paginazione per tabella proposte'" [pageSize]="pageSize" (pageChange)="changePageClicked($event)">
    </pagination>

    <ng-container *ngIf="!isLoadingData && count == 0">
        <div class="w-100 d-flex justify-content-center align-items-center">
            <h4>{{ "proposal.NO_RESULTS_FOR_SEARCH" | translate }}</h4>
        </div>
    </ng-container>

</ng-template>