import { Component } from "@angular/core";

@Component({
    selector: "app-admin",
    templateUrl: "./admin.component.html",
    styleUrl: "./admin.component.scss",
    standalone: false
})
export class AdminComponent {

}
