<ul class="w-100">
    <ng-container *ngFor="let node of nodes">
        <li class="node-tree-item" [ngClass]="{ 'open': node.isOpen && node.children.length}">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <button tabindex="0" type="button"
                    class="btn title-container d-flex w-100 justify-content-start align-items-center"
                    (click)="toggleNode(node)" [attr.aria-expanded]="node.isOpen">
                    <nga-icon *ngIf="node.children?.length && !node.isOpen" src="assets/icons/add-circle-outline.svg">
                    </nga-icon>
                    <nga-icon *ngIf="node.children?.length && node.isOpen" src="assets/icons/remove-circle-outline.svg">
                    </nga-icon>
                    <div *ngIf="!node.children?.length" class="icon-placeholder"></div>
                    <p>{{ node.name | titlecase }}</p>
                </button>

                <p-checkbox inputId="{{node.code}}" (onChange)="onSelectNode(node)" [binary]="true"
                    [ariaLabelledBy]="node.name" [(ngModel)]="node.isSelected" />
            </div>
        </li>
        <li>
            <ul class="border-left-tree">
                <li>
                    <h-tree-node *ngIf="node.isOpen && hasChildren(node)" [nodes]="node.children"></h-tree-node>
                </li>
            </ul>
        </li>
    </ng-container>
</ul>