import { Component, Input } from "@angular/core";
import { ProposalGenderExtendedDashboard } from "salary-review-cl";

@Component({
    selector: "proposal-table-dots-gender",
    templateUrl: "./proposal-dashboard-table-dots-gender.component.html",
    styleUrl: "./proposal-dashboard-table-dots-gender.component.scss"
})
export class ProposalDashboardTableDotsGenderComponent {

    @Input()
    tableData: ProposalGenderExtendedDashboard | null = null;

}


