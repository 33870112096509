<aside aria-label="Menu principale">
  <nav aria-labelledby="main-menu-label">
    <div class="oops-drawer-container">
      <div class="drawer__container" [class.show]="show">
        <div class="drawer__container-header">
          <button class="btn" (click)="showChange.emit(false)" aria-label="Chiudi menu">
            <img src="../../../../assets/icons/close-red.svg" alt="Icona chiudi">
          </button>

          <h2 id="main-menu-label">{{ title ?? ("generic.MENU" | translate) }}</h2>
        </div>
        <ul class="drawer__container-options">
          <ng-container *ngFor="let item of items">
            <li *ngIf="item.canDisplay || item.canDisplay == undefined">
              <button class="btn menu-option-button" href="#" (click)="onClick.emit(item); showChange.emit(false)">
                {{ item.name }}
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="drawer__overlay" *ngIf="show" (click)="showChange.emit(false)">
      </div>
    </div>
  </nav>
</aside>