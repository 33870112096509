import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { BaseComponent } from "src/ancestors/base/base.component";
import { UrlCollection } from "./app-routing.module";
import { TranslateService } from "./shared/util/translate.service";
import { BaseLoginInfo } from "./global-service/user.service";
import { DrawerItems } from "./shared/components/drawer/drawer.component";
import { PayloadParams, User } from "salary-review-cl";
import { JwtPayload } from "sharedclasses";
import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { PrimeNGConfig } from "primeng/api";



@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]

})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  protected fb: FormBuilder = inject(FormBuilder);
  protected translate = inject(TranslateService);
  protected tooltipService = inject(NgbTooltipConfig);

  constructor(private primengConfig: PrimeNGConfig) {
    super();
    this.logger.setCaller("AppComponent");
  }

  protected showUserOption?: boolean;
  protected drawerItems: DrawerItems[] = [];

  impersonifiedData: JwtPayload<User, PayloadParams> | undefined = undefined;

  override async ngOnInit(): Promise<void> {
    const loginResposnse: BaseLoginInfo = this.tStorage.retriveToken(this.tokenKey);
    this.tooltipService.tooltipClass = "custom-tooltip";
    /**
     * 
     * Se esiste un token ed è ancora valido schedula il rinnovo prima della scadenza
    */
    if (!this.appService.isEmpty(loginResposnse) && this.tkManager.checkTokenValidity(loginResposnse)) {
      this.tkManager.refreshTokenScheduler(loginResposnse);
    }

    if (!sessionStorage.getItem("originalRequestedUrl") && this.env.loginType === "saml-sso") {
      await this.router.navigate([UrlCollection.redirectTo]);
    }

    sessionStorage.removeItem("originalRequestedUrl");

    this.impersonifiedData = this.usrService.getJwtPayload();
    this.primengConfig.setTranslation({
      "accept": "Si",
      "addRule": "Aggiungi regola",
      "am": "AM",
      "apply": "Applica",
      "cancel": "Annulla",
      "choose": "Scegli",
      "chooseDate": "Seleziona Data",
      "chooseMonth": "Seleziona Mese",
      "chooseYear": "Seleziona Anno",
      "clear": "Cancella tutto",
      "contains": "Contiene",
      "dateAfter": "La data è successiva",
      "dateBefore": "La data è precedente",
      "dateFormat": "dd/mm/yy",
      "dateIs": "La data è",
      "dateIsNot": "La data non è",
      "dayNames": [
        "Domenica",
        "Lunedi",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato"
      ],
      "dayNamesMin": [
        "Do",
        "Lu",
        "Ma",
        "Me",
        "Gi",
        "Ve",
        "Sa"
      ],
      "dayNamesShort": [
        "Dom",
        "Lun",
        "Mar",
        "Mer",
        "Gio",
        "Ven",
        "Sab"
      ],
      "emptyFilterMessage": "Nessuna opzione disponibile",
      "emptyMessage": "Nessun risultato trovato",
      "emptySearchMessage": "Nessun risultato trovato",
      "emptySelectionMessage": "Nessun elemento selezionato",
      "endsWith": "Finisce con",
      "equals": "Equivale",
      "fileSizeTypes": [
        "B",
        "KB",
        "MB",
        "GB",
        "TB",
        "PB",
        "EB",
        "ZB",
        "YB"
      ],
      "firstDayOfWeek": 1,
      "gt": "Maggiore di",
      "gte": "Maggiore o uguale a",
      "lt": "Minore di",
      "lte": "Minore o uguale a",
      "matchAll": "Abbina tutto",
      "matchAny": "Abbina alcuni",
      "medium": "Medio",
      "monthNames": [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre"
      ],
      "monthNamesShort": [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic"
      ],
      "nextDecade": "Decade successiva",
      "nextHour": "Ora successiva",
      "nextMinute": "Minuto successivo",
      "nextMonth": "Mese successivo",
      "nextSecond": "Secondo successivo",
      "nextYear": "Anno successivo",
      "noFilter": "Senza Filtro",
      "notContains": "Non contiene",
      "notEquals": "Non uguale",
      "passwordPrompt": "Inserisci la password",
      "pending": "In corso",
      "pm": "PM",
      "prevDecade": "Decade precedente",
      "prevHour": "Ora precedente",
      "prevMinute": "Minuto precedente",
      "prevMonth": "Mese precedente",
      "prevSecond": "Secondo precedente",
      "prevYear": "Anno precedente",
      "reject": "No",
      "removeRule": "Rimuovi regola",
      "searchMessage": "{0} risultati disponibili",
      "selectionMessage": "{0} elementi selezionati",
      "startsWith": "Inizia con",
      "strong": "Forte",
      "today": "Oggi",
      "upload": "Carica",
      "weak": "Debole",
      "weekHeader": "Sett",
      "aria": {
        "cancelEdit": "Annulla modifica",
        "close": "Chiudi",
        "collapseRow": "Riduci riga",
        "editRow": "Modifica riga",
        "expandRow": "Espandi riga",
        "falseLabel": "Falso",
        "filterConstraint": "Costante di filtro",
        "filterOperator": "Operatore di filtro",
        "firstPageLabel": "Prima pagina",
        "gridView": "Griglia",
        "hideFilterMenu": "Nascondi Menu filtri",
        "jumpToPageDropdownLabel": "Vai alla Dropdown delle pagine",
        "jumpToPageInputLabel": "Vai all'Input delle pagine",
        "lastPageLabel": "Ultima pagina",
        "listView": "Lista",
        "moveAllToSource": "Muovi tutto alla sorgente",
        "moveAllToTarget": "Muovi tutto all'elemento",
        "moveBottom": "Vai in fondo",
        "moveDown": "Vai sotto",
        "moveTop": "Vai in cima",
        "moveToSource": "Vai alla sorgente",
        "moveToTarget": "Vai all'elemento",
        "moveUp": "Vai sopra",
        "navigation": "Naviga",
        "next": "Successivo",
        "nextPageLabel": "Pagina successiva",
        "nullLabel": "Non selezionato",
        "pageLabel": "Pagina {page}",
        "previous": "Precedente",
        "previousPageLabel": "Pagina precedente",
        "removeLabel": "Rimuovere",
        "rotateLeft": "Ruota a sinistra",
        "rotateRight": "Ruota a destra",
        "rowsPerPageLabel": "Elementi per pagina",
        "saveEdit": "Salva modifica",
        "scrollTop": "Torna sù",
        "selectAll": "Seleziona tutti gli elementi",
        "selectRow": "Seleziona riga",
        "showFilterMenu": "Mostra Menu filtri",
        "slide": "Scorri",
        "slideNumber": "{slideNumber}",
        "star": "1 stella",
        "stars": "{star} stelle",
        "trueLabel": "Vero",
        "unselectAll": "Deseleziona tutti gli elementi",
        "unselectRow": "Deseleziona riga",
        "zoomImage": "Zoom Immagine",
        "zoomIn": "Ingrandisci",
        "zoomOut": "Riduci"
      }
    });
  }

  /** Questa funzione cambia la lingua a runtime, e fa il reload della pagina */
  private changeLang(locale: string) {

    /** Salva la lingua nel localStorage */
    localStorage.setItem("locale", locale);

    /** Fa il reload della pagina, per caricare il file json corretto */
    location.reload();
  }

  protected async doLogout() {
    this.showUserOption = false;
    try {
      const redirectToLogin: string = sessionStorage.getItem("SSO_LOGIN") ? "Saml-sso/logoutSaml" : this.env.loginUrl;
      // cancello la variabile che mi dice se ho fatto il login con l'sso 
      sessionStorage.removeItem("SSO_LOGIN");
      sessionStorage.removeItem("selectedProfile");
      sessionStorage.removeItem("selectedSidebarManagerRole");
      this.usrService.clearSelectedProfile();
      await this.backendService.logout(redirectToLogin);
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  protected loadMenuOptions() {
    this.drawerItems = [];
    this.drawerItems.push(
      {
        name: this.translate.instant("generic.LOGOUT"),
        redirectTo: UrlCollection.localAuthentication,
        id: "LOGOUT"
      }
    );
  }
  protected async onNavTabClick(path: DrawerItems) {
    if (path?.id === "LOGOUT") {
      await this.doLogout();
    }
    return this.router.navigate([path.redirectTo]);
  }

  protected async returnToMyProfile() {
    const loginResponse = await this.backendService.loginOriginalUser();
    this.tStorage.saveToken(this.tokenKey, loginResponse);

    this.tkManager.refreshTokenScheduler(loginResponse);
    this.usrService.clearSelectedProfile();
    sessionStorage.removeItem("selectedSidebarManagerRole");
    // recupero l'oggetto impersonifyType per capire in che pagina di impersonifica andare, se non c'è vado in STANDARD
    const impersonifyType: string = sessionStorage.getItem("impersonifyType") ?? "STANDARD";
    await this.router.navigate(["/admin/" + UrlCollection.impersonify + "/" + impersonifyType]);
  }

  getImpersonifiedData() {
    const payload = this.usrService.getJwtPayload();
    return {
      forename: payload?.user?.forename,
      surname: payload?.user?.surname,
      impersonifiedByUserRole: payload?.params?.impersonifiedByUserRole
    };
  }

  protected skipToContent() {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.focus();
    }
  }
}
