/* eslint-disable no-console */
import { Injectable } from "@angular/core";
import { GeneralConfig, EnvironmentLoaderService } from "src/ancestors/env-config.service";

/** Definisce i livelli dei log. Ad esempio se il livello scelto è warn, mostra i warn e gli errori ma non info. */
export enum LogLevel {
    off = 0,
    error = 1,
    warn = 2,
    info = 3,
    debug = 4,
    all = 5
}

/**
 * Logger centralizzato.
 * Questa classe crea un oggetto logger per standardizzare ed estendere i log.
 * Mostra i log in base al livello di log scelto nella config
 *
 * Per utilizzarlo in un componente:
 * 1. Importarlo e aggiungerlo a providers
 * 2. Nel costruttore settare la variabile caller, ad es. this.logger.setCaller("ComponentName");
 * 3. Per utilizzarlo usare this.logger.error("error message")
 */


@Injectable()
export class LoggerService {
    constructor(private envConfig: EnvironmentLoaderService) { }
    private env: GeneralConfig = this.envConfig.getEnvConfig();
    /** Nome del chiamante, da mostrare nel log */
    private caller = "default";
    /** Livello di log da mostrare */
    private level: LogLevel = this.env.loggerLevel;
    /** Crea la stringa e la stampa in console */

    private log(severity: string, error: string, detailMessage?: string, stack?: string): void {
        /** Data e ora del log */
        const caller = `[Caller: ${this.caller}]`;
        const date = `[Date: ${new Date().toUTCString()}]`;
        const detail = `${detailMessage ? "[DetailMessage: " + detailMessage + "]" : ""}`;

        /** Stringa da mostrare in console */
        const stringToLog =
            `${severity}: ${caller} ${date} [${error}] ${detail} ${stack ? stack : ""}`;

        if (severity === "ERROR") {
            console.error(stringToLog);
        } else {
            console.log(stringToLog);
        }
    }

    /** Controlla se mostrare il log in base al livello di log scelto nella config */
    private shouldLog(messageLevel: LogLevel): boolean {
        return messageLevel <= this.level;
    }

    /** Setta il nome del chiamante */
    public setCaller(name: string): void {
        this.caller = name;
    }

    /** Mostra un errore */
    public error(msg: string, detail?: string, stack?: string) {
        if (!this.shouldLog(LogLevel.error)) {
            return;
        }

        this.log("ERROR", msg, detail, stack);
    }

    public info(info: unknown) {
        if (!this.shouldLog(LogLevel.error)) {
            return;
        }

        console.info(info);
    }

    public warn(warn: unknown) {
        if (!this.shouldLog(LogLevel.error)) {
            return;
        }

        console.warn(warn);
    }
}

/* eslint-enable no-console */
