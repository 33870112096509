<div class="homepage-content">
    <div class="main-container pb-10">
        <breadcrumb></breadcrumb>
    </div>
    <div class="d-flex justify-content-between align-items-center pb-24">
        <div class="d-flex gap-2">
            <p class="page-title">{{ "homepage.CHOOSE_SALARY_REVIEW" | translate }}</p>
        </div>
    </div>
    <div class="d-flex flex-row flex-wrap d-grid gap-4 justify-content-center" *ngIf="!isLoadingHomeData">
        <ng-container *ngFor="let panel of panelItems">
            <panel-card [src]="panel.srcImg" [description]="panel.description" [isAccessible]="panel.actionIsAvailable"
                [buttonLabel]="panel.buttonLabel" (onClick)="goToPage(panel.type)"></panel-card>
        </ng-container>
    </div>
    <loader *ngIf="isLoadingHomeData" [marginBlock]="'150px'"></loader>
</div>