import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BudgetSummary, ProcessTypeEnum, ProcessTypeEnumTypes, ProposalBudgetTypeDetails, ProposalDashboardSummary, ProposalGenderExtendedDashboard } from "salary-review-cl";
@Component({
    selector: "proposal-dashboard",
    templateUrl: "./proposal-dashboard.component.html",
    styleUrl: "./proposal-dashboard.component.scss",
    standalone: false
})
export class ProposalDashboardComponent {
  @Input()
  isLoadingSummary: boolean = false;

  @Input()
  isLoadingExpanded: boolean = false;

  @Input()
  summary: ProposalDashboardSummary | null = null;

  @Input()
  budgetData: BudgetSummary | null = null;

  @Input()
  budgetExtendedData: ProposalBudgetTypeDetails[] | null = null;

  @Input()
  genderData: BudgetSummary | null = null;

  @Input()
  genderExtendedData: ProposalGenderExtendedDashboard | null = null;

  @Input()
  processType: ProcessTypeEnum = ProcessTypeEnumTypes.STANDARD;

  protected isExpanded: boolean = false;


  @Output()
  onExpand: EventEmitter<void> = new EventEmitter<void>();
  activeTab: string = "BUDGET"; // BUDGET, GENDER

  onExpandClick(): void {
    if (!this.isExpanded) {
      this.onExpand.emit();
    } else {
      this.activeTab = "BUDGET";
    }
    this.isExpanded = !this.isExpanded;
  }

  showExpandedTab(tabName: string): void {
    this.activeTab = tabName;
  }

  hasBudgetExtendedData() {
    if (this.budgetExtendedData && this.budgetExtendedData.length > 0) {
      return true;
    }
    this.activeTab = "GENDER";
    return false;
  }

  hasGenderExtendedData() {
    if (this.genderExtendedData?.genderTypesWithDetails && this.genderExtendedData?.genderTypesWithDetails?.length > 0) {
      return true;
    }
    this.activeTab = "BUDGET";
    return false;
  }
}

