
import { ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from "@angular/core";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { TabItem } from "alloy-design-system";
import {
  AuthProfilesKeys,
  ExtraBudgetProposalReasonTypesEnum,
  ExtraBudgetProposalReasonTypesEnumTypes,
  GenderTypesEnum,
  HistoryMeasureTypesEnum,
  LastMeasureFilterEnumTypes,
  NumberRangeFilter,
  PerformanceOverallEvaluationsEnum,
  ProcessTypeEnum,
  ProcessTypeEnumTypes,
  ProposalAssignationDetails,
  ProposalBudgetTypeDetails,
  ProposalDashboardSummary,
  ProposalEconomicMeasureTypeDetails,
  ProposalEconomicMeasureTypesEnum,
  ProposalEconomicMeasureTypesEnumTypes,
  ProposalEconomicMeasureWarningTypesEnum,
  ProposalEconomicsDetails,
  ProposalErrorTypesEnum,
  ProposalFieldTypesEnum,
  ProposalFieldTypesEnumTypes,
  ProposalFieldValidationRequest,
  ProposalFilterAgeResponse,
  ProposalFilterAnnualSalaryResponse,
  ProposalFilterContractLevelResponse,
  ProposalFilterEquityResponse,
  ProposalFilterGroupSeniorityResponse,
  ProposalFilterLastMeasureResponse,
  ProposalFilterProposalStatusResponse,
  ProposalFilterProposalTypeResponse,
  ProposalFilterStiCategoryResponse,
  ProposalFilterStiDetailsResponse,
  ProposalFilterStructureResponse,
  ProposalFilterWarningResponse,
  ProposalFormInfo,
  ProposalGenderExtendedDashboard,
  ProposalPhaseInfo,
  ProposalSummary,
  ProposalTypesEnum,
  ProposalTypesEnumTypes,
  ProposalUpdateRequest,
  ProposalUserAnagDetails,
  ProposalUserDetails,
  ProposalsFilters,
  ProposalsSortEnum,
  ProposalsSortEnumTypes,
  SortTypesEnum,
  SortTypesEnumTypes,
  StiTypesEnum,
  StiTypesEnumTypes,
  StructureTreeNode,
  UserCategoriesEnum,
  UserProposalToBeConfirmed,
  StructureManagerRoleEnum,
  ProposalStatusTypesEnumTypes
} from "salary-review-cl";
import { MapById } from "sharedclasses";
import { BaseComponent } from "src/ancestors/base/base.component";
import { ProfileBasedAPIService } from "src/app/global-service/profile-based-api.service";
import { ProfileBasedAPIServiceProvider } from "src/app/global-service/profile-based-api.service.factory";
import { BreadcrumbService } from "src/app/shared/components/breadcrumb/breadcrumb.service";
import { HeaderList, SortDirectionType } from "src/app/shared/components/proposal-table/proposal-table.component";
import { ToastService } from "src/app/shared/components/toast/toast.service";
import { MenuItem, TreeNode } from "primeng/api";


export const tableHeaderIds: string[] = ["USER_FULL_NAME", "STRUCTURE_NAME", "GRADING", "ANNUAL_RETRIBUTION", "EQUITY", "STI", "LAST_PERFORMANCE_OVERALL", "LAST_MEASURE", "CURRENT_PROPOSAL"];

const FilterTypeByKey: { [key: string]: string } = {
  PROPOSAL_TYPE: "proposalTypes",
  PROPOSAL_STATUS: "proposalStatuses",
  WARNING: "warnings",
  AGE: "age",
  GROUP_SENIORITY: "groupSeniority",
  GENDER: "genderTypes",
  EMPLOYEE_TYPE: "employeeTypes",
  CONTRACT_LEVEL: "contractLevelIds",
  LAST_MEASURE: "lastMeasure",
  EQUITY: "equity",
  ANNUAL_SALARY: "annualSalary",
  STI_CATEGORY: "stiCategory",
  STI_DETAILS: "stiDetails",
  STRUCTURE: "structureIds",
  PERFORMANCE_OVERALL: "performanceOverallEvaluations"
};

const FilterTypeByName: { [key: string]: string } = {
  "proposalTypes": "PROPOSAL_TYPE",
  "proposalStatuses": "PROPOSAL_STATUS",
  "warnings": "WARNING",
  "age": "AGE",
  "groupSeniority": "GROUP_SENIORITY",
  "genderTypes": "GENDER",
  "employeeTypes": "EMPLOYEE_TYPE",
  "contractLevelIds": "CONTRACT_LEVEL",
  "lastMeasure": "LAST_MEASURE",
  "equity": "EQUITY",
  "annualSalary": "ANNUAL_SALARY",
  "stiCategory": "STI_CATEGORY",
  "stiDetails": "STI_DETAILS",
  "structureIds": "STRUCTURE",
  "performanceOverallEvaluations": "PERFORMANCE_OVERALL"
};


@Component({
  selector: "app-proposal",
  templateUrl: "./proposal.component.html",
  styleUrls: ["./proposal.component.scss"],
  providers: [BreadcrumbService, ProfileBasedAPIServiceProvider],
  standalone: false
})
export class ProposalComponent extends BaseComponent {
  protected breadcrumbService: BreadcrumbService = inject(BreadcrumbService);
  protected profileBasedAPIService: ProfileBasedAPIService = inject(ProfileBasedAPIService);
  private offcanvasService = inject(NgbOffcanvas);
  protected toast = inject(ToastService);
  @ViewChild("contentProposalSidebar") contentProposalSidebar!: ElementRef;
  @ViewChild("filtersProposalSidebar") filtersProposalSidebar!: ElementRef;
  @ViewChild("hierarchySidebar") hierarchySidebar!: ElementRef;
  protected isLoadingPhaseInfo: boolean = false;
  protected selectedProfile: string;
  protected processType: ProcessTypeEnum;
  protected processId: string;
  protected structureId: string;
  protected phaseInfo: ProposalPhaseInfo | null = null;
  protected tabList: MenuItem[] = [];
  protected activeTab: MenuItem = {} as MenuItem;
  protected dashboardSummary: ProposalDashboardSummary | null = null;
  protected isLoadingDashboardSummary: boolean = false;
  protected isLoadingDashboardExtendedData: boolean = false;
  protected budgetExtendedData: ProposalBudgetTypeDetails[] | null = null;
  protected genderExtendedData: ProposalGenderExtendedDashboard | null = null;
  protected isLoadingTableData: boolean = false;
  protected tableHeaderList: HeaderList[] = [];
  protected userCount: number = 0;
  protected userList: ProposalUserDetails[] | null = [];
  protected currentPage: number = 1;
  protected fromRecord: number = 0;
  protected numRecords: number = 10;
  protected filters: {
    data: FilterData,
    allowedLoaded: boolean;
    // reset dei filtri per tornare a situazione iniziale
    resetFilter: () => void;
    // Reset dei filtri + reload dei valori
    hardReset: () => void;
    // chiamato dalle chips sopra alla tabella, rimuove il filtro selezionato
    removeFilter: (id: string, key: string) => void;
    // applica i filtri, valorizzando il campo applied con il valore contenuto in selected al momento dell'applica
    applyFilters: () => void;
    // ritorna la lista di chips da visualizzare sopra la tabella
    getChips: () => { id: string, key: string, title: string }[];
    // per i tipi enum, ritorna i valori selezionabili come array di stringhe
    getValuesAsArray: (key: string) => string[];
    // per i tipi enum, ritorna i valori selezionati come array di stringhe
    getSelectedAsArray: (key: string) => string[];
    // per i tipi enum, ritorna i valori applicati come array di stringhe
    getAppliedAsArray: (key: string) => string[];
    // per i tipi enum, ritorna true se il valore è stato selezionato
    isSelected: (key: string, value: string) => boolean;
    // per i tipi enum, seleziona/deseleziona il valore
    toggleStringFilter: (key: string, value: string) => void;
    // per i tipi range, ritorna il minimo recuperato dai servizi
    getValueMin: (key: string) => number;
    // per i tipi range, ritorna il massimo recuperato dai servizi
    getValueMax: (key: string) => number;
    // per i tipi range, ritorna il minimo inserito dall'utente
    getSelectedMin: (key: string) => number | null;
    // per i tipi range, ritorna il massimo inserito dall'utente
    getSelectedMax: (key: string) => number | null;
    // per i tipi range, ritorna il minimo applicato per specifico filtro
    getAppliedMin: (key: string) => number | null;
    // per i tipi range, ritorna il massimo applicato per specifico filtro
    getAppliedMax: (key: string) => number | null;
    // aggiorna il range dei filtri range
    updateRangeFilter: (key: string, isMin: boolean, e: Event) => void;
    // per tipi {id, name} come contractLevelIds o structureIds, seleziona/deseleziona il valore
    toggleObjectFilter: (key: string, value: { id: string, name: string }) => void;
    // per tipi {id, name} come contractLevelIds o structureIds, ritorna se il valore è stato selezionato
    isObjectSelected: (key: string, value: { id: string, name: string }) => boolean;
    // per tipi (id, name ) come contractLevelIds o structureIds, ritorna i valori come oggetto generico
    getObjectValuesAsArray: (key: string) => { id: string, name: string }[];
    // per tipi (id, name ) come contractLevelIds o structureIds, ritorna gli id degli oggetti selezionati
    getObjectAppliedAsArray: (key: string) => string[];
    // per tipi composti (range + chips), ritorna i valori (chips) come array di stringhe
    getComboValuesAsArray: (key: string) => { id: string, name: string }[];
    // per tipi composti (range + chips), ritorna se i valori (chips) sono selezionati
    isComboSelected: (key: string, value: { id: string, name: string }) => boolean;
    // per tipi composti (range + chips), seleziona i valori
    toggleComboFilter: (key: string, value: { id: string, name: string }) => void;
    // per tipi composti (range + chips), ritorna un oggetto iterabile per l'html
    getComboSelectedAsArray: (key: string) => { id: string, name: string }[];
    // Ritorna true se la chiave è presente nell'oggetto
    checkKeyForRange: (key: string, fieldName: string) => boolean;
    // ritorna value di un filtro combo dato il nome del campo
    getComboValue: (key: string, fieldName: string) => number | null;
    // ritorna selected di un filtro combo dato il nome del campo (minAmount, maxPerc, ecc)
    getComboSelected: (key: string, fieldName: string) => number | null;
    // aggiorna il range dei filtri combo
    updateComboRangeFilter: (key: string, fieldName: string, value: Event) => void;
    // controlla se ho selezionato la chip per sbloccare i range di stiDetails
    checkComboAmountSelected: () => boolean;
    checkComboPercSelected: () => boolean;
    // Ritorna l'oggetto filtri da utilizzare per le chiamate ai servizi
    getFiltersForService: (onlyStructureIds?: boolean) => ProposalsFilters;
  };
  protected filtersKeys: FilterDataKeys[] = [];
  sortData: { sortBy: string; sortType: SortTypesEnum; } = {
    sortBy: ProposalsSortEnumTypes.USER_FULL_NAME,
    sortType: SortTypesEnumTypes.ASC
  };
  enumFilterTypeKey: Partial<keyof FilterData>[] = ["proposalTypes", "proposalStatuses", "warnings", "genderTypes", "employeeTypes", "performanceOverallEvaluations"];
  rangeFilterTypeKey: Partial<keyof FilterData>[] = ["age", "groupSeniority", "equity", "annualSalary"];
  comboFilterTypeKey: Partial<keyof FilterData>[] = ["stiCategory", "stiDetails", "lastMeasure"];

  isLoadingFilters: boolean = false;

  proposalTabs: TabItem[] = [];
  activeTabProposalId: string = "";
  isLoadingProposal: boolean = false;
  proposalAvailableOptions: ProposalOption[] = [];
  userProposalDetails: ProposalAssignationDetails | null = null;
  selectedProposalOptionType: ProposalOption | null = null;
  defaultOptionSelected: ProposalOption = {} as ProposalOption;
  proposalFormInfo: ProposalFormInfo[] | null = [];
  proposalFieldMap = new Map<ProposalEconomicMeasureTypesEnum, MapById<string | number>>();
  currentYear: number = new Date().getFullYear();
  evaluationsHistory: { year: number, yearLabel: string, evaluation: PerformanceOverallEvaluationsEnumstring | undefined }[] = [];
  interventionsHistory: { year: string, yearLabel: string, interventionsLabels: { label: string, amount: number, type: HistoryMeasureTypesEnum }[] }[] = [];
  additionalInfoProposalForm = new Map<ProposalEconomicMeasureTypesEnum, MapById<string | number | ProposalEconomicMeasureWarningTypesEnum[] | ProposalErrorTypesEnum[]>>();
  previewFormMap = new Map<ProposalEconomicMeasureTypesEnum, MapById<string | number>>();
  validateFormMap = new Map<ProposalEconomicMeasureTypesEnum, MapById<string | boolean | string[]>>();
  extraBudgetTypeOptions: ProposalOption[] = [];
  selectedOptionExtraBudgetType = new Map<ProposalEconomicMeasureTypesEnum, ProposalOption>();
  selectedOptionStiType = new Map<ProposalEconomicMeasureTypesEnum, ProposalOption>();
  stiTypeOptions: { id: StiTypesEnum, label: string }[] = [];
  proposalNote: string = "";
  warningTypesMap: Map<ProposalEconomicMeasureTypesEnum, string[]> = new Map();
  errorTypesMap: Map<ProposalEconomicMeasureTypesEnum, string[]> = new Map();
  selectedUser: ProposalUserDetails | null = null;
  proposalsToBeConfirmed: UserProposalToBeConfirmed[] = [];
  isDownloadingReport: boolean = false;
  passedOnBlurInput: boolean = false;
  firstTimeOpenedSidebarWithExistentPropose: boolean = false;
  anagUserDetails: ProposalUserAnagDetailsExtended | undefined = undefined;
  isLoadingAnagUserDetails: boolean = false;
  isLoadingTimelineDetails: boolean = false;
  timelineDetails: ProposalSummary[] | undefined = undefined;
  isLoadingHierarchyData: boolean = false;
  hierarchyData: StructureTreeNode[] = [];
  treeFormattedData: HierarchyNode[] = [];
  selectedItems: HierarchyNode[] = [];
  // formattedData: TreeNode[] = [] as TreeNode[];
  hierarchyDataLoaded: boolean = false;
  idToFocusAfterLoadingValues: string = "";
  activeTabProposal: MenuItem | undefined = undefined;

  chargingDataForHierarchy: boolean = false;
  filterOrganizationUnitText: string = "";
  columnsWithoutTooltip: string[] = [];
  constructor(
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.logger.setCaller("ProposalComponent");
    this.selectedProfile = this.router.url.split("/")[1].toUpperCase() as AuthProfilesKeys;
    this.processType = this.route.snapshot.paramMap.get("processType") as ProcessTypeEnum;
    this.processId = this.route.snapshot.paramMap.get("processId") as ProcessTypeEnum;
    this.structureId = this.route.snapshot.paramMap.get("structureId") as ProcessTypeEnum;

    if (this.processType == ProcessTypeEnumTypes.EXECUTIVE) {
      this.columnsWithoutTooltip.push("ADP");
    }

    // Inizializzo l'oggetto dei filtri
    this.filters = {
      data: {
        proposalTypes: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [],
          values: [],
          selected: [] as ProposalFilterProposalTypeResponse[]
        },
        proposalStatuses: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [],
          values: [],
          selected: [] as ProposalFilterProposalStatusResponse[]
        },
        warnings: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [],
          values: [],
          selected: [] as ProposalFilterWarningResponse[]
        },
        age: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: new ProposalFilterAgeResponse(),
          values: new ProposalFilterAgeResponse(),
          selected: new NumberRangeFilter()
        },
        groupSeniority: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: new ProposalFilterGroupSeniorityResponse(),
          values: new ProposalFilterGroupSeniorityResponse(),
          selected: new NumberRangeFilter()
        },
        genderTypes: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [],
          values: [],
          selected: [] as GenderTypesEnum[]
        },
        employeeTypes: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [],
          values: [],
          selected: [] as UserCategoriesEnum[]
        },
        contractLevelIds: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [] as ProposalFilterContractLevelResponse[],
          values: [] as ProposalFilterContractLevelResponse[],
          selected: [] as ProposalFilterContractLevelResponse[]
        },
        lastMeasure: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: new ProposalFilterLastMeasureResponse(),
          values: new ProposalFilterLastMeasureResponse(),
          selected: new ProposalFilterLastMeasureResponse()
        },
        equity: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: new NumberRangeFilter(),
          values: new NumberRangeFilter(),
          selected: new NumberRangeFilter()
        },
        annualSalary: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: new NumberRangeFilter(),
          values: new NumberRangeFilter(),
          selected: new NumberRangeFilter()
        },
        stiCategory: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [] as ProposalFilterStiCategoryResponse[],
          values: [] as ProposalFilterStiCategoryResponse[],
          selected: [] as ProposalFilterStiCategoryResponse[]
        },
        stiDetails: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: new ProposalFilterStiDetailsResponse(),
          values: new ProposalFilterStiDetailsResponse(),
          selected: new ProposalFilterStiDetailsResponse()
        },
        structureIds: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [] as ProposalFilterStructureResponse[],
          values: [] as ProposalFilterStructureResponse[],
          selected: [] as ProposalFilterStructureResponse[]
        },
        performanceOverallEvaluations: {
          active: false,
          loaded: false,
          isOpen: false,
          applied: [] as PerformanceOverallEvaluationsEnum[],
          values: [] as PerformanceOverallEvaluationsEnum[],
          selected: [] as PerformanceOverallEvaluationsEnum[]
        }
      },
      // dice se i filtri permessi per il tipo di utente sono già stati caricati
      allowedLoaded: true,
      toggleStringFilter: (key: string, value: string) => {
        if ((this.filters.data[key as keyof FilterData].selected as string[]).includes(value)) {
          (this.filters.data[key as keyof FilterData].selected as string[]) = JSON.parse(JSON.stringify(this.filters.getSelectedAsArray(key).filter((item) => item !== value))) as string[];
        } else {
          (this.filters.data[key as keyof FilterData].selected as string[]) = [...this.filters.getSelectedAsArray(key), value] as string[];
        }
      },
      getComboValuesAsArray: (key: string) => {
        if (key == "stiDetails" && this.filters.data.stiDetails?.values?.stiTypes?.length) {
          return this.filters.data.stiDetails?.values?.stiTypes?.map((item: StiTypesEnum) => {
            return {
              id: item as string,
              name: this.translateService.instant("proposal.filter.stiDetails." + item)
            };
          }) as { id: string, name: string }[];
        } else if (key == "lastMeasure") {
          const values: { id: string, name: string }[] = [];
          if (this.filters.data.lastMeasure.values?.type) {
            for (const item of this.filters.data.lastMeasure.values.type) {
              values.push({ id: item.toString(), name: this.translateService.instant("proposal.filter.lastMeasure." + item) });
            }
          }
          return values;
        } else if (key == "stiCategory") {
          return this.filters.data.stiCategory.values?.map((item: ProposalFilterStiCategoryResponse) => {
            return {
              id: item.stiCategory,
              name: item.stiCategory
            };
          }) as { id: string, name: string }[];
        }
        return [] as { id: string, name: string }[];
      },
      isComboSelected: (key: string, value: { id: string, name: string }) => {
        // Per stiDetails controllo se selected contiene value.id in selected.stiTypes
        if (key == "stiDetails" && this.filters.data.stiDetails.selected?.stiTypes) {
          return !!this.filters.data.stiDetails.selected.stiTypes.includes(value.id as StiTypesEnum);
        } else if (key == "lastMeasure" && this.filters.data.lastMeasure?.selected?.type) { // per lastMeasure invece controllo selected.type contiene value.id
          return !!this.filters.data.lastMeasure?.selected?.type.includes(value.id as LastMeasureFilterEnumTypes);
        } else if (key == "stiCategory" && this.filters.data.stiCategory.selected) { // stiCategory controlla se value.id è presente in un oggetto dell'array selected in stiCategory
          return !!this.filters.data.stiCategory.selected.find((item: ProposalFilterStiCategoryResponse) => item.stiCategory == value.id);
        }
        return false;
      },
      getComboSelectedAsArray: (key: string) => {
        if (key == "stiDetails") {
          return (this.filters.data.stiDetails.selected.stiTypes as StiTypesEnum[])?.map((item: StiTypesEnum) => {
            return {
              id: item as string,
              name: item
            };
          }) as { id: string, name: string }[];
        } else if (key == "lastMeasure") {
          return (this.filters.data.lastMeasure.selected.type as LastMeasureFilterEnumTypes[])?.map((item: LastMeasureFilterEnumTypes) => {
            return {
              id: item as string,
              name: this.translateService.instant("proposal.filter.lastMeasure." + item)
            };
          }) as { id: string, name: string }[];
        } else if (key == "stiCategory") {
          return this.filters.data.stiCategory.selected?.map((item: ProposalFilterStiCategoryResponse) => {
            return {
              id: item.stiCategory,
              name: item.stiCategory
            };
          }) as { id: string, name: string }[];
        }
        return [] as { id: string, name: string }[];
      },
      toggleComboFilter: (key: string, value: { id: string, name: string }) => {
        if (key == "stiDetails") {
          // se è già presente, la rimuovo, altrimenti la aggiungo
          if (this.filters.data.stiDetails.selected?.stiTypes && this.filters.data.stiDetails.selected.stiTypes.includes(value.id as StiTypesEnum)) {
            this.filters.data.stiDetails.selected.stiTypes.splice(this.filters.data.stiDetails.selected.stiTypes.indexOf(value.id as StiTypesEnum), 1);
          } else {
            // Se sto aggiungendo un nuovo filtro e ne ho già di selezionati
            if (this.filters.data.stiDetails.selected?.stiTypes) {
              // Se seleziono una chip di tipo percentuale e ho BASELINE_AMOUNT o MAX_THEORETICAL_AMOUNT, rimuovo entrambi e azzero minPerc e maxPerc 
              if ([StiTypesEnumTypes.BASELINE_PERCENTAGE, StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE].includes(value.id as StiTypesEnum)
                &&
                (this.filters.data.stiDetails.selected?.stiTypes?.includes(StiTypesEnumTypes.BASELINE_AMOUNT)
                  || this.filters.data.stiDetails.selected?.stiTypes?.includes(StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT))
              ) {
                this.filters.data.stiDetails.selected.stiTypes.splice(this.filters.data.stiDetails.selected.stiTypes.indexOf(StiTypesEnumTypes.BASELINE_PERCENTAGE), 1);
                this.filters.data.stiDetails.selected.stiTypes.splice(this.filters.data.stiDetails.selected.stiTypes.indexOf(StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE), 1);
                this.filters.data.stiDetails.selected.minPerc = this.filters.getComboValue("stiDetails", "minPerc") ?? undefined;
                this.filters.data.stiDetails.selected.maxPerc = this.filters.getComboValue("stiDetails", "maxPerc") ?? undefined;
              }
              // Se seleziono una chip di tipo amount e ho BASELINE_PERCENTAGE o MAX_THEORETICAL_PERCENTAGE, rimuovo entrambi e azzero minAmoun e maxAmount
              if ([StiTypesEnumTypes.BASELINE_AMOUNT, StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT].includes(value.id as StiTypesEnum)
                &&
                (this.filters.data.stiDetails.selected?.stiTypes?.includes(StiTypesEnumTypes.BASELINE_PERCENTAGE)
                  || this.filters.data.stiDetails.selected?.stiTypes?.includes(StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE))
              ) {
                this.filters.data.stiDetails.selected.stiTypes.splice(this.filters.data.stiDetails.selected.stiTypes.indexOf(StiTypesEnumTypes.BASELINE_AMOUNT), 1);
                this.filters.data.stiDetails.selected.stiTypes.splice(this.filters.data.stiDetails.selected.stiTypes.indexOf(StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT), 1);
                this.filters.data.stiDetails.selected.minAmount = this.filters.getComboValue("stiDetails", "minAmount") ?? undefined;
                this.filters.data.stiDetails.selected.maxAmount = this.filters.getComboValue("stiDetails", "maxAmount") ?? undefined;
              }
              this.filters.data.stiDetails.selected?.stiTypes.push(value.id as StiTypesEnum);
            } else {
              this.filters.data.stiDetails.selected = {
                stiTypes: [value.id as StiTypesEnum],
                minAmount: undefined, // this.filters.getComboValue("stiDetails", "minAmount") != null ? (this.filters.getComboSelected("stiDetails", "minAmount") ?? this.filters.getComboValue("stiDetails", "minAmount") ?? 1) : null,
                maxAmount: undefined, // this.filters.getComboValue("stiDetails", "maxAmount") != null ? (this.filters.getComboSelected("stiDetails", "maxAmount") ?? this.filters.getComboValue("stiDetails", "maxAmount")) : null,
                minPerc: undefined,   // this.filters.getComboValue("stiDetails", "minPerc") != null ? this.filters.getComboSelected("stiDetails", "minPerc") ?? (this.filters.getComboValue("stiDetails", "minPerc") ?? 0.1) : null,
                maxPerc: undefined   // this.filters.getComboValue("stiDetails", "maxPerc") != null ? this.filters.getComboSelected("stiDetails", "maxPerc") ?? (this.filters.getComboValue("stiDetails", "maxPerc") ?? 100) : null
              } as ProposalFilterStiDetailsResponse;
            }
          }
        } else if (key == "lastMeasure") {
          // se è già presente, la rimuovo, altrimenti la aggiungo
          if (this.filters.data.lastMeasure?.selected?.type && (this.filters.data.lastMeasure.selected.type).includes(value.id as LastMeasureFilterEnumTypes)) {
            this.filters.data.lastMeasure.selected.type.splice(this.filters.data.lastMeasure.selected.type.indexOf(value.id as LastMeasureFilterEnumTypes), 1);
          } else {
            if (this.filters.data.lastMeasure.selected?.type) {
              this.filters.data.lastMeasure.selected.type.push(value.id as LastMeasureFilterEnumTypes);
            } else {
              this.filters.data.lastMeasure.selected = {
                type: [value.id as LastMeasureFilterEnumTypes]
                // min: this.filters.getComboSelected("lastMeasure", "min") ?? this.filters.getComboValue("lastMeasure", "min"),
                // max: this.filters.getComboSelected("lastMeasure", "max") ?? this.filters.getComboValue("lastMeasure", "max")
              } as ProposalFilterLastMeasureResponse;
            }
          }
        } else if (key == "stiCategory") {
          // se è già presente, la rimuovo, altrimenti la aggiungo. In questo caso devo controllare se dentro all'oggetto nei selected, c'è un oggetto con stiCategory == value.id
          if ((this.filters.data.stiCategory.selected).find((item: ProposalFilterStiCategoryResponse) => item.stiCategory == value.id)) {
            // rimuovo l'oggetto nell'array con la stiCategory == value.id
            this.filters.data.stiCategory.selected = this.filters.data.stiCategory.selected.filter((item: ProposalFilterStiCategoryResponse) => {
              return item.stiCategory !== value.id;
            });
          } else {
            // aggiungo l'oggetto nell'array selected
            this.filters.data.stiCategory.selected.push({ stiCategory: value.id } as ProposalFilterStiCategoryResponse);
          }
        }
      },
      checkKeyForRange: (key: string, fieldName: string) => {
        return Object.prototype.hasOwnProperty.call(this.filters.data[key as keyof FilterData].values, fieldName) ?? false;
      },
      getComboValue: (key: string, fieldName: string) => {
        if (this.filters.checkKeyForRange(key, fieldName)) {
          return ((this.filters.data[key as keyof FilterData].values as ProposalFilterLastMeasureResponse | ProposalFilterStiDetailsResponse)[fieldName as keyof (ProposalFilterLastMeasureResponse | ProposalFilterStiDetailsResponse)]) as number;
        }
        return null;
      },
      getComboSelected: (key: string, fieldName: string) => {
        if (this.filters.checkKeyForRange(key, fieldName)) {
          return ((this.filters.data[key as keyof FilterData].values as ProposalFilterLastMeasureResponse | ProposalFilterStiDetailsResponse)[fieldName as keyof (ProposalFilterLastMeasureResponse | ProposalFilterStiDetailsResponse)]) as number;
        }
        return null;
      },
      updateComboRangeFilter: (key: string, fieldName: string, e: Event) => {
        const value = parseFloat((e?.target as HTMLInputElement)?.value);

        if (!isNaN(value)) {
          if (key == "stiDetails") {
            switch (fieldName) {
              case "minPerc":
                if (value >= (this.filters.data.stiDetails.values.minPerc ?? 0.1)) {
                  this.filters.data.stiDetails.selected.minPerc = value;
                } else {
                  this.filters.data.stiDetails.selected.minPerc = (this.filters.data.stiDetails.values.minPerc ?? 0.1);
                }
                if ((this.filters.data.stiDetails.selected.minAmount ?? 0.1) > (this.filters.data.stiDetails?.selected?.maxAmount ?? 100)) {
                  this.filters.data.stiDetails.selected.maxAmount = this.filters.data.stiDetails.selected.minAmount;
                }
                break;
              case "minAmount":
                if (value >= (this.filters.data.stiDetails.values.minAmount ?? 1)) {
                  this.filters.data.stiDetails.selected.minAmount = value;
                } else {
                  this.filters.data.stiDetails.selected.minAmount = (this.filters.data.stiDetails.values.minAmount ?? 1);
                }
                if ((this.filters.data.stiDetails.selected.minAmount ?? 1) > (this.filters.data.stiDetails?.selected?.maxAmount ?? 9999999)) {
                  this.filters.data.stiDetails.selected.maxAmount = this.filters.data.stiDetails.selected.minAmount;
                }
                break;
              case "maxAmount":
                if (value <= (this.filters.data.stiDetails.values.maxAmount ?? 99999999)) {
                  this.filters.data.stiDetails.selected.maxAmount = value;
                } else {
                  this.filters.data.stiDetails.selected.maxAmount = this.filters.data.stiDetails.values.maxAmount ?? 99999999;
                }
                if ((this.filters.data.stiDetails.selected.minAmount ?? 1) > (this.filters.data.stiDetails?.selected?.maxAmount ?? 9999999)) {
                  this.filters.data.stiDetails.selected.maxAmount = this.filters.data.stiDetails.selected.minAmount;
                }
                break;
              case "maxPerc":
                if (value <= (this.filters.data.stiDetails.values.maxPerc ?? 100)) {
                  this.filters.data.stiDetails.selected.maxPerc = value;
                } else {
                  this.filters.data.stiDetails.selected.maxPerc = this.filters.data.stiDetails.values.maxPerc ?? 100;
                }
                if ((this.filters.data.stiDetails.selected.minAmount ?? 0.1) > (this.filters.data.stiDetails?.selected?.maxAmount ?? 100)) {
                  this.filters.data.stiDetails.selected.maxAmount = this.filters.data.stiDetails.selected.minAmount;
                }
                break;
            }
            this.cdr.detectChanges();
          } else if (key == "lastMeasure") {
            switch (fieldName) {
              case "min":
                if (value >= this.filters.data.lastMeasure.values.min) {
                  this.filters.data.lastMeasure.selected.min = value;
                } else {
                  this.filters.data.lastMeasure.selected.min = (this.filters.data.lastMeasure.values.min ?? 1);
                }

                if (this.filters.data.lastMeasure.selected.min < this.filters.data.lastMeasure.values.min) {
                  this.filters.data.lastMeasure.selected.min = this.filters.data.lastMeasure.values.min ?? 1;
                }
                if (this.filters.data.lastMeasure.selected.min > this.filters.data.lastMeasure.selected.max) {
                  this.filters.data.lastMeasure.selected.min = this.filters.data.lastMeasure.selected.max;
                }
                break;
              case "max":
                if (value <= this.filters.data.lastMeasure.values.max) {
                  this.filters.data.lastMeasure.selected.max = value;
                } else {
                  this.filters.data.lastMeasure.selected.max = this.filters.data.lastMeasure.values.max ?? 9999999;
                }
                if (this.filters.data.lastMeasure.selected.min > this.filters.data.lastMeasure.selected.max) {
                  this.filters.data.lastMeasure.selected.max = this.filters.data.lastMeasure.selected.min;
                }
                if (this.filters.data.lastMeasure.selected.max > this.filters.data.lastMeasure.values.max) {
                  this.filters.data.lastMeasure.selected.max = this.filters.data.lastMeasure.values.max ?? 9999999;
                }
                break;
            }
            this.cdr.detectChanges();
          }
        }
        this.cdr.detectChanges();
      },
      toggleObjectFilter: (key: string, value: { id: string, name: string }) => {
        const ids: string[] = (this.filters.data[key as keyof FilterData]?.selected as ProposalFilterContractLevelResponse[] | ProposalFilterStructureResponse[]).map((item) => key === "contractLevelIds" ? (item as ProposalFilterContractLevelResponse).contractLevelId : (item as ProposalFilterStructureResponse).structureId);
        const newId = value.id; // key === "contractLevelIds" ? (value as ProposalFilterContractLevelResponse).contractLevelId : (value as ProposalFilterStructureResponse).structureId;

        if (ids.includes(newId)) {
          if (key === "contractLevelIds") {
            this.filters.data.contractLevelIds.selected = this.filters.data.contractLevelIds.selected.filter((item: ProposalFilterContractLevelResponse) => {
              return item.contractLevelId !== newId;
            });
          } else {
            this.filters.data.structureIds.selected = this.filters.data.structureIds.selected.filter((item: ProposalFilterStructureResponse) => {
              return item.structureId !== newId;
            });
          }
        } else {
          if (key === "contractLevelIds") {
            this.filters.data.contractLevelIds.selected.push({ contractLevelId: value.id, name: value.name } as ProposalFilterContractLevelResponse);
          } else {
            this.filters.data.structureIds.selected.push({ structureId: value.id, name: value.name } as ProposalFilterStructureResponse);
          }
        }
      },
      isObjectSelected: (key: string, value: { id: string, name: string }) => {
        if (key == "contractLevelIds") {
          return this.filters.data.contractLevelIds.selected.some(item => item.contractLevelId == value.id);
        } else if (key == "structureIds") {
          return this.filters.data.structureIds.selected.some(item => item.structureId == value.id);
        }
        return false;
      },
      getObjectValuesAsArray: (key: string) => {
        if (key == "contractLevelIds") {
          return this.filters.data.contractLevelIds.values.map((item: ProposalFilterContractLevelResponse) => {
            return {
              id: item.contractLevelId,
              name: item.name
            };
          });
        } else {
          return this.filters.data.structureIds.values.map((item: ProposalFilterStructureResponse) => {
            return {
              id: item.structureId,
              name: item.name
            };
          });
        }
      },
      getObjectAppliedAsArray: (key: string) => {
        if (key == "contractLevelIds") {
          return this.filters.data.contractLevelIds.selected.map(item => item.contractLevelId);
        } else if (key == "structureIds") {
          return this.filters.data.structureIds.selected.map(item => item.structureId);
        }
        return [];
      },
      resetFilter: () => {
        for (const key of this.filtersKeys) {
          this.filters.data[key as keyof FilterData].selected = [];
          this.filters.data[key as keyof FilterData].applied = [];
        }
      },
      hardReset: () => {
        this.filters.resetFilter();
        for (const key of this.filtersKeys) {
          this.filters.data[key as keyof FilterData].active = false;
          this.filters.data[key as keyof FilterData].loaded = false;
        }
      },
      getValuesAsArray: (key) => {
        const values = this.filters.data[key as keyof FilterData]?.values;
        return Array.isArray(values) ? values as string[] : [];
      },
      getSelectedAsArray: (key) => {
        const values = this.filters.data[key as keyof FilterData]?.selected;
        return Array.isArray(values) ? values as string[] : [];
      },
      getAppliedAsArray: (key) => {
        const values = this.filters.data[key as keyof FilterData]?.applied;
        return Array.isArray(values) ? values as string[] : [];
      },
      isSelected: (key: string, value: string) => {
        return this.filters.getSelectedAsArray(key)?.includes(value);
      },
      getValueMin: (key: string) => {
        if (Object.prototype.hasOwnProperty.call(this.filters.data[key as keyof FilterData].values, "min") && (this.filters.data[key as keyof FilterData].values as ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse).min != null) {
          return (this.filters.data[key as keyof FilterData].values as ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse).min;
        }
        if (key == "age") {
          return 18;
        } else if (key == "equity") {
          return -99;
        } else if (key == "groupSeniority") {
          return 1;
        } else {
          return 0;
        }
      },
      getValueMax: (key: string) => {
        if (Object.prototype.hasOwnProperty.call(this.filters.data[key as keyof FilterData].values, "max") && (this.filters.data[key as keyof FilterData].values as ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse).max != null) {
          return (this.filters.data[key as keyof FilterData].values as ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse).max;
        }
        if (key == "age") {
          return 80;
        } else if (key == "equity") {
          return 99;
        } else if (key == "groupSeniority") {
          return 50;
        } else {
          return 9999999;
        }
      },
      getSelectedMin: (key: string) => {
        if (Object.prototype.hasOwnProperty.call(this.filters.data[key as keyof FilterData].selected, "min")) {
          return (this.filters.data[key as keyof FilterData].selected as ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse).min;
        }
        return null;
      },
      getSelectedMax: (key: string) => {
        if (Object.prototype.hasOwnProperty.call(this.filters.data[key as keyof FilterData].selected, "max")) {
          return (this.filters.data[key as keyof FilterData].selected as ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse).max;
        }
        return null;
      },
      getAppliedMin: (key: string) => {
        if (Object.prototype.hasOwnProperty.call(this.filters.data[key as keyof FilterData].applied, "min")) {
          return (this.filters.data[key as keyof FilterData].applied as ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse).min;
        }
        return null;
      },
      getAppliedMax: (key: string) => {
        if (Object.prototype.hasOwnProperty.call(this.filters.data[key as keyof FilterData].applied, "max")) {
          return (this.filters.data[key as keyof FilterData].applied as ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse).max;
        }
        return null;
      },
      updateRangeFilter: (key: string, isMin: boolean, e: Event) => {
        const value = parseFloat((e?.target as HTMLInputElement)?.value);
        if (!isNaN(value)) {
          if (isMin) {
            if (value >= this.filters.getValueMin(key) && value <= this.filters.getValueMax(key)) {
              (this.filters.data[key as keyof FilterData].selected as NumberRangeFilter).min = value;
            } else {
              (this.filters.data[key as keyof FilterData].selected as NumberRangeFilter).min = this.filters.getValueMin(key);
            }
            if ((this.filters.getSelectedMin(key) ?? this.filters.getValueMin(key)) > (this.filters.getSelectedMax(key) ?? this.filters.getValueMax(key))) {
              (this.filters.data[key as keyof FilterData].selected as NumberRangeFilter).max = (this.filters.data[key as keyof FilterData].selected as NumberRangeFilter).min;
            }
          } else {
            if (value <= this.filters.getValueMax(key) && value >= this.filters.getValueMin(key)) {
              (this.filters.data[key as keyof FilterData].selected as NumberRangeFilter).max = value < this.filters.getValueMax(key) ? value : this.filters.getValueMax(key);
            } else {
              (this.filters.data[key as keyof FilterData].selected as NumberRangeFilter).max = this.filters.getValueMax(key);
            }
            if ((this.filters.getSelectedMax(key) ?? this.filters.getValueMax(key)) < (this.filters.getSelectedMin(key) ?? this.filters.getValueMin(key))) {
              (this.filters.data[key as keyof FilterData].selected as NumberRangeFilter).min = (this.filters.data[key as keyof FilterData].selected as NumberRangeFilter).max;
            }
          }
        }
      },
      checkComboAmountSelected: () => {
        if (this.filters.data.stiDetails.selected.stiTypes) {
          if (this.filters.data.stiDetails.selected.stiTypes.includes(StiTypesEnumTypes.BASELINE_AMOUNT) || this.filters.data.stiDetails.selected.stiTypes.includes(StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT)) {
            return true;
          }
        }
        return false;
      },
      checkComboPercSelected: () => {
        if (this.filters.data.stiDetails.selected.stiTypes) {
          if (this.filters.data.stiDetails.selected.stiTypes.includes(StiTypesEnumTypes.BASELINE_PERCENTAGE) || this.filters.data.stiDetails.selected.stiTypes.includes(StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE)) {
            return true;
          }
        }
        return false;
      },
      removeFilter: (id: string, key: string) => {
        // Id è il valore della chip, key la chiave del campo
        // se è un enum, rimuovo l'elemento
        if (this.enumFilterTypeKey.includes(key as keyof FilterData)) {
          this.filters.toggleStringFilter(key, id);
          this.filters.data[key as keyof FilterData].applied = this.filters.data[key as keyof FilterData].selected;
        }
        // se è un range, ripristino al valore originale
        if (this.rangeFilterTypeKey.includes(key as keyof FilterData)) {
          this.filters.data[key as keyof FilterData].selected = {} as NumberRangeFilter;
          this.filters.data[key as keyof FilterData].applied = {} as NumberRangeFilter;
        }
        // se è contractLevel o structure devo rimuovere confrontando l'id
        // Passo solo l'id, il nome non mi interessa
        if (key == "contractLevelIds") {
          this.filters.toggleObjectFilter(key, { id: id, name: "" });
        }
        if (key == "structureIds") {
          this.filters.toggleObjectFilter(key, { id: id, name: "" });
        }

        if (key == "stiDetails") {
          this.filters.data.stiDetails.selected.stiTypes = this.filters.data.stiDetails.selected?.stiTypes ? this.filters.data.stiDetails.selected.stiTypes.filter((item: string) => item != id) : [];
          this.filters.data.stiDetails.applied.stiTypes = this.filters.data.stiDetails.applied?.stiTypes ? this.filters.data.stiDetails.applied.stiTypes.filter((item: string) => item != id) : [];
          if (this.filters.data.stiDetails.selected.stiTypes.length == 0) {
            this.filters.data.stiDetails.selected = {
              stiTypes: [],
              minAmount: this.filters.getComboValue("stiDetails", "minAmount") ?? 1,
              maxAmount: this.filters.getComboValue("stiDetails", "maxAmount"),
              minPerc: this.filters.getComboValue("stiDetails", "minPerc") ?? 0.1,
              maxPerc: this.filters.getComboValue("stiDetails", "maxPerc") ?? 100
            } as ProposalFilterStiDetailsResponse;
          }
        }
        if (key == "lastMeasure") {
          this.filters.data.lastMeasure.selected.type = this.filters.data.lastMeasure.selected?.type ? this.filters.data.lastMeasure.selected.type.filter((item: string) => item != id) : [];
          this.filters.data.lastMeasure.applied.type = this.filters.data.lastMeasure.applied?.type ? this.filters.data.lastMeasure.applied.type.filter((item: string) => item != id) : [];
          if (this.filters.data.lastMeasure.selected.type.length == 0) {
            this.filters.data.lastMeasure.selected = {
              type: [],
              min: this.filters.getComboSelected("lastMeasure", "min") ?? this.filters.getComboValue("lastMeasure", "min"),
              max: this.filters.getComboSelected("lastMeasure", "max") ?? this.filters.getComboValue("lastMeasure", "max")
            } as ProposalFilterLastMeasureResponse;
          }
        }

        if (key == "stiCategory") {
          this.filters.toggleComboFilter(key, { id: id, name: "" });
        }

        this.filters.applyFilters();
      },
      getChips: () => {
        const allChips: { id: string, key: string, title: string }[] = [];

        for (const enumKey of this.enumFilterTypeKey) {
          for (const item of this.filters.getAppliedAsArray(enumKey)) {
            allChips.push({
              id: item,
              key: enumKey,
              title: this.translateService.instant("proposal.filter." + enumKey + "." + item)
            });
          }
        }

        for (const rangeKey of this.rangeFilterTypeKey) {
          // QUesta era in and per non passare filtri non modificati (this.filters.getValueMax(rangeKey) !== this.filters.getAppliedMax(rangeKey) || this.filters.getValueMin(rangeKey) !== this.filters.getAppliedMin(rangeKey))
          if (this.filters.getAppliedMax(rangeKey) ?? this.filters.getAppliedMin(rangeKey)) {
            const name: string = this.translateService.instant("proposal.filter." + rangeKey);
            const min: number | null = this.filters.getAppliedMin(rangeKey) ?? null;
            const max: number | null = this.filters.getAppliedMax(rangeKey) ?? null;

            let symbol: string = "";

            if (rangeKey == "equity") {
              symbol = "%";
            } else if (rangeKey == "annualSalary" || rangeKey == "lastMeasure") {
              symbol = "€";
            }
            if (min ?? max) {
              allChips.push({
                id: "",
                key: rangeKey,
                title: name + " - " + (min != null ? min + symbol : "") + (min != null && max != null ? " , " : "") + (max != null ? max + symbol : "")
              });
            }
          }
        }

        // Applica inquadramento / contractLevelIds
        if (this.filters.data.contractLevelIds.applied.length) {
          for (const item of this.filters.data.contractLevelIds.applied) {
            allChips.push({
              id: item.contractLevelId,
              key: "contractLevelIds",
              title: item.name
            });
          }
        }

        // Applica struttura / structureIds
        if (this.filters.data.structureIds.applied.length) {
          for (const item of this.filters.data.structureIds.applied) {
            if (item.structureId != "all") {
              allChips.push({
                id: item.structureId,
                key: "structureIds",
                title: item.name
              });
            }
          }
        }

        if (this.filters.data.stiCategory.applied.length) {
          for (const item of this.filters.data.stiCategory.applied) {
            allChips.push({
              id: item.stiCategory,
              key: "stiCategory",
              title: item.stiCategory
            });
          }
        }

        if (this.filters.data.lastMeasure.applied?.type?.length) {
          let range: string = "";
          if (this.filters.data.lastMeasure.applied.min && this.filters.data.lastMeasure.applied.max) {
            range += " - " + this.filters.data.lastMeasure.applied.min + "€, " + this.filters.data.lastMeasure.applied.max + "€";
          }


          for (const item of this.filters.data.lastMeasure.applied.type) {
            const title: string = this.translateService.instant("proposal.filter.lastMeasure." + item) ?? "";
            const name: string = title + range;
            allChips.push({
              id: item,
              key: "lastMeasure",
              title: name
            });
          }
        }

        if (this.filters.data.stiDetails.applied?.stiTypes?.length) {
          let rangePerc: string = " ";
          let rangeAmount: string = " ";
          if (this.filters.data.stiDetails.applied.minAmount ?? this.filters.data.stiDetails.applied.maxAmount) {
            if (this.filters.data.stiDetails.applied.minAmount != null) {
              rangeAmount += this.filters.data.stiDetails.applied.minAmount ? (this.filters.data.stiDetails.applied.minAmount + "€ ") : "";
            }
            if (this.filters.data.stiDetails.applied.minAmount != null && this.filters.data.stiDetails.applied.maxAmount != null) {
              rangeAmount += " , ";
            }
            if (this.filters.data.stiDetails.applied.maxAmount != null) {
              rangeAmount += this.filters.data.stiDetails.applied.maxAmount ? (this.filters.data.stiDetails.applied.maxAmount + "€") : "";
            }
          }

          if (this.filters.data.stiDetails.applied.minPerc ?? this.filters.data.stiDetails.applied.maxPerc) {
            if (this.filters.data.stiDetails.applied.minPerc != null) {
              rangePerc += this.filters.data.stiDetails.applied.minPerc ? (this.filters.data.stiDetails.applied.minPerc + "% ") : "";
            }
            if (this.filters.data.stiDetails.applied.minPerc != null && this.filters.data.stiDetails.applied.maxPerc != null) {
              rangePerc += " , ";
            }
            if (this.filters.data.stiDetails.applied.maxPerc != null) {
              rangePerc += this.filters.data.stiDetails.applied.maxPerc ? (this.filters.data.stiDetails.applied.maxPerc + "%") : "";
            }
          }

          if (this.filters.data.stiDetails?.applied?.stiTypes?.length) {
            for (const item of this.filters.data.stiDetails.applied.stiTypes) {
              const title: string = this.translateService.instant("proposal.filter.stiDetails." + item) ?? "";
              if ((item == StiTypesEnumTypes.BASELINE_PERCENTAGE || item == StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE)) {
                allChips.push({
                  id: item,
                  key: "stiDetails",
                  title: title + rangePerc
                });
              } else if ((item == StiTypesEnumTypes.BASELINE_AMOUNT || item == StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT)) {
                allChips.push({
                  id: item,
                  key: "stiDetails",
                  title: title + rangeAmount
                });
              }
            }
          }
        }

        return allChips;
      },
      applyFilters: () => {
        for (const key of Object.keys(this.filters.data)) {
          if (this.rangeFilterTypeKey.includes(key as keyof FilterData)) {
            this.filters.data[key as keyof FilterData].applied = Object.assign({}, this.filters.data[key as keyof FilterData].selected as (string[] | NumberRangeFilter | ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse | ProposalFilterContractLevelResponse[] | ProposalFilterStructureResponse[] | ProposalFilterStiCategoryResponse[] | ProposalFilterStiDetailsResponse));
          } else {
            this.filters.data[key as keyof FilterData].applied = JSON.parse(JSON.stringify(this.filters.data[key as keyof FilterData].selected)) as (string[] | NumberRangeFilter | ProposalFilterAgeResponse | ProposalFilterLastMeasureResponse | ProposalFilterAnnualSalaryResponse | ProposalFilterGroupSeniorityResponse | ProposalFilterEquityResponse | ProposalFilterContractLevelResponse[] | ProposalFilterStructureResponse[] | ProposalFilterStiCategoryResponse[] | ProposalFilterStiDetailsResponse);
          }
        }
      },
      getFiltersForService: (onlyStructureIds?: boolean) => {
        const tmpFilter = {} as ProposalsFilters;

        if (onlyStructureIds) {
          if (this.filters.getObjectAppliedAsArray("structureIds").length > 0) {
            tmpFilter.structureIds = this.filters.getObjectAppliedAsArray("structureIds").filter((key: string) => key != "all");
          }
          // Filtro per gerarchia
          if (this.treeFormattedData && this.getSelectedNodeIds(this.treeFormattedData).length > 0) {
            if (tmpFilter.structureIds) {
              tmpFilter.structureIds.concat(this.getSelectedNodeIds(this.treeFormattedData));
            } else {
              tmpFilter.structureIds = this.getSelectedNodeIds(this.treeFormattedData);
            }
          }
        } else {
          if (this.filters.data.proposalTypes.applied) {
            tmpFilter.proposalTypes = this.filters.data.proposalTypes.applied as ProposalFilterProposalTypeResponse[];
          }
          if (this.filters.data.proposalStatuses.applied) {
            tmpFilter.proposalStatuses = this.filters.data.proposalStatuses.applied as ProposalFilterProposalStatusResponse[];
          }
          if (this.filters.data.warnings.applied) {
            tmpFilter.warnings = this.filters.data.warnings.applied as ProposalFilterWarningResponse[];
          }
          if (this.filters.data.age.applied) {
            tmpFilter.age = this.filters.data.age.applied;
          }
          if (this.filters.data.groupSeniority.applied) {
            tmpFilter.groupSeniority = this.filters.data.groupSeniority.applied;
          }
          if (this.filters.data.genderTypes.applied) {
            tmpFilter.genderTypes = this.filters.data.genderTypes.applied as GenderTypesEnum[];
          }
          if (this.filters.data.employeeTypes.applied) {
            tmpFilter.employeeTypes = this.filters.data.employeeTypes.applied as UserCategoriesEnum[];
          }
          if (this.filters.getObjectAppliedAsArray("contractLevelIds").length > 0) {
            tmpFilter.contractLevelIds = this.filters.getObjectAppliedAsArray("contractLevelIds");
          }

          if (this.filters.data.equity.applied) {
            tmpFilter.equity = this.filters.data.equity.applied;
          }
          if (this.filters.data.annualSalary.applied) {
            tmpFilter.annualSalary = this.filters.data.annualSalary.applied;
          }

          if (this.filters.data.stiCategory.applied?.length) {
            tmpFilter.stiCategory = this.filters.data.stiCategory.applied.map((item: ProposalFilterStiCategoryResponse) => item.stiCategory);
          }

          if (this.filters.data.stiDetails.applied?.stiTypes?.length) {
            if (this.filters.data.stiDetails.applied.stiTypes.includes(StiTypesEnumTypes.BASELINE_PERCENTAGE) || this.filters.data.stiDetails.applied.stiTypes.includes(StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE)) {
              tmpFilter.stiDetails = {
                types: this.filters.data.stiDetails.applied.stiTypes,
                min: this.filters.data.stiDetails.applied.minPerc,
                max: this.filters.data.stiDetails.applied.maxPerc
              };
            }
            if (this.filters.data.stiDetails.applied.stiTypes.includes(StiTypesEnumTypes.BASELINE_AMOUNT) || this.filters.data.stiDetails.applied.stiTypes.includes(StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT)) {
              tmpFilter.stiDetails = {
                types: this.filters.data.stiDetails.applied.stiTypes,
                min: this.filters.data.stiDetails.applied.minAmount,
                max: this.filters.data.stiDetails.applied.maxAmount
              };
            }
          }


          if (this.filters.data.lastMeasure.applied?.type?.length) {
            tmpFilter.lastMeasure = this.filters.data.lastMeasure.applied;
          }

          // Filtro per unità organizzativa 
          if (this.filters.getObjectAppliedAsArray("structureIds").length > 0) {
            tmpFilter.structureIds = this.filters.getObjectAppliedAsArray("structureIds").filter((key: string) => key != "all");
          }
          // Filtro per gerarchia
          if (this.treeFormattedData && this.getSelectedNodeIds(this.treeFormattedData).length > 0) {
            if (tmpFilter.structureIds) {
              tmpFilter.structureIds.concat(this.getSelectedNodeIds(this.treeFormattedData));
            } else {
              tmpFilter.structureIds = this.getSelectedNodeIds(this.treeFormattedData);
            }
          }
          if (this.filters.data.performanceOverallEvaluations.applied) {
            tmpFilter.performanceOverallEvaluations = this.filters.data.performanceOverallEvaluations.applied;
          }
        }
        return tmpFilter;
      }
    };
    this.filtersKeys = Object.keys(this.filters.data) as (keyof FilterData)[];
  }

  override async ngOnInit(): Promise<void> {
    this.appService.selectableTypes = await this.backendService.getProcessSelectableTypes();
    // Imposto la breadcrumb
    this.setBreadcrumb();

    this.proposalTabs = [
      {
        id: "current-proposal",
        label: this.translateService.instant("proposal.CURRENT_PROPOSAL")
      },
      {
        id: "person-details",
        label: this.translateService.instant("proposal.PERSON_DETAILS")
      },
      {
        id: "log-proposal",
        label: this.translateService.instant("proposal.LOG_PROPOSAL")
      }
    ];

    this.activeTabProposalId = this.proposalTabs[0]?.id;
    this.activeTabProposal = this.proposalTabs[0];

    const evaluationString = this.translateService.instant("proposal.EVALUATION");
    this.evaluationsHistory = [
      {
        year: this.currentYear - 1,
        yearLabel: evaluationString as string + " " + (this.currentYear - 1).toString(),
        evaluation: undefined
      },
      {
        year: this.currentYear - 2,
        yearLabel: evaluationString as string + " " + (this.currentYear - 2).toString(),
        evaluation: undefined
      },
      {
        year: this.currentYear - 3,
        yearLabel: evaluationString as string + " " + (this.currentYear - 3).toString(),
        evaluation: undefined
      }
    ];

    const interventionString = this.translateService.instant("proposal.INTERVENTION");

    this.interventionsHistory = [
      {
        year: (this.currentYear - 1).toString() + " - " + (this.currentYear).toString(),
        yearLabel: interventionString as string + " " + (this.currentYear - 1).toString() + " - " + (this.currentYear).toString(),
        interventionsLabels: []
      },
      {
        year: (this.currentYear - 2).toString(),
        yearLabel: interventionString as string + " " + (this.currentYear - 2).toString(),
        interventionsLabels: []
      },
      {
        year: (this.currentYear - 3).toString(),
        yearLabel: interventionString as string + " " + (this.currentYear - 3).toString(),
        interventionsLabels: []
      }
    ];

    if (this.processId && this.structureId) {
      await this.getProposalPhaseInfo();
    }
  }

  async getProposalPhaseInfo(skipTabInit?: boolean) {
    this.isLoadingPhaseInfo = true;

    this.phaseInfo = await this.profileBasedAPIService.getProposalPhaseInfo(this.processId, this.structureId);

    if (!skipTabInit) {
      this.tabList = [];
      if (this.phaseInfo) {
        // creo i TabItem delle dashboard disponibili
        for (const teamType of this.phaseInfo.availableTeamTypes) {
          this.tabList.push({
            id: teamType,
            label: this.translateService.instant("proposal.teamType." + teamType),
            icon: "assets/icons/" + teamType.toLocaleLowerCase() + "-team.svg"
          });
        }
      }

      // Inizializzo la tab nel caso non ne avessi una selezionata
      if (!this.activeTab?.id && this.tabList?.length) {
        this.activeTab = this.tabList[0];
      }
    }

    if (this.activeTab?.id && this.tabList?.length) {
      await this.getFilterKeys();
      await this.getDashboardSummaryData();
      await this.getTableData();
    }
    this.isLoadingPhaseInfo = false;
  }

  async loadTeamData(skipDahsboard?: boolean, resetFilters?: boolean) {
    if (resetFilters) {
      this.filters.hardReset();
    }
    await this.getFilterKeys();

    if (!skipDahsboard) {
      await this.getProposalPhaseInfo(true);
      await this.getDashboardSummaryData();
    } else {
      await this.getTableData();
    }
  }

  async changeTeamTab(tab: MenuItem) {
    this.isLoadingTableData = true;
    this.activeTab = tab;
    await this.loadTeamData(false, true);
  }

  async getDashboardSummaryData() {
    this.isLoadingDashboardSummary = true;

    const filters = this.filters.getFiltersForService(true) || [];

    this.dashboardSummary = await this.profileBasedAPIService.getProposalDashboardSummary(
      this.processId,
      this.structureId,
      this.activeTab.id ?? "",
      filters
    );
    // Riordino i contatori di genere
    if (this.dashboardSummary?.genderSummaries?.length) {
      this.dashboardSummary.genderSummaries = this.dashboardSummary?.genderSummaries.reverse();
    }

    this.isLoadingDashboardSummary = false;
  }

  // Recupero dati dashboard estesa
  async getDashboardExtendedData() {
    this.isLoadingDashboardExtendedData = true;

    const filters = this.filters.getFiltersForService(true) || [];

    if (this.phaseInfo?.availableDashboardTypes.includes("BUDGET")) {
      const tempBudgetData = await this.profileBasedAPIService.getProposalDashboardExtendedBudget(this.processId, this.structureId, this.activeTab.id ?? "", filters);
      this.budgetExtendedData = tempBudgetData?.budgetTypesWithDetails ?? null;

    } else {
      this.budgetExtendedData = null;
    }
    if (this.phaseInfo?.availableDashboardTypes.includes("GENDER")) {
      this.genderExtendedData = await this.profileBasedAPIService.getProposalDashboardExtendedGender(this.processId, this.structureId, this.activeTab.id ?? "", filters);
    } else {
      this.genderExtendedData = null;
    }
    this.isLoadingDashboardExtendedData = false;
  }

  async changeTableOrder(data: {
    id: string | undefined;
    sortDirection: SortDirectionType;
  }) {
    if (data?.id) {
      this.sortData = {
        sortBy: data.id,
        sortType: data.sortDirection
      };
      await this.loadTeamData(true);
    }

  }

  async getTableData(skipCount?: boolean) {
    this.isLoadingTableData = true;
    const filters = this.filters.getFiltersForService();
    if (!skipCount) {
      this.userCount = await this.profileBasedAPIService.countProposalTableData(this.processId, this.structureId, this.activeTab.id ?? "", filters);
    }

    this.userList = [];

    if (this.userCount) {
      this.userList = await this.profileBasedAPIService.listProposalTableData(this.processId, this.structureId, this.activeTab.id ?? "", this.fromRecord, this.numRecords, filters, this.sortData.sortBy, this.sortData.sortType);
    }

    this.tableHeaderList = [];
    tableHeaderIds.forEach((id: string) => {
      let tmpText: string | null = null;

      if (id == "LAST_PERFORMANCE_OVERALL") {
        const text: string = this.translateService.instant("proposal.table.header.LAST_PERFORMANCE_OVERALL");
        tmpText = text + (this.phaseInfo?.performanceOverallYear ? this.phaseInfo.performanceOverallYear.toString() : "");
      }

      if (id == "LAST_MEASURE") {
        const tmp = this.translateService.instant("proposal.table.header.LAST_MEASURE");
        tmpText = tmp as string + (this.currentYear - 1).toString() + "-" + this.currentYear.toString();
      }

      this.tableHeaderList.push({
        titleId: id as (ProposalsSortEnum | "STI"),
        text: tmpText,
        sortDirection: "ASC" // SortDirectionType
      });
    });

    this.isLoadingTableData = false;
  }


  async pageChanged(newPage: number) {
    this.currentPage = newPage;
    this.fromRecord = (newPage - 1) * this.numRecords;
    await this.getTableData();
  }


  setBreadcrumb() {
    this.breadcrumbService.breadcrumbs = [
      { name: "Home", url: "/homepage" },
      {
        name: this.translateService.instant("homepage.SALARY_REVIEW_" + this.processType),
        url: "/" + this.selectedProfile.toLowerCase() + "/homepage-actions/" + this.processType
      }
    ];
  }


  protected async openProposalSidebar(user: ProposalUserDetails | null, notOpen?: boolean) {
    this.selectedUser = user;

    if (!notOpen) {
      this.activeTabProposalId = "current-proposal";
      this.activeTabProposal = this.proposalTabs.find(item => item.id == this.activeTabProposalId);
      this.offcanvasService.open(this.contentProposalSidebar, { backdrop: "static", position: "end", ariaLabelledBy: "content-sidebar-title-text", panelClass: "proposal-offcanvas" });
    }

    this.extraBudgetTypeOptions = [
      {
        id: ExtraBudgetProposalReasonTypesEnumTypes.CONTRACT_AUTOMATION,
        label: this.translateService.instant("proposal.options.CONTRACT_AUTOMATION")
      },
      {
        id: ExtraBudgetProposalReasonTypesEnumTypes.WOMAN_VI_LEVEL_ADVANCEMENT,
        label: this.translateService.instant("proposal.options.WOMAN_VI_LEVEL_ADVANCEMENT")
      },
      {
        id: ExtraBudgetProposalReasonTypesEnumTypes.OTHER,
        label: this.translateService.instant("proposal.options.OTHER")
      }
    ];

    this.stiTypeOptions = [
      {
        id: StiTypesEnumTypes.BASELINE_AMOUNT,
        label: this.translateService.instant("proposal.options.BASELINE_AMOUNT")
      },
      {
        id: StiTypesEnumTypes.BASELINE_PERCENTAGE,
        label: this.translateService.instant("proposal.options.BASELINE_PERCENTAGE")
      },
      {
        id: StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT,
        label: this.translateService.instant("proposal.options.MAX_THEORETICAL_AMOUNT")
      },
      {
        id: StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE,
        label: this.translateService.instant("proposal.options.MAX_THEORETICAL_PERCENTAGE")
      }
    ];

    await this.getUserProposal();
  }

  closeProposalSidebar(confirm?: boolean) {
    this.resetProposalSidenavData();
    this.offcanvasService.dismiss(this.contentProposalSidebar);

    if (confirm) {
      return;
    }
  }

  protected openFiltersSidebar() {
    this.offcanvasService.open(this.filtersProposalSidebar, { position: "end", ariaLabelledBy: "content-sidebar-filter-title-text", panelClass: "filter-offcanvas" });
    for (const key in this.filters.data) {
      if (this.filters.data[key as FilterDataKeys]) {
        this.filters.data[key as FilterDataKeys].isOpen = false;
      }
    }
  }

  async closeFiltersSidebar(confirm?: boolean) {
    this.offcanvasService.dismiss(this.filtersProposalSidebar);
    if (confirm) {
      this.filters.applyFilters();
    }
    await this.loadTeamData(true);
  }

  // Cambio tab nella sidenavv
  protected async changeProposalTab(tab: MenuItem) {
    this.resetProposalSidenavData();

    this.activeTabProposalId = tab?.id ?? "";
    this.activeTabProposal = tab;
    if (this.activeTabProposalId == "current-proposal") {
      await this.openProposalSidebar(this.selectedUser, true);
    } else if (this.activeTabProposalId == "person-details") {
      await this.getProposalUserDetails(this.selectedUser);
    } else if (this.activeTabProposalId == "log-proposal") {
      await this.getTimelineDetails(this.selectedUser);
    }
  }

  protected async getUserProposal() {
    this.isLoadingProposal = true;
    this.proposalAvailableOptions = [];
    this.defaultOptionSelected = {
      id: "NO_INTERVENTION",
      label: this.translateService.instant("proposal.options.NO_INTERVENTION")
    };
    const options = await this.profileBasedAPIService.getProposalAvailableOptions(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser?.userId ?? "");
    if (options?.length) {
      for (const el of options) {
        this.proposalAvailableOptions.push({
          id: el,
          label: this.translateService.instant("proposal.options." + el)
        });
      }
    }

    const evaluationString: string = this.translateService.instant("proposal.EVALUATION");
    this.evaluationsHistory = [
      {
        year: this.currentYear - 1,
        yearLabel: evaluationString + " " + (this.currentYear - 1).toString(),
        evaluation: undefined
      },
      {
        year: this.currentYear - 2,
        yearLabel: evaluationString + " " + (this.currentYear - 2).toString(),
        evaluation: undefined
      },
      {
        year: this.currentYear - 3,
        yearLabel: evaluationString + " " + (this.currentYear - 3).toString(),
        evaluation: undefined
      }
    ];

    const interventionString: string = this.translateService.instant("proposal.INTERVENTION");

    this.interventionsHistory = [
      {
        year: (this.currentYear - 1).toString() + " - " + (this.currentYear).toString(),
        yearLabel: interventionString + " " + (this.currentYear - 1).toString() + " - " + (this.currentYear).toString(),
        interventionsLabels: []
      },
      {
        year: (this.currentYear - 2).toString(),
        yearLabel: interventionString + " " + (this.currentYear - 2).toString(),
        interventionsLabels: []
      },
      {
        year: (this.currentYear - 3).toString(),
        yearLabel: interventionString + " " + (this.currentYear - 3).toString(),
        interventionsLabels: []
      }
    ];

    this.userProposalDetails = await this.profileBasedAPIService.getUserProposalDetails(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser?.userId ?? "", this.structureId);

    // SE è presente gia una proposta e non è stata eliminata devo prevalorizzare select principale e tutti i vari altri dati del form
    if (this.userProposalDetails?.currentProposal?.proposalType && this.userProposalDetails?.currentProposal?.statusType != ProposalStatusTypesEnumTypes.DELETED) {
      this.selectedProposalOptionType = this.proposalAvailableOptions.find(x => x.id == this.userProposalDetails?.currentProposal?.proposalType) ?? this.defaultOptionSelected;
      await this.onSelectedProposalOptionChanged(this.selectedProposalOptionType, true);
    } else {
      this.isLoadingProposal = false;
    }

    if (this.userProposalDetails?.measuresHistory?.length) {
      for (const element of this.userProposalDetails.measuresHistory) {
        const label = this.translateService.instant<string>("proposal.interventionType.sigle." + element.type);
        if (element.year == this.currentYear - 1 || element.year == this.currentYear) {
          this.interventionsHistory[0].interventionsLabels.push({
            label: label,
            amount: element.amount,
            type: element.type
          });
        } else if (element.year == this.currentYear - 2) {
          this.interventionsHistory[1].interventionsLabels.push({
            label: label,
            amount: element.amount,
            type: element.type
          });
        } else if (element.year == this.currentYear - 3) {
          this.interventionsHistory[2].interventionsLabels.push({
            label: label,
            amount: element.amount,
            type: element.type
          });
        }
      }
    }

    if (this.userProposalDetails?.performanceOverallEvaluationsHistory?.length) {
      for (const element of this.userProposalDetails.performanceOverallEvaluationsHistory) {
        if (element.year == this.currentYear - 1) {
          this.evaluationsHistory[0].evaluation = element.evaluation;
        } else if (element.year == this.currentYear - 2) {
          this.evaluationsHistory[1].evaluation = element.evaluation;
        } else if (element.year == this.currentYear - 3) {
          this.evaluationsHistory[2].evaluation = element.evaluation;
        }
      }
    }
  }

  // Recupero costanti dei campi in base alla scelta del tipo di economic measure selezionata nella select
  getFieldTypeForValidateForm(economicMeasureType: ProposalEconomicMeasureTypesEnum) {
    if (economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM
      || economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE
    ) {
      return ProposalFieldTypesEnumTypes.AMOUNT;
    } else if (economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT) {
      return ProposalFieldTypesEnumTypes.ABSORBED_AMOUNT;
    } else if (economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE) {
      if (this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.BASELINE_AMOUNT
        || this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT) {
        return ProposalFieldTypesEnumTypes.AMOUNT;
      } else {
        return ProposalFieldTypesEnumTypes.PERCENTAGE;
      }
    } else {
      return ProposalFieldTypesEnumTypes.AMOUNT;
    }
  }

  // Recupero valori input all interno della mappa proposalFieldMap in base ai vari economic measure types
  getFieldValueForValidateForm(economicMeasureType: ProposalEconomicMeasureTypesEnum) {
    if (economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM
      || economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE
    ) {
      if (typeof this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.AMOUNT] === "string") {
        const value = this.proposalFieldMap.get(economicMeasureType);
        let valueToUse = "0";
        if (value) {
          valueToUse = (value[ProposalFieldTypesEnumTypes.AMOUNT] as string).split(".").join("");
          valueToUse = valueToUse.split(",").join("");
        }
        return parseInt(valueToUse);
      } else {
        return this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.AMOUNT] as number;
      }
    } else if (economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT) {
      if (typeof this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.ABSORBED_AMOUNT] === "string") {
        const value = this.proposalFieldMap.get(economicMeasureType);
        let valueToUse = "0";
        if (value) {
          valueToUse = (value[ProposalFieldTypesEnumTypes.ABSORBED_AMOUNT] as string).split(".").join("");
          valueToUse = valueToUse.split(",").join("");
        }
        return parseInt(valueToUse);
      } else {
        return this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.ABSORBED_AMOUNT] as number;
      }
    } else if (economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE) {
      if (this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.BASELINE_AMOUNT
        || this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT) {
        if (typeof this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.AMOUNT] === "string") {
          const value = this.proposalFieldMap.get(economicMeasureType);
          let valueToUse = "0";
          if (value) {
            valueToUse = (value[ProposalFieldTypesEnumTypes.AMOUNT] as string).split(".").join("");
            valueToUse = valueToUse.split(",").join("");
          }
          return parseInt(valueToUse);
        } else {
          return this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.AMOUNT] as number;
        }
      } else {
        if (typeof this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.PERCENTAGE] === "string") {
          return parseInt((this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.PERCENTAGE]) as string);
        } else {
          return this.proposalFieldMap.get(economicMeasureType)?.[ProposalFieldTypesEnumTypes.PERCENTAGE] as number;
        }
      }
    } else {
      return;
    }
  }

  protected async onSelectedProposalOptionChanged(proposalTypeSelected: ProposalOption, withExistentProposal?: boolean) {
    this.isLoadingProposal = true;
    this.passedOnBlurInput = false;
    // Resetto dati
    this.resetProposalSidenavData();

    // withExistentProposal = E' presente una proposta esistente ed è la prima volta che apro la sidenav o clicco la prima tab
    this.firstTimeOpenedSidebarWithExistentPropose = withExistentProposal ? true : false;

    this.selectedProposalOptionType = proposalTypeSelected;

    if (!this.selectedUser) {
      return;
    }

    // Recupero campi form costanti
    this.proposalFormInfo = await this.profileBasedAPIService.getAvailableFields(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser.userId, this.selectedProposalOptionType?.id as ProposalTypesEnum);

    // Ho scelto promozione dirigente o funzionario
    const isPromotionOptionSelected = this.selectedProposalOptionType?.id === ProposalTypesEnumTypes.EXECUTIVE_PROMOTION || this.selectedProposalOptionType?.id === ProposalTypesEnumTypes.OFFICER_PROMOTION;

    if (this.proposalFormInfo?.length) {
      for (const element of this.proposalFormInfo) {
        // Pulisco mappe
        this.proposalFieldMap.set(element.economicMeasureType, {});
        this.previewFormMap.set(element.economicMeasureType, {});
        this.validateFormMap.set(element.economicMeasureType, {});
      }
    }

    // Se ho scelto promozione officer o executive non chiamo nulla, altrimenti chiamo i dettagli
    if (!isPromotionOptionSelected) {
      // Se ho gia una proposta esistente il type/details deve rispondere in base a quella
      await this.getTypeDetails(withExistentProposal ? this.userProposalDetails?.currentProposal?.proposalEconomicsDetails as ProposalEconomicsDetails[] : []);
      if (this.proposalFormInfo?.length) {

        // Chiamo il preview se ho una proposta esistente o ho scelto nella select passaggio livello/PL piu una tantum
        if (withExistentProposal ? true : (this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.LEVEL_ADVANCEMENT
          || this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.LEVEL_ADVANCEMENT_AND_UNA_TANTUM)
        ) {
          const allPreviewInfo: { economicMeasureType: ProposalEconomicMeasureTypesEnum, value: MapById<string | number> }[] = [];

          const proposalEconomicsDetailsToPass = [];
          for (const element of this.proposalFormInfo) {
            proposalEconomicsDetailsToPass.push(
              {
                proposalEconomicMeasureType: element.economicMeasureType,
                amount: element.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM
                  || element.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE
                  || (element.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE
                    && this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.BASELINE_AMOUNT
                    || this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT)
                  ? (this.getFieldValueForValidateForm(element.economicMeasureType) ?? 0) : 0,
                absorbedAmount: element.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT
                  ? (this.getFieldValueForValidateForm(element.economicMeasureType) ?? 0) : 0,
                processContractLevelId: ((this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(element.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelId) ?? "",
                processContractLevelName: ((this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(element.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelId) ?? "",
                extraBudgetProposalReasonType: this.selectedOptionExtraBudgetType && this.selectedOptionExtraBudgetType.get(element.economicMeasureType)?.id as ExtraBudgetProposalReasonTypesEnum || null,
                extraBudgetProposalNote: this.proposalFieldMap && this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] as string,
                stiType: this.selectedOptionStiType && this.selectedOptionStiType.get(element.economicMeasureType)?.id as StiTypesEnum,
                percentage: element.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE
                  && (this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.BASELINE_PERCENTAGE
                    || this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE)
                  ? (this.getFieldValueForValidateForm(element.economicMeasureType) ?? 0) : 0
              }
            );
          }

          for (const element of this.proposalFormInfo) {
            const info = await this.getPreviewDetail(element.economicMeasureType, withExistentProposal ? this.userProposalDetails?.currentProposal?.proposalEconomicsDetails as ProposalEconomicsDetails[] : proposalEconomicsDetailsToPass);
            if (info) {
              allPreviewInfo.push({ economicMeasureType: element.economicMeasureType, value: info as unknown as MapById<string | number> });
            }
          }

          this.previewFormMap = new Map(allPreviewInfo.map((obj) => [obj.economicMeasureType, obj.value]));
        }
        this.proposalNote = withExistentProposal ? (this.userProposalDetails?.currentProposal?.proposalNote ?? "") : "";

        // Se è presente una proposta esistente devo riempire le mappe e le variabili con i valori corrispondenti
        if (withExistentProposal) {
          for (const element of this.userProposalDetails?.currentProposal?.proposalEconomicsDetails as ProposalEconomicsDetails[]) {
            if (element.proposalEconomicMeasureType == ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM) {
              this.proposalFieldMap.set(ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM, {});
              const el = this.proposalFieldMap.get(ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM);
              if (el) {
                el[ProposalFieldTypesEnumTypes.AMOUNT] = this.numberWithCommas(element.amount);
                el[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] = element.extraBudgetProposalNote ?? "";
                if (element.warningTypes?.length) {
                  this.warningTypesMap.set(ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM, element.warningTypes);
                }
                const extraBudgetOptionSelected: ProposalOption = this.extraBudgetTypeOptions.find(x => x.id == element.extraBudgetProposalReasonType) as ProposalOption;
                this.onSelectedExtraBudgetTypeOptionChanged(extraBudgetOptionSelected, ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM);
              }
            }

            if (element.proposalEconomicMeasureType == ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE) {
              this.proposalFieldMap.set(ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE, {});
              const el = this.proposalFieldMap.get(ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE);
              if (el) {
                el[ProposalFieldTypesEnumTypes.AMOUNT] = this.numberWithCommas(element.amount);
                el[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] = element.extraBudgetProposalNote ?? "";
                if (element.warningTypes?.length) {
                  this.warningTypesMap.set(ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE, element.warningTypes);
                }
                const extraBudgetOptionSelected: ProposalOption = this.extraBudgetTypeOptions.find(x => x.id == element.extraBudgetProposalReasonType) as ProposalOption;
                this.onSelectedExtraBudgetTypeOptionChanged(extraBudgetOptionSelected, ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE);
              }
            }

            if (element.proposalEconomicMeasureType == ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT) {
              this.proposalFieldMap.set(ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT, {});
              const el = this.proposalFieldMap.get(ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT);
              if (el) {
                el[ProposalFieldTypesEnumTypes.ABSORBED_AMOUNT] = this.numberWithCommas(element.absorbedAmount ?? 0) ?? "";
                el[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] = element.extraBudgetProposalNote ?? "";
                const extraBudgetOptionSelected: ProposalOption = this.extraBudgetTypeOptions.find(x => x.id == element.extraBudgetProposalReasonType) as ProposalOption;
                this.onSelectedExtraBudgetTypeOptionChanged(extraBudgetOptionSelected, ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT);
              }
            }

            if (element.proposalEconomicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE) {
              this.proposalFieldMap.set(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE, {});
              const el = this.proposalFieldMap.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE);
              if (el) {
                el[ProposalFieldTypesEnumTypes.AMOUNT] = this.numberWithCommas(element.amount) ?? 0;
                el[ProposalFieldTypesEnumTypes.PERCENTAGE] = element.percentage ? (element.percentage + "%") : 0;
                el[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] = element.extraBudgetProposalNote ?? "";

                const selectedStiTypeOption = this.stiTypeOptions.find(x => x.id == element.stiType) as ProposalOption;
                this.onSelectedStiTypeOptionChanged(selectedStiTypeOption, ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE, true);
                const extraBudgetOptionSelected: ProposalOption = this.extraBudgetTypeOptions.find(x => x.id == element.extraBudgetProposalReasonType) as ProposalOption;
                this.onSelectedExtraBudgetTypeOptionChanged(extraBudgetOptionSelected, ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE);
              }
            }
          }
        }

        if (this.proposalFormInfo?.length) {
          const validateFormsToSend: ProposalFieldValidationRequest[] = [];
          for (const element of this.proposalFormInfo) {
            validateFormsToSend.push(
              {
                proposalEconomicMeasureType: element.economicMeasureType,
                fieldType: this.getFieldTypeForValidateForm(element.economicMeasureType),
                fieldValue: this.getFieldValueForValidateForm(element.economicMeasureType) ?? 0,
                proposalEconomicsDetails: []
              }
            );

          }

          if (validateFormsToSend?.length) {
            for (const el of this.proposalFormInfo) {
              for (const [i, element] of validateFormsToSend.entries()) {
                element;
                validateFormsToSend[i].proposalEconomicsDetails?.push(
                  {
                    proposalEconomicMeasureType: el.economicMeasureType,
                    amount: el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM
                      || el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE
                      || (el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE
                        && this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.BASELINE_AMOUNT
                        || this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT)
                      ? (this.getFieldValueForValidateForm(el.economicMeasureType) ?? 0) : 0,
                    absorbedAmount: el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT
                      ? (this.getFieldValueForValidateForm(el.economicMeasureType) ?? 0) : 0,
                    processContractLevelId: (this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(el.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelId ?? "",
                    processContractLevelName: (this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(el.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelId ?? "",
                    extraBudgetProposalReasonType: this.selectedOptionExtraBudgetType && this.selectedOptionExtraBudgetType.get(el.economicMeasureType)?.id as ExtraBudgetProposalReasonTypesEnum || null,
                    extraBudgetProposalNote: this.proposalFieldMap && this.proposalFieldMap.get(el.economicMeasureType)?.[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] as string,
                    stiType: this.selectedOptionStiType && this.selectedOptionStiType.get(el.economicMeasureType)?.id as StiTypesEnum,
                    percentage: el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE
                      && this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.BASELINE_PERCENTAGE
                      || this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE
                      ? (this.getFieldValueForValidateForm(el.economicMeasureType) ?? 0) : 0
                  }
                );
              }
            }
          }

          // Chiamo il validate
          const allValidateInfo: { economicMeasureType: ProposalEconomicMeasureTypesEnum, value: MapById<string | boolean | string[]> }[] = [];
          for (const element of validateFormsToSend) {
            const validate = await this.profileBasedAPIService.getValidateInfo(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser.userId, this.selectedProposalOptionType?.id, element, this.structureId);
            if (validate) {
              allValidateInfo.push({ economicMeasureType: element.proposalEconomicMeasureType, value: validate as unknown as MapById<string | boolean | string[]> });
            }
          }
          this.validateFormMap = new Map(allValidateInfo.map((obj) => [obj.economicMeasureType, obj.value]));

          for (const element of validateFormsToSend) {
            this.warningTypesMap.set(element.proposalEconomicMeasureType, []);
            this.errorTypesMap.set(element.proposalEconomicMeasureType, []);

            if ((this.validateFormMap?.get(element.proposalEconomicMeasureType)?.["warningTypes"] as string[] || []).length) {
              this.warningTypesMap.set(element.proposalEconomicMeasureType, this.validateFormMap?.get(element.proposalEconomicMeasureType)?.["warningTypes"] as string[]);
            }

            if ((this.validateFormMap?.get(element.proposalEconomicMeasureType)?.["errorTypes"] as string[] || []).length) {
              this.errorTypesMap.set(element.proposalEconomicMeasureType, this.validateFormMap?.get(element.proposalEconomicMeasureType)?.["errorTypes"] as string[]);
            } else {
              const formInfo = this.additionalInfoProposalForm.get(element.proposalEconomicMeasureType);
              if (formInfo) {
                formInfo["errorTypes"] = [] as ProposalErrorTypesEnum[];
              }
            }
          }
        }
      }
      this.isLoadingProposal = false;
    } else {
      this.proposalNote = withExistentProposal ? (this.userProposalDetails?.currentProposal?.proposalNote ?? "") : "";
      this.isLoadingProposal = false;
    }
  }

  protected async getTypeDetails(proposalEconomicsDetails?: ProposalEconomicsDetails[]) {
    const promises = [];
    if (this.proposalFormInfo?.length) {
      const proposalAdditionalInfo = [];
      const proposalEconomicsDetailsEmpty = [];
      for (const element of this.proposalFormInfo) {
        proposalEconomicsDetailsEmpty.push(
          {
            proposalEconomicMeasureType: element.economicMeasureType,
            amount: 0,
            absorbedAmount: 0,
            processContractLevelId: (this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(element.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelId as string || "",
            processContractLevelName: (this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(element.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelName as string || "",
            extraBudgetProposalReasonType: this.selectedOptionExtraBudgetType && this.selectedOptionExtraBudgetType.get(element.economicMeasureType)?.id as ExtraBudgetProposalReasonTypesEnum || null,
            extraBudgetProposalNote: this.proposalFieldMap && this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] as string || "",
            stiType: this.selectedOptionStiType && this.selectedOptionStiType.get(element.economicMeasureType)?.id as StiTypesEnum,
            percentage: 0
          }
        );
      }

      for (const element of this.proposalFormInfo) {
        promises.push(this.getTypeDetail(element.economicMeasureType, proposalEconomicsDetails?.length ? proposalEconomicsDetails : proposalEconomicsDetailsEmpty));
        proposalAdditionalInfo.push({ economicMeasureType: element.economicMeasureType, value: {} });
      }

      const values = await Promise.all(promises);

      for (const [index, element] of values.entries()) {
        proposalAdditionalInfo[index].value = element as ProposalEconomicsDetails;
      }

      this.additionalInfoProposalForm = new Map(proposalAdditionalInfo.map((obj) => [obj.economicMeasureType, obj.value]));
    }
  }


  protected async getTypeDetail(economicMeasureType: ProposalEconomicMeasureTypesEnum, proposalEconomicDetails: ProposalEconomicsDetails[]) {
    if (!this.selectedUser) {
      return null;
    }
    const result = await this.profileBasedAPIService.getTypeDetailsInfo(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser.userId, this.selectedProposalOptionType?.id, economicMeasureType, proposalEconomicDetails || []);
    return result;
  }

  protected async getPreviewDetail(economicMeasureType: ProposalEconomicMeasureTypesEnum, proposalEconomicDetails: ProposalEconomicsDetails[]) {
    if (!this.selectedUser) {
      return null;
    }
    const result = await this.profileBasedAPIService.getPreviewInfo(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser.userId, this.selectedProposalOptionType?.id, economicMeasureType, proposalEconomicDetails || []);
    return result;
  }

  // Formatto valore passato
  numberWithCommas(text: string | number | ProposalEconomicMeasureWarningTypesEnum[] | ProposalErrorTypesEnum[], removePoints?: boolean, noDecimals?: boolean) {
    if (text) {
      if (typeof text == "string") {
        if (removePoints) {
          text = text.replaceAll(".", "");
          text = text.replaceAll(",", "");
        }
        text = new Intl.NumberFormat("it-IT", {
          style: "currency",
          currency: "EUR"
        }).format(parseFloat(text));
        text = text.substring(0, text.length - 2);
        if (text.slice(-2) == "00") {
          text = text.slice(0, -3);
        } else
          if (text.slice(-1) == "0") {
            text = text.slice(0, -1);
          }
      } else if (typeof text == "number") {
        text = new Intl.NumberFormat("it-IT", {
          style: "currency",
          currency: "EUR"
        }).format(text);
        text = text.substring(0, text.length - 2);
        if (text.slice(-2) == "00") {
          text = text.slice(0, -3);
        } else
          if (text.slice(-1) == "0") {
            text = text.slice(0, -1);
          }
      } else {
        return NaN;
      }


      if (noDecimals) {
        const value = Math.round(parseFloat(text.replaceAll(".", "")));
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return text;
      }
    } else {
      return "0";
    }

  }

  onInputProposalFormChange(type: ProposalFieldTypesEnum, economicMeasureType: ProposalEconomicMeasureTypesEnum, value: ProposalEconomicMeasureTypesEnum) {

    if (this.proposalFieldMap.get(economicMeasureType)) {
      const el = this.proposalFieldMap.get(economicMeasureType);
      if (el) {
        el[type] = value;
      }
    } else {
      this.proposalFieldMap.set(economicMeasureType, {});
    }
  }

  onTextAreaChange(type: ProposalFieldTypesEnum, economicMeasureType: ProposalEconomicMeasureTypesEnum, value: string) {
    if (this.proposalFieldMap.get(economicMeasureType)) {
      const el = this.proposalFieldMap.get(economicMeasureType);
      if (el) {
        el[type] = value;
      }
    } else {
      this.proposalFieldMap.set(economicMeasureType, {});
    }
  }

  protected async onBlurInputAmount(event: FocusEvent | Event | MouseEvent, type: ProposalFieldTypesEnum, economicMeasureType: ProposalEconomicMeasureTypesEnum, isAbsorbedAmountInput?: boolean) {
    this.idToFocusAfterLoadingValues = "inputProposalForm-" + type + "-" + economicMeasureType;
    this.passedOnBlurInput = true;
    if (type != ProposalFieldTypesEnumTypes.PERCENTAGE) {
      const value = this.proposalFieldMap.get(economicMeasureType);
      if (value) {
        value[type] = this.numberWithCommas((event.target as HTMLInputElement).value, true) as string;
      }
    }
    if ((event.target as HTMLInputElement).value) {
      this.isLoadingProposal = true;
      const validateFormsToSend: ProposalFieldValidationRequest[] = [];
      if (this.proposalFormInfo?.length) {
        for (const element of this.proposalFormInfo) {
          validateFormsToSend.push(
            {
              proposalEconomicMeasureType: element.economicMeasureType,
              fieldType: this.getFieldTypeForValidateForm(element.economicMeasureType),
              fieldValue: this.getFieldValueForValidateForm(element.economicMeasureType) ?? 0,
              proposalEconomicsDetails: []
            }
          );

        }

        if (validateFormsToSend?.length) {
          for (const el of this.proposalFormInfo) {
            for (const [i, element] of validateFormsToSend.entries()) {
              element;
              validateFormsToSend[i].proposalEconomicsDetails?.push(
                {
                  proposalEconomicMeasureType: el.economicMeasureType,
                  amount: el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM
                    || el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE
                    || (el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE
                      && this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.BASELINE_AMOUNT
                      || this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.MAX_THEORETICAL_AMOUNT)
                    ? (this.getFieldValueForValidateForm(el.economicMeasureType) ?? 0) : 0,
                  absorbedAmount: el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT
                    ? (this.getFieldValueForValidateForm(el.economicMeasureType) ?? 0) : 0,
                  processContractLevelId: (this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(el.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelId ?? "",
                  processContractLevelName: (this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(el.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelId ?? "",
                  extraBudgetProposalReasonType: this.selectedOptionExtraBudgetType && this.selectedOptionExtraBudgetType.get(el.economicMeasureType)?.id as ExtraBudgetProposalReasonTypesEnum || null,
                  extraBudgetProposalNote: this.proposalFieldMap && this.proposalFieldMap.get(el.economicMeasureType)?.[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] as string,
                  stiType: this.selectedOptionStiType && this.selectedOptionStiType.get(el.economicMeasureType)?.id as StiTypesEnum,
                  percentage: el.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE
                    && this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.BASELINE_PERCENTAGE
                    || this.selectedOptionStiType?.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id == StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE
                    ? (this.getFieldValueForValidateForm(el.economicMeasureType) ?? 0) : 0
                }
              );
            }
          }
        }

        if (!this.selectedUser) {
          return;
        }

        const allValidateInfo: { economicMeasureType: ProposalEconomicMeasureTypesEnum, value: MapById<string | boolean | string[]> }[] = [];
        for (const element of validateFormsToSend) {
          const validate = await this.profileBasedAPIService.getValidateInfo(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser.userId, this.selectedProposalOptionType?.id, element, this.structureId);
          if (validate) {
            allValidateInfo.push({ economicMeasureType: element.proposalEconomicMeasureType, value: validate as unknown as MapById<string | boolean | string[]> });
          }
        }
        this.validateFormMap = new Map(allValidateInfo.map((obj) => [obj.economicMeasureType, obj.value]));
      }

      this.warningTypesMap.set(economicMeasureType, []);
      this.errorTypesMap.set(economicMeasureType, []);

      if ((this.validateFormMap?.get(economicMeasureType)?.["warningTypes"] as string[] || []).length) {
        this.warningTypesMap.set(economicMeasureType, this.validateFormMap?.get(economicMeasureType)?.["warningTypes"] as string[]);
      }

      if ((this.validateFormMap?.get(economicMeasureType)?.["errorTypes"] as string[] || []).length) {
        this.errorTypesMap.set(economicMeasureType, this.validateFormMap?.get(economicMeasureType)?.["errorTypes"] as string[]);
      } else {
        const formInfo = this.additionalInfoProposalForm.get(economicMeasureType);
        if (formInfo) {
          formInfo["errorTypes"] = [] as ProposalErrorTypesEnum[];
        }
      }

      if (isAbsorbedAmountInput) {
        await this.getTypeDetails(validateFormsToSend[0].proposalEconomicsDetails);
      }

      if (!((this.validateFormMap?.get(economicMeasureType)?.["errorTypes"] as string[] || [])?.length)) {
        const promises = [];
        if (this.proposalFormInfo?.length) {
          const previewInfo = [];
          for (const element of this.proposalFormInfo) {
            promises.push(this.getPreviewDetail(element.economicMeasureType, validateFormsToSend[0].proposalEconomicsDetails as ProposalEconomicsDetails[]));
            previewInfo.push({ economicMeasureType: element.economicMeasureType, value: {} });
          }
          const response = await Promise.all(promises);

          for (const [index, element] of response.entries()) {
            previewInfo[index].value = element as ProposalEconomicsDetails;
          }
          this.previewFormMap = new Map(previewInfo.map((obj) => [obj.economicMeasureType, obj.value]));

          if (((event as MouseEvent).relatedTarget as HTMLInputElement)?.id == "save-propose-button") {
            await this.savePropose();
          } else if (((event as MouseEvent).relatedTarget as HTMLInputElement)?.id == "delete-propose-button") {
            await this.deleteProposal();
          }
          this.isLoadingProposal = false;
          setTimeout(() => {
            if (document.getElementById(this.idToFocusAfterLoadingValues) && document.getElementById("propose-sidenav")) {
              document.getElementById(this.idToFocusAfterLoadingValues)?.scrollIntoView();
              (document.getElementById("propose-sidenav") as HTMLInputElement).scrollTop = (document.getElementById("propose-sidenav") as HTMLInputElement).scrollTop - 30;
            }
          }, 150);

        }
      } else {
        this.isLoadingProposal = false;
        setTimeout(() => {
          if (document.getElementById(this.idToFocusAfterLoadingValues) && document.getElementById("propose-sidenav")) {
            document.getElementById(this.idToFocusAfterLoadingValues)?.scrollIntoView();
            (document.getElementById("propose-sidenav") as HTMLInputElement).scrollTop = (document.getElementById("propose-sidenav") as HTMLInputElement).scrollTop - 30;
          }
        }, 150);
      }
    }

  }

  onSelectedExtraBudgetTypeOptionChanged(optionSelected: ProposalOption, economicMeasureType: ProposalEconomicMeasureTypesEnum) {
    this.selectedOptionExtraBudgetType.set(economicMeasureType, optionSelected);
  }

  // modifiche per problemi eliminazione valori con aumento budget e variazione sti, da ricontrollare in caso di problemi (passa notClear a true da html)
  onSelectedStiTypeOptionChanged(optionSelected: ProposalOption, economicMeasureType: ProposalEconomicMeasureTypesEnum, notClear?: boolean) {
    this.selectedOptionStiType.set(economicMeasureType, optionSelected);
    if (!notClear) {
      if (this.proposalFormInfo?.length) {
        for (const element of this.proposalFormInfo) {
          this.proposalFieldMap.set(element.economicMeasureType, {});
          this.previewFormMap.set(element.economicMeasureType, {});
          this.validateFormMap.set(element.economicMeasureType, {});
        }
      }
      const el = this.proposalFieldMap.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE);
      if (el) {
        el[ProposalFieldTypesEnumTypes.AMOUNT] = "";
        el[ProposalFieldTypesEnumTypes.PERCENTAGE] = "";
      }
    }

    if ([StiTypesEnumTypes.BASELINE_PERCENTAGE, StiTypesEnumTypes.MAX_THEORETICAL_PERCENTAGE].includes(this.selectedOptionStiType.get(ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE)?.id as string)) {
      if (this.proposalFormInfo?.length) {
        for (const element of this.proposalFormInfo) {
          if (element.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE && element.fields?.length) {
            for (const el of element.fields) {
              if (el.type == ProposalFieldTypesEnumTypes.AMOUNT) {
                el.required = false;
              } else if (el.type == ProposalFieldTypesEnumTypes.PERCENTAGE) {
                el.required = true;
              } else if (el.type == ProposalFieldTypesEnumTypes.STI_TYPE) {
                el.required = false;
              }
            }
          }
        }
      }
    } else {
      if (this.proposalFormInfo?.length) {
        for (const element of this.proposalFormInfo) {
          if (element.economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.STI_UPDATE && element.fields?.length) {
            for (const el of element.fields) {
              if (el.type == ProposalFieldTypesEnumTypes.AMOUNT) {
                el.required = true;
              } else if (el.type == ProposalFieldTypesEnumTypes.PERCENTAGE) {
                el.required = false;
              } else if (el.type == ProposalFieldTypesEnumTypes.STI_TYPE) {
                el.required = false;
              }
            }
          }
        }
      }
    }
  }

  clearSearch(economicMeasureType: ProposalEconomicMeasureTypesEnum, type: ProposalFieldTypesEnum) {
    const input = this.proposalFieldMap.get(economicMeasureType);
    if (input) {
      input[type] = "";
    }
  }

  onProposalNoteChange(text: string) {
    this.proposalNote = text;
  }

  getLevelAdvancementCost(economicMeasureType: ProposalEconomicMeasureTypesEnum) {
    const levelAdvancementCostPreviewString = (this.previewFormMap.get(economicMeasureType) as MapById<unknown>)?.["levelAdvancementCost"] as string;
    if (levelAdvancementCostPreviewString) {
      return this.numberWithCommas(levelAdvancementCostPreviewString, false, true) + "€";
    } else {
      return this.numberWithCommas(this.additionalInfoProposalForm.get(economicMeasureType)?.["levelAdvancementCost"] as string, false, true) + "€";
    }
  }

  onKeyPressInput(e?: KeyboardEvent) {
    const charCode = e?.which ? e.which : e?.keyCode;
    if (
      charCode &&
      charCode != 43 &&
      charCode != 45 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    )
      return false;

    return true;
  }

  protected async savePropose() {
    this.isLoadingProposal = true;
    if (this.selectedProposalOptionType?.id) {
      const formToSave: ProposalUpdateRequest = {
        proposalType: this.selectedProposalOptionType?.id as ProposalTypesEnum,
        proposalEconomicsDetails: [
        ],
        proposalNote: this.proposalNote,
        version: this.userProposalDetails?.currentProposal?.version ?? undefined
      };

      if (this.proposalFormInfo?.length) {
        for (const element of this.proposalFormInfo) {

          let amount = 0;
          let absorbedAmount = 0;

          if (typeof this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.AMOUNT] === "string") {
            const value = this.proposalFieldMap.get(element.economicMeasureType);
            let valueToUse = "0";
            if (value) {
              valueToUse = (value[ProposalFieldTypesEnumTypes.AMOUNT] as string).split(".").join("");
              valueToUse = valueToUse.replaceAll(",", "");
            }
            amount = parseInt(valueToUse);
          } else {
            this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.AMOUNT] as number;
          }

          if (typeof this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.ABSORBED_AMOUNT] === "string") {
            const value = this.proposalFieldMap.get(element.economicMeasureType);
            let valueToUse = "0";
            if (value) {
              valueToUse = (value[ProposalFieldTypesEnumTypes.ABSORBED_AMOUNT] as string).split(".").join("");
              valueToUse = valueToUse.replaceAll(",", "");
            }
            absorbedAmount = parseInt(valueToUse);
          } else {
            this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.ABSORBED_AMOUNT] as number;
          }

          const percentage = typeof this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.PERCENTAGE] === "string" ?
            parseInt((this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.PERCENTAGE]) as string)
            : this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.PERCENTAGE] as number;


          formToSave.proposalEconomicsDetails?.push({
            proposalEconomicMeasureType: element.economicMeasureType,
            amount: amount || 0,
            absorbedAmount: absorbedAmount || 0,
            processContractLevelId: (this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(element.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelId as string || "",
            processContractLevelName: (this.additionalInfoProposalForm && this.additionalInfoProposalForm.get(element.economicMeasureType) as ProposalEconomicMeasureTypeDetails)?.nextProcessContractLevelName as string || "",
            extraBudgetProposalReasonType: this.selectedOptionExtraBudgetType && this.selectedOptionExtraBudgetType.get(element.economicMeasureType)?.id as ExtraBudgetProposalReasonTypesEnum || null,
            extraBudgetProposalNote: this.proposalFieldMap && this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] as string || "",
            stiType: this.selectedOptionStiType && this.selectedOptionStiType.get(element.economicMeasureType)?.id as StiTypesEnum,
            percentage: percentage || 0
          });
        }
      }

      if (!this.selectedUser) {
        return;
      }

      await this.profileBasedAPIService.savePropose(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser.userId, formToSave, this.structureId);

      this.isLoadingProposal = false;
      this.closeProposalSidebar();
      await this.loadTeamData();

    }
  }

  saveDisabled() {
    if ((this.validateFormMap?.get(ProposalEconomicMeasureTypesEnumTypes.LEVEL_ADVANCEMENT)?.["errorTypes"] as string[] || []).length) {
      return true;
    }

    let allRequiredFieldsNotEmpty = true;
    if (this.proposalFormInfo?.length) {
      for (const element of this.proposalFormInfo) {
        if (element.fields?.length) {
          for (const field of element.fields) {
            if (field.required) {
              const mapToManage = (field.type == ProposalFieldTypesEnumTypes.CONTRACT_LEVEL ? this.additionalInfoProposalForm : this.proposalFieldMap);
              let otherField = null;
              if (field.type == ProposalFieldTypesEnumTypes.CONTRACT_LEVEL) {
                otherField = "nextProcessContractLevelName";
              }

              const fieldValue = mapToManage.get(element.economicMeasureType)![otherField ?? field.type as ProposalFieldTypesEnum];

              const parsedValue = parseFloat(fieldValue as string);
              const isNotEmpty = !isNaN(parsedValue)
                ? parsedValue > 0
                : (typeof fieldValue == "string"
                  ? !!fieldValue?.length
                  : !!fieldValue);

              if (this.selectedOptionExtraBudgetType.get(element.economicMeasureType)?.id == ExtraBudgetProposalReasonTypesEnumTypes.OTHER
                && !((this.proposalFieldMap.get(element.economicMeasureType)?.[ProposalFieldTypesEnumTypes.EXTRA_BUDGET_NOTES] as string || "").length)) {
                return true;
              } else {
                allRequiredFieldsNotEmpty = allRequiredFieldsNotEmpty && isNotEmpty;
              }
            }
          }
        }
      }

    }
    return !allRequiredFieldsNotEmpty;

  }

  isFieldValueValid(value: string | number | string[]): boolean {
    const result: boolean = value
      ? (typeof value === "object" ? !!value?.length : typeof value === "string" ? !!value?.length : (typeof value === "number" ? !isNaN(value) : false))
      : false;
    return result;
  }

  getWarningToCycle(economicMeasureType: ProposalEconomicMeasureTypesEnum) {
    const warnings = [];
    if ((this.additionalInfoProposalForm.get(economicMeasureType)!["warningTypes"] as ProposalEconomicMeasureWarningTypesEnum[])?.length) {
      warnings.push(this.additionalInfoProposalForm.get(economicMeasureType)!["warningTypes"] as ProposalEconomicMeasureWarningTypesEnum);
    }
    if (this.warningTypesMap.get(economicMeasureType)?.length) {
      warnings.push(this.warningTypesMap.get(economicMeasureType));
    }

    return warnings;
  }

  // Recupero e gestione filtri
  async getFilterKeys() {
    this.isLoadingFilters = true;
    const filterKeys = await this.profileBasedAPIService.getProposalFilters(this.processId, this.structureId, this.activeTab.id ?? "");
    if (!filterKeys) {
      return;
    }
    this.filters.allowedLoaded = true;
    for (const key of filterKeys) {
      if ((FilterTypeByKey[key] == "stiCategory" && this.processType != ProcessTypeEnumTypes.STANDARD) || FilterTypeByKey[key] == "stiDetails" && this.processType != ProcessTypeEnumTypes.EXECUTIVE) {
        (this.filters.data[FilterTypeByKey[key] as keyof FilterData]).active = false;
      } else {
        (this.filters.data[FilterTypeByKey[key] as keyof FilterData]).active = true;
      }

    }

    this.isLoadingFilters = false;
  }

  async expandFilter(id: string) {
    this.isLoadingFilters = true;
    this.filters.data[id as keyof FilterData].isOpen = !this.filters.data[id as keyof FilterData].isOpen;
    if (!this.filters.data[id as keyof FilterData].loaded) {
      if (id != "STRUCTURE_NAME" && this.filters.data[id as keyof FilterData].loaded)
        return;

      const tmp = await this.profileBasedAPIService.getProposalFiltersValue(this.processId, this.structureId, this.activeTab.id ?? "", FilterTypeByName[id]);
      // Per la struttura organizzativa c'è anche la ricerca, potrei dover richiamare più volte la funzione
      if (tmp) {
        if (id != "STRUCTURE_NAME") {
          this.filters.data[id as keyof FilterData].loaded = true;
        }
        if (this.enumFilterTypeKey.includes(id as keyof FilterData)) {
          this.filters.data[id as keyof FilterData].values = tmp as string[];
        } else if (this.rangeFilterTypeKey.includes(id as keyof FilterData)) {
          this.filters.data[id as keyof FilterData].values = tmp as NumberRangeFilter;
        } else if (id == "contractLevelIds") {
          this.filters.data[id as keyof FilterData].values = tmp as ProposalFilterContractLevelResponse[];
        } else if (id == "structureIds") {
          this.filters.data[id as keyof FilterData].values = tmp as ProposalFilterStructureResponse[];
          (this.filters.data[id as keyof FilterData].values as ProposalFilterStructureResponse[]).unshift({
            structureId: "all",
            name: this.translateService.instant("proposal.filter.structureIds.ALL")
          });

        } else if (this.comboFilterTypeKey.includes(id as keyof FilterData)) {
          this.filters.data[id as keyof FilterData].values = tmp as ProposalFilterStiCategoryResponse[] | ProposalFilterStiDetailsResponse || ProposalFilterLastMeasureResponse;
        }
      }
    }
    this.isLoadingFilters = false;
  }

  arraysAreDifferent(values: unknown[], selected: unknown[]) {
    // Check if lengths are different
    if (values.length !== selected.length) {
      return true;
    }

    // Check if there is at least one element that is different
    return !values.every((value, index) => value === selected[index]);
  }

  getSelectedIfDifferent(values: unknown[], selected: unknown[]) {
    if (this.arraysAreDifferent(values, selected)) {
      return selected;
    }
    return [];
  }

  // Apre modale di conferma inoltro proposte
  async openConfirmSendProposalModal() {
    this.proposalsToBeConfirmed = await this.profileBasedAPIService.proposalsToBeConfirmed(this.processId, this.structureId);
    this.modalService.open("modal-confirm-send-proposal");
  }

  async closeConfirmSendProposalModal(confirm?: boolean) {
    this.modalService.close("modal-confirm-send-proposal");
    if (confirm) {
      const response = await this.profileBasedAPIService.confirmProposals(this.processId, this.structureId);
      if (response) {
        this.toast.success(this.translateService.instant("proposal.PROPOSAL_CONFIRMED"));
        await this.loadTeamData();
      }
    }
  }

  protected async deleteProposal() {
    this.isLoadingProposal = true;
    if (!this.selectedUser) {
      return;
    }

    await this.profileBasedAPIService.deletePropose(this.processId, this.selectedUser?.structureId ?? "", this.selectedUser.userId, this.userProposalDetails?.currentProposal?.version as number, this.structureId);

    this.isLoadingProposal = false;
    this.closeProposalSidebar();
    await this.loadTeamData();
  }

  getImportLabel(economicMeasureType: ProposalEconomicMeasureTypesEnum) {
    if (this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE_AND_LEVEL_ADVANCEMENT_AND_UNA_TANTUM
      || this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE_AND_STI_UPDATE
      || this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE_AND_UNA_TANTUM
      || this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE_AND_UNA_TANTUM_AND_STI_UPDATE
      || this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.LEVEL_ADVANCEMENT_AND_ANNUAL_RETRIBUTION_INCREASE
      || this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.LEVEL_ADVANCEMENT_AND_UNA_TANTUM
      || this.selectedProposalOptionType?.id == ProposalTypesEnumTypes.UNA_TANTUM_AND_STI_UPDATE
    ) {
      if (economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.ANNUAL_RETRIBUTION_INCREASE) {
        return this.translateService.instant("proposal.INSERT_IMPORT_ANNUAL_RETRIBUTION_INCREASE");
      } else if (economicMeasureType == ProposalEconomicMeasureTypesEnumTypes.UNA_TANTUM) {
        return this.translateService.instant("proposal.INSERT_IMPORT_UNA_TANTUM");
      } else {
        return this.translateService.instant("proposal.INSERT_IMPORT");
      }
    } else {
      return this.translateService.instant("proposal.INSERT_IMPORT");
    }
  }


  async downloadProposalReport() {
    this.isDownloadingReport = true;
    const file = await this.profileBasedAPIService.downloadProposalReport(this.processId, this.structureId);
    if (file) {
      const a = document.createElement("a");
      file.fileName = file.fileName.substring(1, file.fileName.length - 1);
      a.href = file.fileUrl;
      a.download = file.fileName;
      a.click();
      URL.revokeObjectURL(a.href);
    }
    this.isDownloadingReport = false;
  }

  protected async getProposalUserDetails(selectedUser: ProposalUserDetails | null) {
    if (this.selectedUser) {
      this.isLoadingAnagUserDetails = true;
      this.anagUserDetails = await this.profileBasedAPIService.getProposalAnagUserDetails(this.processId, selectedUser?.structureId as string, selectedUser?.userId as string);
      if (this.anagUserDetails?.allowancesNames?.length) {
        this.anagUserDetails.allowancesLabel = "";
        for (const element of this.anagUserDetails.allowancesNames) {
          this.anagUserDetails.allowancesLabel += element + ", ";
        }
        this.anagUserDetails.allowancesLabel = this.anagUserDetails.allowancesLabel.slice(0, -2);
      }

      this.isLoadingAnagUserDetails = false;
    }
  }

  protected async getTimelineDetails(selectedUser: ProposalUserDetails | null) {
    if (this.selectedUser) {
      this.isLoadingTimelineDetails = true;
      this.timelineDetails = await this.profileBasedAPIService.getProposalTimelineDetails(this.processId, selectedUser?.structureId as string, selectedUser?.userId as string, this.structureId);

      this.isLoadingTimelineDetails = false;
    }
  }

  getDisponibleBudget(economicMeasureType: ProposalEconomicMeasureTypesEnum, type: "availableBudget" | "absorbableAmount", isVisible?: boolean) {
    if (isVisible && ((this.previewFormMap.get(economicMeasureType)?.[type] != null
      && this.previewFormMap.get(economicMeasureType)?.[type] != undefined)
      || (this.additionalInfoProposalForm.get(economicMeasureType)?.[type] != null
        && this.additionalInfoProposalForm.get(economicMeasureType)?.[type] != undefined))) {
      return true;
    } else {
      if (this.previewFormMap.get(economicMeasureType)?.[type] != null && this.previewFormMap.get(economicMeasureType)?.[type] != undefined) {
        return this.numberWithCommas(this.previewFormMap.get(economicMeasureType)?.[type] as number);
      } else if (this.additionalInfoProposalForm.get(economicMeasureType)?.[type] != null && this.additionalInfoProposalForm.get(economicMeasureType)?.[type] != undefined) {
        return this.numberWithCommas(this.additionalInfoProposalForm.get(economicMeasureType)?.[type] as number);
      } else {
        return;
      }
    }
  }
  // Ritorna il valore del budget disponibile come number
  getDisponibleBudgetNumber(economicMeasureType: ProposalEconomicMeasureTypesEnum, type: "availableBudget" | "absorbableAmount") {
    if (this.previewFormMap.get(economicMeasureType)?.[type] != null && this.previewFormMap.get(economicMeasureType)?.[type] != undefined) {
      return this.previewFormMap.get(economicMeasureType)?.[type] as number;
    } else if (this.additionalInfoProposalForm.get(economicMeasureType)?.[type] != null && this.additionalInfoProposalForm.get(economicMeasureType)?.[type] != undefined) {
      return this.additionalInfoProposalForm.get(economicMeasureType)?.[type] as number;
    } else {
      return 0;
    }
  }


  resetProposalSidenavData() {
    this.proposalFieldMap.clear();
    this.proposalFormInfo = [];
    this.previewFormMap.clear();
    this.validateFormMap.clear();
    this.selectedOptionExtraBudgetType.clear();
    this.selectedOptionStiType.clear();
    this.proposalNote = "";
    this.additionalInfoProposalForm.clear();
    this.warningTypesMap.clear();
    this.errorTypesMap.clear();
    this.selectedProposalOptionType = null;
  }


  // Trasformare in async quando c'è il servizio
  protected async openHierarchySidebar() {
    this.offcanvasService.open(this.hierarchySidebar, { position: "end", ariaLabelledBy: "content-sidebar-hierarchy-title-text", panelClass: "hierarchy-offcanvas" });
    this.isLoadingHierarchyData = true;
    if (!this.hierarchyDataLoaded) {
      this.hierarchyData = await this.profileBasedAPIService.getHieararchyStructure(this.processId, this.structureId);
      // this.formattedData = this.convertToTreeNodes(this.hierarchyData);
      this.treeFormattedData = this.convertToHierarchyNodes(this.hierarchyData);
    }
    this.hierarchyDataLoaded = true;
    this.isLoadingHierarchyData = false;
  }

  convertToHierarchyNodes(structureNodes: StructureTreeNode[]): HierarchyNode[] {
    return structureNodes.map(structureNode => {
      const treeNode: HierarchyNode = {
        ...structureNode,
        isSelected: false,
        isOpen: false,
        children: structureNode.childrenStructures as HierarchyNode[]
      };

      if (structureNode.childrenStructures && structureNode.childrenStructures.length > 0) {
        treeNode.children = this.convertToHierarchyNodes(structureNode.childrenStructures);
      }

      return treeNode;
    });
  }
  // Funzione per ottenere i codici dei nodi selezionati
  getSelectedNodeIds(nodes: HierarchyNode[]): string[] {
    const selectedCodes: string[] = [];

    function traverse(node: HierarchyNode) {
      if (node.isSelected) {
        selectedCodes.push(node.structureId as string);
      }
      if (node.children) {
        node.children.forEach(child => traverse(child));
      }
    }

    nodes.forEach(node => traverse(node));
    return selectedCodes;
  }

  async applyHierarchyFilter() {
    await this.closeHierarchySidebar(true);
  }

  async resetHierarchyFilter(nodes: HierarchyNode[]) {
    function traverse(node: HierarchyNode) {
      node.isSelected = false;
      if (node.children) {
        node.children.forEach(child => traverse(child));
      }
    }

    nodes.forEach(node => traverse(node));
    await this.closeHierarchySidebar(true);
  }

  convertToTreeNodes(structureNodes: StructureTreeNode[]): TreeNode[] {
    return structureNodes.map(structureNode => {
      const treeNode: TreeNode = {
        label: structureNode.name,
        data: structureNode,
        expandedIcon: "assets/icons/remove-circle-outline.svg",
        collapsedIcon: "assets/icons/add-circle-outline.svg",
        children: []
      };

      if (structureNode.childrenStructures && structureNode.childrenStructures.length > 0) {
        treeNode.children = this.convertToTreeNodes(structureNode.childrenStructures);
      }

      return treeNode;
    });
  }

  openHierarchyItem(item: HierarchyNode) {
    item.isOpen = !item.isOpen;
  }

  toggleHierarchyItem(item: HierarchyNode) {
    item.isSelected = !item.isSelected;
  }

  async closeHierarchySidebar(confirm?: boolean) {
    this.offcanvasService.dismiss(this.hierarchySidebar);
    if (confirm) {
      this.chargingDataForHierarchy = true;
      await this.loadTeamData(true);
      await this.getDashboardSummaryData();
      this.chargingDataForHierarchy = false;
    }
  }

  getHierarchyActionLabel() {
    let title: string = "";
    if (this.treeFormattedData) {
      const items = this.getSelectedNodeIds(this.treeFormattedData);
      title = this.translateService.instant("proposal.actions.HIERARCHY");
      title += " ";
      const single: string = this.translateService.instant("proposal.actions.SELECTED");
      const multi: string = this.translateService.instant("proposal.actions.SELECTED_P");
      if (items.length == 1) {
        title = title + "(" + items.length + " " + single + ")";
      } else if (items.length > 1) {
        title = title + "(" + items.length + " " + multi + ")";
      }
    }
    return title;
  }

  onMultiSelectChange(event: ProposalFilterStructureResponse) {
    if (event.structureId == "all") {
      // Se è già selezionato, tolgo tutte le opzioni
      if (this.filters.data.structureIds.selected.find((item: ProposalFilterStructureResponse) => item.structureId == "all")) {
        this.filters.data.structureIds.selected = [];
      } else {
        // Altrimenti seleziono tutto
        this.filters.data.structureIds.selected = this.filters.data.structureIds.values;
      }
    } else {
      this.filters.data.structureIds.selected = this.filters.data.structureIds.selected.filter((item: ProposalFilterStructureResponse) => item.structureId != "all");
      if (this.filters.data.structureIds.selected.find((item: ProposalFilterStructureResponse) => item.structureId == event.structureId)) {
        this.filters.data.structureIds.selected = this.filters.data.structureIds.selected.filter((item: ProposalFilterStructureResponse) => item.structureId != event.structureId);
      } else {
        this.filters.data.structureIds.selected.push(event);
      }
    }

    // if ((event.itemValue as ProposalFilterStructureResponse).structureId == "all") {
    //   // Se è già selezionato, tolgo tutte le opzioni
    //   if (this.filters.data.structureIds.selected.find((item: ProposalFilterStructureResponse) => item.structureId == "all")) {
    //     this.filters.data.structureIds.selected = this.filters.data.structureIds.values;
    //   } else {
    //     // Altrimenti seleziono tutto
    //     this.filters.data.structureIds.selected = [];
    //   }
    // } else {
    //   this.filters.data.structureIds.selected = this.filters.data.structureIds.selected.filter((item: ProposalFilterStructureResponse) => item.structureId != "all");
    // }
  }


  filterOrganizationUnit(text: Event) {
    if (text?.target) {
      this.filterOrganizationUnitText = (text.target as HTMLInputElement).value;
    } else {
      this.filterOrganizationUnitText = "";
    }
  }

  filteredStructures() {
    if (this.filterOrganizationUnitText?.length) {
      return this.filters.data.structureIds.values.filter((item) => item.name.toLowerCase().includes(this.filterOrganizationUnitText.toLowerCase()));
    } else {
      return this.filters.data.structureIds.values;
    }
  }

  getNumber(value: string | number | ProposalEconomicMeasureWarningTypesEnum[] | "ABSORBED_AMOUNT_GREATER_THAN_ABSORBABLE_AMOUNT"[]): number {
    if (value != null && !isNaN(parseFloat(value as string))) {
      return value as number;
    }
    return 0;
  }

  isNotEnumValue(enumType: string[], value: string): boolean {
    return !Object.values(enumType).includes(value);
  }
}

type ProposalOption = {
  id: string;
  label: string;
};

type PerformanceOverallEvaluationsEnumstring = "UNSATISFACTORY" | "IMPROVABLE" | "MEET" | "EXCEEDED" | "EXCEPTIONAL";

export type FilterEnumEntry = {
  id: string,
  title: string
};

// Questa probabilmente non servirà, perchè non viene passato con il nome del valore ai servizi di count e list, quindi probabilmente non serve

type FilterData = {
  proposalTypes: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: string[];
    values: string[];
    selected: ProposalFilterProposalTypeResponse[];
  };
  proposalStatuses: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: string[];
    values: string[];
    selected: ProposalFilterProposalStatusResponse[];
  };
  warnings: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: string[];
    values: string[];
    selected: ProposalFilterWarningResponse[];
  };
  age: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: NumberRangeFilter;
    values: NumberRangeFilter;
    selected: NumberRangeFilter;
  };
  groupSeniority: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: NumberRangeFilter;
    values: NumberRangeFilter;
    selected: NumberRangeFilter;
  };
  genderTypes: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: string[];
    values: string[];
    selected: GenderTypesEnum[];
  };
  employeeTypes: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: string[];
    values: string[];
    selected: UserCategoriesEnum[];
  };
  contractLevelIds: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: ProposalFilterContractLevelResponse[];
    values: ProposalFilterContractLevelResponse[];
    selected: ProposalFilterContractLevelResponse[];
  };
  lastMeasure: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: ProposalFilterLastMeasureResponse;
    values: ProposalFilterLastMeasureResponse;
    selected: ProposalFilterLastMeasureResponse;
  };
  equity: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: NumberRangeFilter;
    values: NumberRangeFilter;
    selected: NumberRangeFilter;
  };
  annualSalary: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: NumberRangeFilter;
    values: NumberRangeFilter;
    selected: NumberRangeFilter;
  };
  stiCategory: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: ProposalFilterStiCategoryResponse[];
    values: ProposalFilterStiCategoryResponse[];
    selected: ProposalFilterStiCategoryResponse[];
  };
  stiDetails: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: ProposalFilterStiDetailsResponse;
    values: ProposalFilterStiDetailsResponse;
    selected: ProposalFilterStiDetailsResponse;
  };
  structureIds: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: ProposalFilterStructureResponse[];
    values: ProposalFilterStructureResponse[];
    selected: ProposalFilterStructureResponse[];
  };
  performanceOverallEvaluations: {
    active: boolean;
    loaded: boolean;
    isOpen: boolean;
    applied: PerformanceOverallEvaluationsEnum[];
    values: PerformanceOverallEvaluationsEnum[];
    selected: PerformanceOverallEvaluationsEnum[];
  };
};

type FilterDataKeys = keyof FilterData;
export class ProposalUserAnagDetailsExtended extends ProposalUserAnagDetails {
  allowancesLabel: string = "";
}

export class HierarchyNode extends StructureTreeNode {
  isOpen: boolean = false;
  isSelected: boolean = false;
  children: HierarchyNode[] = [];
  // Usati nel setup processi per la ricerca
  parent?: HierarchyNode;
  highlight?: boolean;
  selectedRole?: { code: StructureManagerRoleEnum, name: string };
}