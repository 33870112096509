import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HierarchyNode } from "src/app/pages/proposal/proposal.component";

@Component({
  selector: "h-tree-node",
  templateUrl: "./h-tree-node.component.html",
  styleUrl: "./h-tree-node.component.scss"
})

export class HTreeNodeComponent {
  @Input() nodes: HierarchyNode[] = [];
  @Output() selectNode: EventEmitter<HierarchyNode> = new EventEmitter<HierarchyNode>();

  toggleNode(node: HierarchyNode) {
    node.isOpen = !node.isOpen;
  }

  onSelectNode(node: HierarchyNode) {
    if (node.children) {
      this.selectChildren(node.children, node.isSelected);
    }
  }

  private selectChildren(children: HierarchyNode[] | undefined, isSelected: boolean) {
    if (children) {
      children.forEach(child => {
        child.isSelected = isSelected;
        if (child.children) {
          this.selectChildren(child.children, isSelected);
        }
      });
    }
  }

  hasChildren(node: HierarchyNode) {
    return node?.children?.length;
  }
}
