import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { AuthenticationGuardFn } from "./global-service/authentication.guard";
import { AdminComponent } from "./pages/admin/admin.component";
import { AdminGuard, HrbpGuard, ManagerGuard } from "./global-service/authorization.guard";
import { HrbpComponent } from "./pages/hrbp/hrbp.component";
import { ManagerComponent } from "./pages/manager/manager.component";

export namespace UrlCollection {
  export const localAuthentication = "localLogin";
  export const homepage = "homepage";
  export const samlSso = "samlSso";
  export const notAuthorized = "notAuthorized";
  export const notFound = "notFound";
  export const redirectTo = "";
  export const redirectRequest = "redirectRequest";
  export const forbidden = "403";

  export const admin = "admin";
  export const hrbp = "hrbp";
  export const manager = "manager";
  export const homepageActions = "homepage-actions";
  export const impersonify = "impersonify";
  export const budgetMoving = "budget-moving";
  export const proposal = "proposal";
  export const measure = "measure";
  // Setup
  export const setup = "setup";
  export const process = "process";
  export const detailProcess = "detail-process";
  export const roleHierarchy = "role-hierarchy";
  // Lettere
  export const letterSetup = "letter-setup";
  export const sessions = "sessions";
}

const routes: Routes = [
  { path: "", redirectTo: UrlCollection.homepage, pathMatch: "full" },
  {
    path: UrlCollection.homepage,
    canActivate: [AuthenticationGuardFn],
    component: HomepageComponent
  },
  // Modulo Admin
  {
    path: UrlCollection.admin,
    component: AdminComponent,
    canActivate: [AuthenticationGuardFn, AdminGuard],
    loadChildren: async () => {
      const m = await import("./pages/admin/admin.module");
      return m.AdminModule;
    }
  },
  // Modulo hrbp
  {
    path: UrlCollection.hrbp,
    component: HrbpComponent,
    canActivate: [AuthenticationGuardFn, HrbpGuard],
    loadChildren: async () => {
      const m = await import("./pages/hrbp/hrbp.module");
      return m.HrbpModule;
    }
  },
  // Modulo manager
  {
    path: UrlCollection.manager,
    component: ManagerComponent,
    canActivate: [AuthenticationGuardFn, ManagerGuard],
    loadChildren: async () => {
      const m = await import("./pages/manager/manager.module");
      return m.ManagerModule;
    }
  },
  {
    path: UrlCollection.samlSso,
    loadChildren: async () => {
      const m = await import("./pages/saml-sso/saml-sso.module");
      return m.SamlSsoModule;
    }
  },
  {
    path: UrlCollection.localAuthentication,
    loadChildren: async () => {
      const m = await import("./pages/local-authentication/local-authentication.module");
      return m.LocalAuthenticationModule;
    }
  },
  {
    path: UrlCollection.forbidden,
    component: ForbiddenComponent
  },
  { path: "**", redirectTo: UrlCollection.notFound }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
