<div [ngClass]="{
    positive: isPositive,
    negative: !isPositive
  }" class="equity-container d-flex flex-column w-100">
    <p class="value-text equity">
        {{ value > 0 ? "+" : "" }}
        {{ formatRoundValue(value) }} €
    </p>
    <p class="value-text equity-perc">
        {{ value > 0 ? "+" : "" }}
        {{ formatRoundValue(percentage, false , true) }}%
    </p>
</div>