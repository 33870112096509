<!-- Header app -->
<header id="header-content">
  <!-- Button per screen reader -->
  <button (click)="skipToContent()" class="skip-link">Salta al contenuto principale</button>

  <app-header *ngIf="appService.showHeader" [items]="drawerItems" (onOpen)="loadMenuOptions()"
    (onRedirection)="onNavTabClick($event)"></app-header>
  <!-- Banner impersonfica -->
  <div *ngIf="getImpersonifiedData().impersonifiedByUserRole?.length"
    class="d-flex flex-row align-items-center justify-content-center sectionImpersonification">
    <p>
      {{ "impersonify.YOU_ARE_IMPERSONIFICHING" | translate }}
      {{ getImpersonifiedData().forename }} {{ getImpersonifiedData().surname }}
    </p>
    <button type="button" class="btn text-uppercase fw-bold return-to-my-profile-button" (click)="returnToMyProfile()">
      <p>{{ "impersonify.RETURN_TO_MY_PROFILE" | translate }}</p>

      <p></p>
    </button>
  </div>
</header>

<main
  [ngClass]="{'impersonified': getImpersonifiedData().impersonifiedByUserRole && getImpersonifiedData().impersonifiedByUserRole.length}"
  id="main-content" tabindex="-1">
  <div class="pages-container">
    <router-outlet (activate)="appService.setHeaderVisibility()"></router-outlet>
  </div>
</main>
<!-- Footer non presente, l'ho fatto comunque che non si sa mai -->
<!-- <app-footer></app-footer> -->

<toast [hideAfter]="2000"></toast>