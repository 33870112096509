import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbDatepickerModule, NgbNavModule, NgbPaginationModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { FocusTrapModule } from "primeng/focustrap";
import { TranslatePipe } from "./pipe/translation.filter.pipe";
import { NumberFormatPipe } from "./pipe/number-format.pipe";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { DrawerComponent } from "./components/drawer/drawer.component";
import { PanelCardComponent } from "./components/panel-card/panel-card.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { TitleSubtitlePageComponent } from "./components/title-subtitle-page/title-subtitle-page.component";
import { StatusComponent } from "./components/status/status.component";
import { InputComponent } from "./components/input/input.component";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { UploadItemComponent } from "./components/upload-item/upload-item.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { ModalComponent } from "./components/modal/modal.component";
import { BaseModalComponent } from "./components/base-modal/base-modal.component";
import { ToastComponent } from "./components/toast/toast.component";
import { RemoveAfterDirective } from "./directive/remove-after.directive";
import { TabsItemComponent } from "./components/tabs-item/tabs-item.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { SelectComponent } from "./components/select/select.component";
import { BudgetMovingEconomicsDashboardComponent } from "./components/budget-moving-economics-dashboard/budget-moving-economics-dashboard.component";
import { BudgetMovingBudgetDashboardComponent } from "./components/budget-moving-budget-dashboard/budget-moving-budget-dashboard.component";
import { BudgetMovingHeaderTableComponent } from "./components/budget-moving-header-table/budget-moving-header-table.component";
import { ProposalDashboardComponent } from "./components/proposal-dashboard/proposal-dashboard.component";
import { ProposalDashboardTableDotsBudgetComponent } from "./components/proposal-dashboard-table-dots-budget/proposal-dashboard-table-dots-budget.component";
import { ProposalDashboardTableDotsGenderComponent } from "./components/proposal-dashboard-table-dots-gender/proposal-dashboard-table-dots-gender.component";
import { ProposalDashboardTableGender } from "./components/proposal-dashboard-table-gender/proposal-dashboard-table-gender.component";
import { ProposalHeaderTableComponent } from "./components/proposal-header-table/proposal-header-table.component";
import { ProposalTableComponent } from "./components/proposal-table/proposal-table.component";
import { EquityCellComponent } from "./components/equity-cell/equity-cell.component";
import { ProposaTableActionCellComponent } from "./components/proposal-table-action-cell/proposal-table-action-cell.component";
import { ProposalScheduleTabsComponent } from "./components/proposal-schedule-tabs/proposal-schedule-tabs.component";
import { NgAlloyModule } from "alloy-design-system";
import { TimelineProposalComponent } from "./components/timeline-proposal/timeline-proposal.component";
import { HTreeNodeComponent } from "./components/h-tree-node/h-tree-node.component";
import { CheckboxModule } from "primeng/checkbox";
import { PrimeTemplate } from "primeng/api";



@NgModule({
  declarations: [
    TranslatePipe,
    NumberFormatPipe,
    HeaderComponent,
    FooterComponent,
    DrawerComponent,
    PanelCardComponent,
    BreadcrumbComponent,
    TitleSubtitlePageComponent,
    StatusComponent,
    InputComponent,
    DatePickerComponent,
    UploadItemComponent,
    LoaderComponent,
    ModalComponent,
    BaseModalComponent,
    ToastComponent,
    RemoveAfterDirective,
    TabsItemComponent,
    PaginationComponent,
    SelectComponent,
    BudgetMovingEconomicsDashboardComponent,
    BudgetMovingBudgetDashboardComponent,
    BudgetMovingHeaderTableComponent,
    ProposalDashboardComponent,
    ProposalDashboardTableDotsBudgetComponent,
    ProposalDashboardTableDotsGenderComponent,
    ProposalDashboardTableGender,
    ProposalHeaderTableComponent,
    ProposalTableComponent,
    EquityCellComponent,
    ProposaTableActionCellComponent,
    ProposalScheduleTabsComponent,
    TimelineProposalComponent,
    HTreeNodeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    RouterModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbTooltipModule,
    CheckboxModule,
    PrimeTemplate,
    NgAlloyModule,
    NgbNavModule,
    FocusTrapModule
  ],
  exports: [
    HeaderComponent,
    TranslatePipe,
    NumberFormatPipe,
    FooterComponent,
    DrawerComponent,
    PanelCardComponent,
    BreadcrumbComponent,
    TitleSubtitlePageComponent,
    StatusComponent,
    InputComponent,
    DatePickerComponent,
    UploadItemComponent,
    LoaderComponent,
    ModalComponent,
    BaseModalComponent,
    ToastComponent,
    RemoveAfterDirective,
    TabsItemComponent,
    PaginationComponent,
    SelectComponent,
    BudgetMovingEconomicsDashboardComponent,
    BudgetMovingBudgetDashboardComponent,
    BudgetMovingHeaderTableComponent,
    ProposalDashboardComponent,
    ProposalDashboardTableDotsBudgetComponent,
    ProposalDashboardTableDotsGenderComponent,
    ProposalDashboardTableGender,
    ProposalHeaderTableComponent,
    ProposalTableComponent,
    EquityCellComponent,
    ProposaTableActionCellComponent,
    ProposalScheduleTabsComponent,
    TimelineProposalComponent,
    HTreeNodeComponent
  ],
  providers: []
})
export class SharedModule { }
